<template>
	<errorContainer :error="erreur" :warning="warning">
		<transition name="slide">
			<div :class="`${$screen.width <= 576 ? 'container-mobile' : ''}`">
				<!-- <b-row>
					<b-col sm="12">
						<h1 :class="`${!$screen.sm ? 'main-page-title' : ''}`">
							{{ FormMSG(1, 'Validate document') }}
						</h1>
					</b-col>
				</b-row> -->
				<b-row>
					<b-col class="p-3">
						<b-card>
							<b-card-text>
								<follow-up
									ref="document-follow-up"
									:follow-ups-list="followUps"
									:is-busy="isBusy"
									for-validation
									@follow-up:validate-document="handleFolloUpValidateDocument"
									@follow-up:document-signed="handleFollowUpDocumentSigned"
									@follow-up:document-refused="handleFollowUpDocumentRefused"
								/>
							</b-card-text>
						</b-card>
					</b-col>
				</b-row>
			</div>
		</transition>
	</errorContainer>
</template>

<script>
import FollowUp from '@/modules/onboarding/components/FollowUp';
import languageMessages from '@/mixins/languageMessages';
import { getDocumentToValidate } from '@/modules/onboarding/cruds/onboarding.crud';
import globalMixin from '@/mixins/global.mixin';
import { store } from '@/store';
import { mapActions } from 'vuex';

export default {
	name: 'ValidateDocuments',
	mixins: [languageMessages, globalMixin],
	components: {
		FollowUp
	},
	data() {
		return {
			hv: 'dark',
			erreur: {},
			warning: '',
			followUps: [],
			isBusy: false
		};
	},
	async created() {
		if (store.getToken()) await this.getFollowUpsList();
	},
	methods: {
		...mapActions({
			getNotifications: 'header/getNotifications'
		}),
		async handleFollowUpDocumentRefused() {
			await this.getFollowUpsList();
		},
		async handleFollowUpDocumentSigned(payload) {
			await this.getFollowUpsList();
		},
		async handleFolloUpValidateDocument(payload) {
			await this.getFollowUpsList();
		},
		async getFollowUpsList() {
			this.isBusy = true;
			await this.getNotifications();
			const result = await getDocumentToValidate();
			this.followUps = result;
			this.isBusy = false;
		}
	}
};
</script>
