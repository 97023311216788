var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "errorContainer",
    { attrs: { error: _vm.erreur, warning: _vm.warning } },
    [
      _c("transition", { attrs: { name: "slide" } }, [
        _c(
          "div",
          { class: `${_vm.$screen.width <= 576 ? "container-mobile" : ""}` },
          [
            _c(
              "b-row",
              [
                _c(
                  "b-col",
                  { staticClass: "p-3" },
                  [
                    _c(
                      "b-card",
                      [
                        _c(
                          "b-card-text",
                          [
                            _c("follow-up", {
                              ref: "document-follow-up",
                              attrs: {
                                "follow-ups-list": _vm.followUps,
                                "is-busy": _vm.isBusy,
                                "for-validation": "",
                              },
                              on: {
                                "follow-up:validate-document":
                                  _vm.handleFolloUpValidateDocument,
                                "follow-up:document-signed":
                                  _vm.handleFollowUpDocumentSigned,
                                "follow-up:document-refused":
                                  _vm.handleFollowUpDocumentRefused,
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }