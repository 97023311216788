<template>
	<transition name="slide">
		<div :class="`container-mobile${!isFilmSingle ? ' pb-135' : ''}`">
			<div class="my-contract-mobile p-3">
				<div class="title">
					<h2>{{ FormMSG(41, 'Contract') }}</h2>
					<span v-if="!isFilmSingle && !isFilmSingleFree">{{ titleStartDate }} - {{ titleEndDate }}</span>
					<div v-if="!isFilmSingle" @click="handleClickHeader">
						<chevron-down v-if="!configAccordion.isVisible" color="#06263E" :size="22" class="icon" />
						<chevron-up v-if="configAccordion.isVisible" color="#06263E" :size="22" class="icon" />
					</div>
				</div>
				<b-collapse :visible="configAccordion.isVisible || isFilmSingle" class="content form pt-3">
					<department-selection
						ref="depfun"
						:edit-data="contract"
						:disable-edit="isEditable() === false"
						burger-field
						@department-function-selector:departmentSelected="handleDepartmentChange"
						@department-function-selector:functionSelected="handleFunctionChange"
						:is-submitted="isHandledCcn2642"
						:refresh-list="refreshDepartmentSelection"
						:from-job-request="fromJobRequest"
						@department-function-selection:init-finish="handleDepartmentSelectionInitFinish"
						:useMemoDeal="useMemoDeal"
						@active-template:status="handleChangeStatusTemplate"
					/>
					<b-form @submit="submitForm">
						<b-row v-if="!isFilmSingle">
							<b-col cols="6">
								<b-form-group v-uni-for="'startDate'" :label="FormMSG(10, 'Start date:')" :label-cols="12" :label-cols-md="3">
									<b-form-input
										v-uni-id="'startDate'"
										:disabled="isEditable() === false"
										:value="contract.startDate && contract.startDate.split('T')[0]"
										type="date"
										@input="updateStartDate"
									/>
								</b-form-group>
							</b-col>
							<b-col cols="6">
								<b-form-group v-uni-for="'endDate'" :label="FormMSG(11, 'End date:')" :label-cols="12" :label-cols-md="3">
									<b-form-input
										v-uni-id="'endDate'"
										:disabled="isEditable() === false"
										:value="contract.endDate && contract.endDate.split('T')[0]"
										type="date"
										@input="updateEndDate"
									/>
								</b-form-group>
							</b-col>
						</b-row>
						<b-form-group
							v-if="!isFilmSingle"
							v-uni-for="'daysforeseens'"
							:label="FormMSG(12, 'Number of working days foreseen:')"
							:label-cols="12"
							:label-cols-md="3"
						>
							<b-form-input v-model="contract.daysForeseen" v-uni-id="'daysforeseens'" :disabled="isEditable() === false" type="number" />
						</b-form-group>
						<b-form-group v-if="!isFilmSingle" v-uni-for="'employeeType'" :label="FormMSG(26, 'Employee type')">
							<b-form-select :disabled="isEditable() === false" v-model="contract.employeeStatus" :options="employeeStatusOptions" />
						</b-form-group>
						<b-row v-if="!isFilmSingle && !isFilmSingleFree">
							<!-- isFilmSingleUserFree -->
							<b-col v-if="[0, 1, 3, 4].includes(analyticPeriodType)" sm="12">
								<b-form-group v-uni-for="'costCenterPrepsType'" :label="labelCostCenterPreps">
									<treeselect
										:disabled="isEditable() === false"
										v-model="contract.costCenterPrepsType"
										:multiple="false"
										:clearable="false"
										:options="costCenterPrepsOptions"
										:disable-branch-nodes="true"
										:class="{ 'is-invalid': isSubmitted && $v.contract.costCenterPrepsType.$error }"
									>
										<div slot="option-label" slot-scope="{ node }">
											<div class="treeselect-label" :title="node.label">{{ node.label }}</div>
										</div>
									</treeselect>
									<div v-if="isSubmitted && $v.contract.costCenterPrepsType.$error" class="invalid-feedback">
										{{ FormMSG(61, msgPrep) }}
									</div>
								</b-form-group>
							</b-col>
							<b-col v-if="[1, 2, 4].includes(analyticPeriodType)" sm="12">
								<b-form-group v-uni-for="'costCenterShootingType'" :label="FormMSG(28, 'Cost center shooting')">
									<treeselect
										:disabled="isEditable() === false"
										v-model="contract.costCenterShootingType"
										:multiple="false"
										:clearable="false"
										:options="costCenterShootingOptions"
										:disable-branch-nodes="true"
										:class="{ 'is-invalid': isSubmitted && $v.contract.costCenterShootingType.$error }"
									>
										<div slot="option-label" slot-scope="{ node }">
											<div class="treeselect-label" :title="node.label">{{ node.label }}</div>
										</div>
									</treeselect>
									<div v-if="isSubmitted && $v.contract.costCenterShootingType.$error" class="invalid-feedback">
										{{ FormMSG(62, msgShooting) }}
									</div>
								</b-form-group>
							</b-col>
							<b-col v-if="[2, 3, 4].includes(analyticPeriodType)" sm="12">
								<b-form-group v-uni-for="'costCenterWrapType'" :label="FormMSG(29, 'Cost center wrap')">
									<treeselect
										:disabled="isEditable() === false"
										v-model="contract.costCenterWrapType"
										:multiple="false"
										:clearable="false"
										:options="costCenterWrapOptions"
										:disable-branch-nodes="true"
										:class="{ 'is-invalid': isSubmitted && $v.contract.costCenterWrapType.$error }"
									>
										<div slot="option-label" slot-scope="{ node }">
											<div class="treeselect-label" :title="node.label">{{ node.label }}</div>
										</div>
									</treeselect>
									<div v-if="isSubmitted && $v.contract.costCenterWrapType.$error" class="invalid-feedback">
										{{ FormMSG(63, msgWrap) }}
									</div>
								</b-form-group>
							</b-col>
							<b-col sm="12" md="12" lg="3" xl="3">
								<b-form-group :label="FormMSG(35, 'Budget cost factor')" v-uni-for="'bcfRate'">
									<b-form-input
										v-model="contract.companyCostFactor"
										v-uni-id="'bcfRate'"
										type="number"
										step="0.00000000001"
										min="1"
										placeholder="1.0"
										:disabled="isEditable() === false"
									/>
									<div class="info-message">
										<div><info :size="16" /></div>
										<div class="label">{{ FormMSG(36, 'budget cost = salary*factor') }}</div>
									</div>
								</b-form-group>
							</b-col>
							<b-col sm="12" md="12" lg="3" xl="3">
								<b-form-group :label="FormMSG(535, 'Salary type')" v-uni-for="'salType'">
									<b-form-select
										v-model="contract.salaryType"
										v-uni-id="'salType'"
										:options="salaryTypeOptions"
										:disabled="isEditable() === false"
									/>
								</b-form-group>
							</b-col>
						</b-row>
						<b-row v-if="!isFilmSingle && !isFilmSingleFree">
							<b-col sm="12">
								<fieldset class="card-inside">
									<legend class="card-inside">{{ FormMSG(37, 'Document package') }}</legend>
									<b-row class="inline-flex align-items-center">
										<b-col sm="12" md="12" lg="6" xl="6">
											<b-form-group v-uni-for="'documentPackageId'" :label="FormMSG(38, 'Assign document package')">
												<v-select
													:disabled="isEditable() === false"
													:options="documentPackageOptions"
													id="documentPackageId"
													v-model="contract.documentPackageId"
													label="name"
													:reduce="(option) => option.id"
													:clearable="false"
													:class="{ 'is-invalid': isHandleSendPackage && $v.contract.documentPackageId.$error }"
													class="fix-clearable-disabled"
													@option:selected="selectDocumentPackage"
												/>
												<div v-if="isHandleSendPackage && !$v.contract.documentPackageId.greaterThanZero" class="invalid-feedback">
													{{ FormMSG(69, 'Please, choose a document package') }}
												</div>
											</b-form-group>
										</b-col>
										<b-col sm="12" md="12" lg="6" xl="6">
											<b-row v-if="contract.documentPackageId > 0">
												<b-col sm="12">
													<!--  class="inline-flex align-items-center" -->
													<div class="btn-rounded" @click="handleViewDocumentPackage">
														<span class="icon">
															<eye color="#5B6164D6" :size="16" />
														</span>
														<span class="label">
															{{ FormMSG(39, 'View document package') }}
														</span>
													</div>
												</b-col>
											</b-row>
											<b-row class="pt-3" v-if="hideSendPackageBtn">
												<b-col sm="12">
													<div class="btn-rounded" @click="handleResendDocumentPackage">
														<span class="icon">
															<files color="#5B6164D6" :size="16" />
														</span>
														<span class="label">
															{{ FormMSG(42, 'Send new document package') }}
														</span>
													</div>
												</b-col>
											</b-row>
										</b-col>
									</b-row>
								</fieldset>
							</b-col>
						</b-row>
						<memo-deal
							ref="memo"
							:edit-data="getMemoDealInfoFromContract()"
							:disable-edit="!isEditable()"
							:start-date-update="contract.startDate"
							:end-date-update="contract.endDate"
							:days-foreseen-update="contract.daysForeseen"
							:employee-status-update="contract.employeeStatus"
							:cost-center-preps-type-update="contract.costCenterPrepsType"
							:cost-center-shooting-type-update="contract.costCenterShootingType"
							:cost-center-wrap-type-update="contract.costCenterWrapType"
							:company-cost-factor-update="contract.companyCostFactor"
							:document-package-id-update="contract.documentPackageId"
							:is-submitted="isSubmitted"
							:show-pay-code="showPayCode"
							@change="handleMemoDealChange"
							@memo-deal:fields:invalid="isInvalidateFields"
							@memo-deal:fields:clear-error="clearFieldsError"
							hide-actions
							@memo-deal:department-function:required="handleCcn2642"
						/>
						<b-row v-if="isEditable()" class="footer-fixed">
							<b-col v-if="!hideBtnDelete" sm="12" md="12" :lg="`${activePaste ? 3 : 4}`" :xl="`${activePaste ? 3 : 4}`">
								<b-button v-if="!isFilmSingle" block :disabled="disableDelete" variant="danger" size="lg" @click="delContract()">
									{{ FormMSG(31, 'Delete contract') }}
								</b-button>
							</b-col>
							<b-col
								v-if="!hideBtnCopy"
								sm="12"
								md="12"
								:lg="`${activePaste ? 3 : 4}`"
								:xl="`${activePaste ? 3 : 4}`"
								:class="`${$screen.width <= 992 ? 'my-2' : ''}`"
							>
								<b-button v-if="!isFilmSingle" block size="lg" variant="outline-secondary" @click="copyContract()">
									{{ FormMSG(32, 'Copy contract') }}
								</b-button>
							</b-col>
							<b-col
								sm="12"
								md="12"
								:lg="`${activePaste ? 3 : 4}`"
								:xl="`${activePaste ? 3 : 4}`"
								v-if="activePaste && !hideBtnSaveTemplate"
								:class="`${$screen.width <= 992 ? 'mb-2' : ''}`"
							>
								<b-button v-if="activePaste" block variant="outline-secondary" size="lg" @click="pasteContract">
									{{ FormMSG(33, 'Paste contract') }}
								</b-button>
							</b-col>
							<b-col sm="12" md="12" :lg="`${activePaste ? 3 : 4}`" :xl="`${activePaste ? 3 : 4}`" class="mb-2">
								<b-button block :disabled="disableSave" type="submit" size="lg" variant="outline-primary">
									{{ FormMSG(30, 'Save contract') }}
								</b-button>
							</b-col>
						</b-row>
					</b-form>
				</b-collapse>
			</div>
		</div>
	</transition>
</template>

<script>
import gql from 'graphql-tag';
// import UsersData from './UsersData.js'
import Vue from 'vue';
import languageMessages from '@/mixins/languageMessages';
import { createUniqIdsMixin } from 'vue-uniq-ids';
const uniqIdsMixin = createUniqIdsMixin(/* options */);
import isSingleProjectMixin from '@/mixins/isSingleProject.mixin';
import moment from 'moment';
import { ChevronDown, ChevronUp, Info, Eye, CornerUpLeft, Files } from 'lucide-vue';
import * as icons from 'lucide-vue';
import GlobalMixin from '@/mixins/global.mixin';
import { validationMixin } from 'vuelidate';
import { required, decimal } from 'vuelidate/lib/validators';
import WorkGeneralInformation from '@/components/MemoDeal/WorkGeneralInformation';
import { getContractTemplate } from '@/cruds/contract.crud';
import { getDocumentList } from '@/modules/document-package/cruds/document.crud';
import { assignDocumentPackageToUser } from '@/cruds/budget.crud';
import { isNil, greaterThanZero, removeAttributeTree, getDefaultValueCategoryTree, omit, generateSecureId } from '@/shared/utils';
import { getDocument } from '@/modules/document-package/cruds/document.crud';
import { getFileExtension, getFileSrc } from '@/shared/helpers';
import { getBudgetHierarchicalCategoryJson } from '@/cruds/budget.crud';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import { store } from '@/store';
import _ from 'lodash';
import { addUpdContractTemplate, deleteContractService } from '@/cruds/contract.crud';
import { SALARY_TYPE_MENU } from '../../shared/constants';
import { mapGetters, mapActions } from 'vuex';
import { addDepartment, updateDepartment, addFunction, updateFunction } from '@/cruds/department.crud';

Vue.filter('toEuro', function (value) {
	return `${value} `;
});

const mutation_contract = gql`
	mutation ($contractId: ID!, $UpdatedContract: ContractInput!, $JobRequestId: ID) {
		UpdContract(ContractId: $contractId, UpdatedContract: $UpdatedContract, JobRequestId: $JobRequestId) {
			id
		}
	}
`;

const payCodeKeys = [
	'baseSalary1PayCode',
	'baseSalary2PayCode',
	'baseSalary3PayCode',
	'baseSalary4PayCode',
	'dayOvt1PayCode',
	'dayOvt2PayCode',
	'dayOvt3PayCode',
	'weekOvt1PayCode',
	'weekOvt2PayCode',
	'weekOvt3PayCode',
	'nightTimePayCode',
	'restPayCode',
	'transportPayCode',
	'sundayPayCode',
	'seventhDayPayCode',
	'sixthDayPayCode',
	'beforeTimePayCode',
	'travelAllowancePayCode',
	'carAllowancePayCode',
	'phoneAllowancePayCode',
	'computerAllowancePayCode',
	'boxKitAllowancePayCode',
	'productionFeeAllowancePayCode',
	'lunchPerDiemPayCode',
	'dinerPerDiemPayCode',
	'hotelPerDiemPayCode',
	'abroadPerDiemPayCode'
];

export default {
	name: 'contractMobileView',
	components: {
		departmentSelection: () => ({
			component: import('@/components/DepartmentFunctionSelection')
		}),
		memoDeal: () => ({
			component: import('@/components/MemoDeal')
		}),
		ChevronDown,
		ChevronUp,
		Info,
		Eye,
		CornerUpLeft,
		WorkGeneralInformation,
		Files,
		Treeselect
	},
	mixins: [languageMessages, uniqIdsMixin, isSingleProjectMixin, GlobalMixin, validationMixin],
	props: [
		'contractInput',
		'disableSave',
		'disableEdit',
		'disableDelete',
		'accordion',
		'fillClassContainer',
		'hideSendPackageBtn',
		'userId',
		'showPayCode',
		'hideBtnDelete',
		'hideBtnCopy',
		'hideBtnSaveTemplate',
		'fromJobRequest',
		'useMemoDeal'
	],
	watch: {
		useMemoDeal: {
			handler(newVal) {
				if (newVal !== undefined) {
					return newVal;
				} else {
					return false;
				}
			},
			immediate: true,
			deep: true
		},
		currentJobRequest: {
			handler(newVal) {
				if (this.fromJobRequest === true) {
					if (JSON.stringify(newVal) !== '{}' && !_.isNil(newVal)) {
						this.originalDepartment = newVal.department;
						this.originalFunction = newVal.function;
					}
				}
			},
			immediate: true,
			deep: true
		},
		accordion(val) {
			if (val.id === this.configAccordion.id) {
				this.configAccordion.isVisible = val.isVisible;
			} else {
				this.configAccordion.isVisible = false;
			}
		}
	},
	data() {
		return {
			originalDepartment: '',
			originalDepartmentId: 0,
			originalFunction: '',
			originalFunctionId: 0,
			refreshDepartmentSelection: false,
			editData: {
				type: Object,
				required: false,
				department: 10,
				function: 1010,
				//default: null
				default: {
					department: 10,
					function: 1010
				}
			},
			errors: [],
			contract: {
				employeeStatus: 0,
				isAutomaticSentContract: true,
				costCenterPrepsType: 0,
				costCenterShootingType: 0,
				costCenterWrapType: 0,
				documentPackageId: 0,
				salaryType: 0
			},
			configAccordion: {
				id: '',
				isVisible: false
			},
			displayPaste: false, //contractContent.filled,
			contractContent: {},
			isSubmitted: false,
			isInvalid: false,
			configCardAccordion: {
				'work-general-information': {
					isVisible: true
				}
			},
			costCenterPrepsOptions: [],
			costCenterShootingOptions: [],
			costCenterWrapOptions: [],
			documentPackageOptions: [
				{
					id: 0,
					name: ''
				}
			],
			activePaste: false,
			oldDocumentPackageId: 0,
			isHandleSendPackage: false,
			msgPrep: 'Please, select a prep',
			msgShooting: 'Please, select a shooting',
			msgWrap: 'Please, select a wrap',
			currentValidCategoryTree: null,
			isHandledCcn2642: false,

			contractTemplate: {
				defaultDayType: 0,
				dailyRate: 0,
				travelDailyRate: 0,
				hourlyRate: 35,
				overtimeRate: 0,
				nightTimeRate: 0,
				hourBeforeTimeRate: 0,
				kmRate: 0,
				lunchPerDiem: 0,
				hotelPerDiem: 0,
				dailyRightTransfer: 0,
				nightTimeStart: '2019-01-01T00:00:00Z',
				nightTimeEnd: '2019-01-01T00:00:00Z',
				minTimeBetweenDays: '2019-01-01T00:00:00Z',
				lunchMinimum: 0,
				totMinPerDayStr: '00:00',
				totMinPerWeekStr: '00:00',
				sixthDay: 100,
				seventhDay: 100,
				timeManagementType: 0,
				weeklyOvertime: false,
				workSixDays: 0,
				weeklyFiveDaysMinimumSalary: 995,
				weeklySixDaysMinimumSalary: 1141,
				weeklyFiveDaysEffNumHours: 43,
				weeklyFiveDaysEquNumHours: 46,
				weeklySixDaysEffNumHours: 52,
				weeklySixDaysEquNumHours: 56,
				weeklyBonusAmount: 800,
				weeklyFiveDaysRefSalary: 1501,
				weeklySixDaysRefSalary: 1985,
				weeklyOvtOneLimit: 35,
				weeklyOvtOneRate: 125,
				weeklyOvtTwoLimit: 43,
				weeklyOvtTwoRate: 150,
				weeklyOvtThreeLimit: 48,
				weeklyOvtThreeRate: 175,
				weeklyBaseEffNumHours: 39,
				hourlyRateAnnexThree: 23,
				weeklyBonusAmountFiveDays: 950,
				weeklyBonusAmountSixDays: 1200,
				weeklyBaseRefSalary: 1415,
				weeklyBaseMinimumSalary: 970,
				weeklyFiveDaysMinimumSalaryAnThree: 910,
				weeklySixDaysMinimumSalaryAnThree: 1140,
				transportRate: 0,
				transportPaidAfter: 0,
				ovtLimit1: 0,
				ovtLimit2: 0,
				ovtRate1: 100,
				ovtRate2: 100,
				sundayPublicHolidayFactor: 100,
				companyCostFactor: 1,
				minTimeBetweenWeek: 0,
				transportPaidAfterStr: '00:00',
				ovtLimit1Str: '00:00',
				ovtLimit2Str: '00:00',
				minTimeBetweenWeekStr: '00:00',
				totMinPerDayForContDay: 0,
				totMinPerDayForContDayStr: '00:00',
				lunchMinimumForContDay: 0,
				weeklyBaseEffNumHoursStr: '00:00',
				weeklyOvtOneLimitStr: '00:00',
				weeklyOvtTwoLimitStr: '00:00',
				weeklyOvtThreeLimitStr: '00:00',
				lunchMinimumStr: '00:00',
				lunchMinimumForContDayStr: '00:00',
				employeeStatus: 0,
				isAutomaticSentContract: false,
				costCenterPrepsType: 0,
				costCenterShootingType: 0,
				costCenterWrapType: 0,
				documentPackageId: 0,
				useCarAllowance: false,
				carAllowanceRate: 0,
				carAllowance: 0,
				useProductionFeeAllowance: false,
				productionFeeAllowanceRate: 0,
				productionFeeAllowance: 0,
				useDinnerPerDiem: false,
				dinnerPerDiem: 0,
				useComputerAllowance: false,
				computerAllowanceRate: 0,
				computerAllowance: 0,
				minimumHourGuaranteed: 0,
				minimumHourGuaranteedType: 0,
				minimumHourGuaranteedStr: '00:00',
				beforeCallTimeRate: 0,
				ifWorkAboveDailyHours: false,
				usePhoneAllowance: false,
				phoneAllowanceRate: 0,
				phoneAllowance: 0,
				useBoxKitAllowance: false,
				boxKitAllowanceRate: 0,
				boxKitAllowance: 0,
				useAbroadPerDiem: false,
				abroadPerDiem: 0,
				useHotelPerDiem: false,
				useLunchPerDiem: false,
				note: '',
				weeklyFiveDaysEffNumHoursStr: '00:00',
				weeklyFiveDaysEquNumHoursStr: '00:00',
				weeklySixDaysEffNumHoursStr: '00:00',
				weeklySixDaysEquNumHoursStr: '00:00',
				contractReference: '',
				contractType: '',
				dailyOvertime: false,
				ovtLimit: 0,
				ovtLimitContinuousDay: 0,
				ovtLimitContinuousDay1: 0,
				ovtLimitContinuousDay2: 0,
				ovtLimitStr: '00:00',
				ovtLimitContinuousDayStr: '00:00',
				ovtLimitContinuousDay1Str: '00:00',
				ovtLimitContinuousDay2Str: '00:00',
				monthlyRate: 0,
				useTravelDailyRate: false,
				baseSalary1PayCode: null,
				baseSalary2PayCode: null,
				baseSalary3PayCode: null,
				baseSalary4PayCode: null,
				dayOvt1PayCode: null,
				dayOvt2PayCode: null,
				dayOvt3PayCode: null,
				weekOvt1PayCode: null,
				weekOvt2PayCode: null,
				weekOvt3PayCode: null,
				nightTimePayCode: null,
				restPayCode: null,
				transportPayCode: null,
				sundayPayCode: null,
				seventhDayPayCode: null,
				sixthDayPayCode: null,
				beforeTimePayCode: null,
				travelAllowancePayCode: null,
				carAllowancePayCode: null,
				phoneAllowancePayCode: null,
				computerAllowancePayCode: null,
				boxKitAllowancePayCode: null,
				productionFeeAllowancePayCode: null,
				lunchPerDiemPayCode: null,
				dinerPerDiemPayCode: null,
				hotelPerDiemPayCode: null,
				abroadPerDiemPayCode: null,
				collectiveAgreement: '',
				echelon: '',
				analyticCode: '',
				cdiMonthlyReferenceSalary: 0,
				publicHolidayCalendar: null,
				activeContractTemplate: false
			}
		};
	},
	computed: {
		...mapGetters({
			currentJobRequest: 'job-request/currentJobRequest'
		}),

		disableBtnCreateDepartment() {
			return this.currentJobRequest.targetProjectId === 0;
		},

		disableBtnCreateFunction() {
			return this.currentJobRequest.targetProjectId === 0 || this.contract.department === 0;
		},

		salaryTypeOptions() {
			return [{ value: 0, text: this.FormMSG(2556, 'Please select ...') }, ...this.FormMenu(SALARY_TYPE_MENU)];
		},
		labelCostCenterPreps() {
			if (this.analyticPeriodType === 0) {
				return this.FormMSG(354, 'Cost center');
			} else {
				return this.FormMSG(27, 'Cost center preps');
			}
		},
		analyticPeriodType() {
			return store.getCurrentProjectConfig().analyticPeriodType;
		},
		optionsForDefaultDayType() {
			return this.FormMenu(1010);
		},
		titleStartDate() {
			return new moment(this.contract.startDate).utc().format('DD/MM/YYYY');
		},
		titleEndDate() {
			return new moment(this.contract.endDate).utc().format('DD/MM/YYYY');
		},
		employeeStatusOptions() {
			return this.FormMenu(1331);
		}
	},
	async created() {
		this.contract = {
			...this.contract,
			...this.contractInput
		};
		// console.log(this.contractInput);

		this.contract = this.initializedPayCode(this.contract);

		this.oldDocumentPackageId = this.contract.documentPackageId;
		this.configAccordion.id = this.contractInput.id;

		await this.initCostCenter(this.contract.department);
		await this.getDocumentTemplates();
	},
	methods: {
		...mapActions({
			setCurrentJobRequest: 'job-request/setCurrentJobRequest'
		}),
		handleChangeStatusTemplate(e) {
			this.activeContractTemplate = e;
		},
		handleDepartmentSelectionInitFinish() {
			this.refreshDepartmentSelection = false;
		},

		async handleClickAddDepartment() {
			try {
				const res_new_department = await addDepartment(+this.currentJobRequest.targetProjectId);
				const res_upd_department = await updateDepartment(+res_new_department.NewDepartment.id, this.originalDepartment);

				this.contract.department = res_upd_department.UpdDepartment.value;
				this.refreshDepartmentSelection = true;

				this.createToastForMobile(this.FormMSG(1455, 'Success'), this.FormMSG(1457, 'Department created successfully!'));
			} catch (e) {
				console.log(e);
			}
		},

		async handleClickCreateFunction() {
			try {
				const res_new_function = await addFunction(+this.contract.department, +this.currentJobRequest.targetProjectId);
				const res_upd_function = await updateFunction(+res_new_function.id, this.originalFunction);

				this.contract.function = res_upd_function.value;
				this.refreshDepartmentSelection = true;

				this.createToastForMobile(this.FormMSG(1455, 'Success'), this.FormMSG(1456, 'Function created successfully!'));
			} catch (e) {
				console.log(e);
			}
		},

		async saveAsDealMemoTemplate(e) {
			e.preventDefault();
			this.isSubmitted = true;

			const isInvalid = await this.asyncIsinvalid();

			this.$v.$touch();
			if (this.$v.$invalid || isInvalid) {
				this.createToastForMobile(
					this.FormMSG(13, 'Error'),
					this.FormMSG(14, 'Please verify, some fields are required or format is not valid.'),
					'',
					'danger'
				);

				return;
			}

			this.errors = [];
			this.$emit('isLoading', true);

			// let NewContractTemplate = objReducer(Object.keys(this.contractTemplate), this.newUser);
			let NewContractTemplate = this.contract;
			NewContractTemplate.department = parseInt(this.contract.department, 10);
			NewContractTemplate.function = parseInt(this.contract.function, 10);
			// convert elements to the right type as memoDeal returns strings

			NewContractTemplate.nightTimeStart = this.updateTime(this.contract.nightTimeStart);
			NewContractTemplate.nightTimeEnd = this.updateTime(this.contract.nightTimeEnd);
			NewContractTemplate.minTimeBetweenDays = this.updateTime(this.contract.minTimeBetweenDays);
			NewContractTemplate.documentPackageId = parseInt(this.contract.documentPackageId, 10);
			NewContractTemplate.monthlyRate = isNaN(parseFloat(this.contract.monthlyRate)) ? 0 : parseFloat(this.contract.monthlyRate);

			await addUpdContractTemplate(NewContractTemplate)
				.then(() => {
					this.$emit('isLoading', false);
					this.$emit('successModal', true);

					this.isSubmitted = false;
					this.activePaste = false;
				})
				.catch((error) => {
					this.erreur = error;
					this.errors.push(error);
				});
		},
		handleClickHeader() {
			this.configAccordion.isVisible = !this.configAccordion.isVisible;
			this.$emit('contract:show-body', this.configAccordion);
		},
		getMemoDealInfoFromContract() {
			const omits = ['function', 'department', 'departmentName', 'functionName'];
			const obj = omit(omits, this.contract);
			//console.log("getMemoDealInfoFromContract",obj);
			return obj;
		},
		getLastObjectWithNoChildren(id) {
			const targetObject = this.findObjectById(this.costCenterPrepsOptions, id);

			if (targetObject && targetObject.children && targetObject.children.length > 0) {
				return this.getLastObjectWithNoChildren(targetObject.children[0].id);
			}

			if (targetObject) {
				// console.log('Last Object ID:', targetObject.id);
				return targetObject.id;
			}

			console.log('Object not found');
			return null;
		},
		findObjectById(tree, id) {
			for (const node of tree) {
				if (node.id === id) {
					return node;
				}

				if (node.children && node.children.length > 0) {
					const foundObject = this.findObjectById(node.children, id);
					if (foundObject) {
						return foundObject;
					}
				}
			}

			return null;
		},
		async handleFunctionChange(element) {
			if (this.activeContractTemplate) {
				if (this.currentJobRequest && this.currentJobRequest.targetProjectId !== 0) {
					const projectId = +this.currentJobRequest.targetProjectId;
					// checking if need to upload new values
					if (element.department == this.contract.department && element.function == this.contract.function) {
						// no need to load contract template
						return;
					}

					this.contract = {
						...this.contract,
						...element
					};
					// this.contract = this.initializedPayCode(this.contract);

					// loading template for new department/function
					if (
						isNaN(this.contract.department) == false &&
						isNaN(this.contract.function) == false &&
						this.contract.department != 0 &&
						this.contract.function != 0
					) {
						// load template
						const departmentNumber = parseInt(this.contract.department, 10);
						const functionNumber = parseInt(this.contract.function, 10);
						const catNumber = parseInt(this.contract.category, 10);
						const catNumberId = this.getLastObjectWithNoChildren(catNumber);

						await getContractTemplate(departmentNumber, functionNumber, projectId)
							.then(async (record) => {
								if (
									parseInt(record.costCenterPrepsType, 10) === 0 ||
									parseInt(record.costCenterShootingType, 10) === 0 ||
									parseInt(record.costCenterWrapType, 10) === 0
								) {
									// const defaultValueCategoryTree = getDefaultValueCategoryTree(this.currentValidCategoryTree, functionNumber, departmentNumber, 0);

									if (parseInt(record.costCenterPrepsType, 10) === 0) {
										this.contract.costCenterPrepsType = catNumberId;
									}

									if (parseInt(record.costCenterShootingType, 10) === 0) {
										this.contract.costCenterShootingType = catNumberId;
									}

									if (parseInt(record.costCenterWrapType, 10) === 0) {
										this.contract.costCenterWrapType = catNumberId;
									}
								} else {
									if (parseInt(record.costCenterPrepsType, 10) > 0) {
										this.contract.costCenterPrepsType = record.costCenterPrepsType;
									}

									if (parseInt(record.costCenterShootingType, 10) > 0) {
										this.contract.costCenterShootingType = record.costCenterShootingType;
									}

									if (parseInt(record.costCenterWrapType, 10) > 0) {
										this.contract.costCenterWrapType = record.costCenterWrapType;
									}
								}
								this.contract.companyCostFactor = record.companyCostFactor;

								record = {
									...record,
									costCenterPrepsType: this.contract.costCenterPrepsType,
									costCenterShootingType: this.contract.costCenterShootingType,
									costCenterWrapType: this.contract.costCenterWrapType,
									companyCostFactor: this.contract.companyCostFactor,
									employeeStatus: this.contract.employeeStatus,
									isAutomaticSentContract: this.contract.isAutomaticSentContract,
									documentPackageId: this.contract.documentPackageId
								};

								record = this.initializedPayCode(record);
								// copy received data to child component
								this.$refs.memo.contract = {
									...this.$refs.memo.contract,
									...record,
									departmentId: departmentNumber,
									functionId: functionNumber
								};
								this.initializedPayCodeByRef('memo', record);
							})
							.catch((error) => {
								this.erreur = error;
								this.errors.push(error);
							});
					}
					this.activePaste = false;
				}
			}
		},
		async handleDepartmentChange(payload) {
			await this.initCostCenter(payload).then(() => {
				this.contract.costCenterPrepsType = 0;
				this.contract.costCenterShootingType = 0;
				this.contract.costCenterWrapType = 0;
				this.contract.department = payload;

				// copy received data to child component
				this.$refs.memo.contract = {
					...this.$refs.memo.contract,
					costCenterPrepsType: this.contract.costCenterPrepsType,
					costCenterShootingType: this.contract.costCenterShootingType,
					costCenterWrapType: this.contract.costCenterWrapType
				};
			});
		},
		isEditable() {
			if (this.fromJobRequest) {
				return this.currentJobRequest.targetProjectId > 0;
			}
			return !this.disableEdit;
		},
		copyContract() {
			// this.contractContent.filled = true;
			this.contractContent = {
				...this.contractContent,
				...this.contract
			};

			const omitKeys = ['costCenterPrepsType', 'costCenterShootingType', 'costCenterWrapType'];
			this.contractContent = {
				...omit(omitKeys, this.contractContent)
			};

			this.createToastForMobile(this.FormMSG(54, 'Success'), this.FormMSG(55, 'Your contract was copied successfully, you can do your paste'), '');
			this.activePaste = true;
		},
		pasteContract() {
			//  console.log("pasteContract:", contractContent);
			this.contract = {
				...this.contract,
				...this.contractContent
			};
			// console.log("pasteContract:", this.contract);
			this.$refs.memo.contract = this.contractContent;

			this.createToastForMobile(this.FormMSG(54, 'Success'), this.FormMSG(56, 'Paste done successfully'), '');
			this.activePaste = false;
			// this.contract.filled = false;
		},
		updateStartDate: function ($event) {
			if (this.contract.startDate.startsWith('T')) {
				this.contract.startDate = $event + this.contract.startDate;
			} else {
				this.contract.startDate = $event + this.contract.startDate.substring(10);
			}
		},
		updateEndDate: function ($event) {
			if (this.contract.endDate.startsWith('T')) {
				this.contract.endDate = $event + this.contract.endDate;
			} else {
				this.contract.endDate = $event + this.contract.endDate.substring(10);
			}
		},
		delContract: function () {
			var contractId = parseInt(this.contract.id, 10);
			deleteContractService(contractId)
				.then((result) => {
					window.location.reload(true);
				})
				.catch((error) => {
					console.log(error);
					this.erreur = error;
				});
		},
		handleMemoDealChange(element) {
			// remove function and department from element because not handled by this component but receives the whole contract
			this.contract = {
				...this.contract,
				...element
			};
			//console.log("this.contract after:", this.contract);
		},
		async submitForm(e) {
			e.preventDefault();
			this.isSubmitted = true;
			this.isHandleSendPackage = false;

			// console.log("in submit contract:", this.contract);
			const isInvalid = await this.asyncIsinvalid();

			this.$v.$touch();
			if (this.$v.$invalid || isInvalid) {
				this.createToastForMobile(
					this.FormMSG(13, 'Error'),
					this.FormMSG(14, 'Please verify, some fields are required or format is not valid.'),
					'',
					'danger'
				);

				return;
			}

			this.errors = [];
			this.$emit('isLoading', true);

			let UpdatedContract = {
				department: parseInt(this.contract.department, 10),
				function: parseInt(this.contract.function, 10),
				startDate: this.contract.startDate,
				endDate: this.contract.endDate,
				dailyRate: parseFloat(this.contract.dailyRate),
				nightTimeRate: parseFloat(this.contract.nightTimeRate),
				kmRate: parseFloat(this.contract.kmRate),
				lunchPerDiem: parseFloat(this.contract.lunchPerDiem),
				hotelPerDiem: parseFloat(this.contract.hotelPerDiem),
				dailyRightTransfer: parseFloat(this.contract.dailyRightTransfer),
				hourlyRate: parseFloat(this.contract.hourlyRate),
				nightTimeStart: this.updateTime(this.contract.nightTimeStart),
				nightTimeEnd: this.updateTime(this.contract.nightTimeEnd),
				totMinPerDay: this.contract.totMinPerDay,
				lunchMinimum: parseInt(this.contract.lunchMinimum, 10),
				totMinPerWeek: this.contract.totMinPerWeek,
				defaultDayType: this.contract.defaultDayType,
				travelDailyRate: parseFloat(this.contract.travelDailyRate),
				sixthDay: parseInt(this.contract.sixthDay, 10),
				seventhDay: parseInt(this.contract.seventhDay, 10),
				daysForeseen: parseInt(this.contract.daysForeseen, 10),
				hourBeforeTimeRate: parseFloat(this.contract.hourBeforeTimeRate),
				minTimeBetweenDays: this.updateTime(this.contract.minTimeBetweenDays),
				timeManagementType: parseInt(this.contract.timeManagementType),
				overtimeRate: parseFloat(this.contract.overtimeRate),
				weeklyFiveDaysMinimumSalary: parseFloat(this.contract.weeklyFiveDaysMinimumSalary),
				weeklySixDaysMinimumSalary: parseFloat(this.contract.weeklySixDaysMinimumSalary),
				weeklyFiveDaysEffNumHours: parseFloat(this.contract.weeklyFiveDaysEffNumHours),
				weeklyFiveDaysEquNumHours: parseFloat(this.contract.weeklyFiveDaysEquNumHours),
				weeklySixDaysEffNumHours: parseFloat(this.contract.weeklySixDaysEffNumHours),
				weeklySixDaysEquNumHours: parseFloat(this.contract.weeklySixDaysEquNumHours),
				weeklyBonusAmount: parseFloat(this.contract.weeklyBonusAmount),
				weeklyFiveDaysRefSalary: parseFloat(this.contract.weeklyFiveDaysRefSalary),
				weeklySixDaysRefSalary: parseFloat(this.contract.weeklySixDaysRefSalary),
				weeklyOvtOneLimit: parseFloat(this.contract.weeklyOvtOneLimit),
				weeklyOvtOneRate: parseFloat(this.contract.weeklyOvtOneRate),
				weeklyOvtTwoLimit: parseFloat(this.contract.weeklyOvtTwoLimit),
				weeklyOvtTwoRate: parseFloat(this.contract.weeklyOvtTwoRate),
				weeklyOvtThreeLimit: parseFloat(this.contract.weeklyOvtThreeLimit),
				weeklyOvtThreeRate: parseFloat(this.contract.weeklyOvtThreeRate),
				weeklyBaseEffNumHours: parseFloat(this.contract.weeklyBaseEffNumHours),
				hourlyRateAnnexThree: parseFloat(this.contract.hourlyRateAnnexThree),
				weeklyBonusAmountFiveDays: parseFloat(this.contract.weeklyBonusAmountFiveDays),
				weeklyBonusAmountSixDays: parseFloat(this.contract.weeklyBonusAmountSixDays),
				weeklyBaseRefSalary: parseFloat(this.contract.weeklyBaseRefSalary),
				weeklyBaseMinimumSalary: parseFloat(this.contract.weeklyBaseMinimumSalary),
				// weeklyOvertime: (this.contract.weeklyOvertime === 1) ? true : false,
				weeklyOvertime: this.contract.weeklyOvertime,
				workSixDays: parseInt(this.contract.workSixDays, 10),
				weeklyFiveDaysMinimumSalaryAnThree: parseFloat(this.contract.weeklyFiveDaysMinimumSalaryAnThree),
				weeklySixDaysMinimumSalaryAnThree: parseFloat(this.contract.weeklySixDaysMinimumSalaryAnThree),
				transportRate: parseFloat(this.contract.transportRate),
				ovtRate1: parseFloat(this.contract.ovtRate1),
				ovtRate2: parseFloat(this.contract.ovtRate2),
				sundayPublicHolidayFactor: parseFloat(this.contract.sundayPublicHolidayFactor),
				companyCostFactor: parseFloat(this.contract.companyCostFactor),
				transportPaidAfter: parseInt(this.contract.transportPaidAfter),
				ovtLimit1: parseInt(this.contract.ovtLimit1),
				ovtLimit2: parseInt(this.contract.ovtLimit2),
				minTimeBetweenWeek: parseInt(this.contract.minTimeBetweenWeek),
				transportPaidAfterStr: this.contract.transportPaidAfterStr,
				ovtLimit1Str: this.contract.ovtLimit1Str,
				ovtLimit2Str: this.contract.ovtLimit2Str,
				minTimeBetweenWeekStr: this.contract.minTimeBetweenWeekStr,
				totMinPerDayForContDay: this.contract.totMinPerDayForContDay,
				lunchMinimumForContDay: this.contract.lunchMinimumForContDay,
				employeeStatus: parseInt(this.contract.employeeStatus, 10),
				isAutomaticSentContract: this.contract.isAutomaticSentContract,
				costCenterPrepsType: parseInt(this.contract.costCenterPrepsType, 10),
				costCenterShootingType: parseInt(this.contract.costCenterShootingType, 10),
				costCenterWrapType: parseInt(this.contract.costCenterWrapType, 10),
				documentPackageId: parseInt(this.contract.documentPackageId, 10),
				useCarAllowance: this.contract.useCarAllowance,
				carAllowanceRate: this.contract.carAllowanceRate,
				carAllowance: isNaN(parseFloat(this.contract.carAllowance)) ? 0 : parseFloat(this.contract.carAllowance),
				useProductionFeeAllowance: this.contract.useProductionFeeAllowance,
				productionFeeAllowanceRate: this.contract.productionFeeAllowanceRate,
				productionFeeAllowance: isNaN(parseFloat(this.contract.productionFeeAllowance)) ? 0 : parseFloat(this.contract.productionFeeAllowance),
				useDinnerPerDiem: this.contract.useDinnerPerDiem,
				dinnerPerDiem: isNaN(parseFloat(this.contract.dinnerPerDiem)) ? 0 : parseFloat(this.contract.dinnerPerDiem),
				useComputerAllowance: this.contract.useComputerAllowance,
				computerAllowanceRate: this.contract.computerAllowanceRate,
				computerAllowance: isNaN(parseFloat(this.contract.computerAllowance)) ? 0 : parseFloat(this.contract.computerAllowance),
				minimumHourGuaranteed: this.contract.minimumHourGuaranteed,
				minimumHourGuaranteedType: this.contract.minimumHourGuaranteedType,
				beforeCallTimeRate: isNaN(parseFloat(this.contract.beforeCallTimeRate)) ? 0 : parseFloat(this.contract.beforeCallTimeRate),
				ifWorkAboveDailyHours: this.contract.ifWorkAboveDailyHours,
				usePhoneAllowance: this.contract.usePhoneAllowance,
				phoneAllowanceRate: this.contract.phoneAllowanceRate,
				phoneAllowance: isNaN(parseFloat(this.contract.phoneAllowance)) ? 0 : parseFloat(this.contract.phoneAllowance),
				useBoxKitAllowance: this.contract.useBoxKitAllowance,
				boxKitAllowanceRate: this.contract.boxKitAllowanceRate,
				boxKitAllowance: isNaN(parseFloat(this.contract.boxKitAllowance)) ? 0 : parseFloat(this.contract.boxKitAllowance),
				useAbroadPerDiem: this.contract.useAbroadPerDiem,
				abroadPerDiem: isNaN(parseFloat(this.contract.abroadPerDiem)) ? 0 : parseFloat(this.contract.abroadPerDiem),
				useHotelPerDiem: this.contract.useHotelPerDiem,
				useLunchPerDiem: this.contract.useLunchPerDiem,
				note: this.contract.note,
				contractReference: this.contract.contractReference,
				contractType: this.contract.contractType,
				dailyOvertime: this.contract.dailyOvertime,
				ovtLimit: this.contract.ovtLimit,
				ovtLimitContinuousDay: this.contract.ovtLimitContinuousDay,
				monthlyRate: isNaN(parseFloat(this.contract.monthlyRate)) ? 0 : parseFloat(this.contract.monthlyRate),
				useTravelDailyRate: this.contract.useTravelDailyRate,
				baseSalary1PayCode: +this.contract.baseSalary1PayCode,
				baseSalary2PayCode: +this.contract.baseSalary2PayCode,
				baseSalary3PayCode: +this.contract.baseSalary3PayCode,
				baseSalary4PayCode: +this.contract.baseSalary4PayCode,
				dayOvt1PayCode: +this.contract.dayOvt1PayCode,
				dayOvt2PayCode: +this.contract.dayOvt2PayCode,
				dayOvt3PayCode: +this.contract.dayOvt3PayCode,
				weekOvt1PayCode: +this.contract.weekOvt1PayCode,
				weekOvt2PayCode: +this.contract.weekOvt2PayCode,
				weekOvt3PayCode: +this.contract.weekOvt3PayCode,
				nightTimePayCode: +this.contract.nightTimePayCode,
				restPayCode: +this.contract.restPayCode,
				transportPayCode: +this.contract.transportPayCode,
				sundayPayCode: +this.contract.sundayPayCode,
				seventhDayPayCode: +this.contract.seventhDayPayCode,
				sixthDayPayCode: +this.contract.sixthDayPayCode,
				beforeTimePayCode: +this.contract.beforeTimePayCode,
				travelAllowancePayCode: +this.contract.travelAllowancePayCode,
				carAllowancePayCode: +this.contract.carAllowancePayCode,
				phoneAllowancePayCode: +this.contract.phoneAllowancePayCode,
				computerAllowancePayCode: +this.contract.computerAllowancePayCode,
				boxKitAllowancePayCode: +this.contract.boxKitAllowancePayCode,
				productionFeeAllowancePayCode: +this.contract.productionFeeAllowancePayCode,
				lunchPerDiemPayCode: +this.contract.lunchPerDiemPayCode,
				dinerPerDiemPayCode: +this.contract.dinerPerDiemPayCode,
				hotelPerDiemPayCode: +this.contract.hotelPerDiemPayCode,
				abroadPerDiemPayCode: +this.contract.abroadPerDiemPayCode,
				collectiveAgreement: this.contract.collectiveAgreement,
				echelon: this.contract.echelon,
				analyticCode: this.contract.analyticCode,
				cdiMonthlyReferenceSalary: +this.contract.cdiMonthlyReferenceSalary,
				publicHolidayCalendar: +this.contract.publicHolidayCalendar,
				salaryType: this.contract.salaryType
			};
			if (this.contractInput.kmRate !== this.contract.kmRate) {
				let txt = this.FormMSG(
					1590,
					'You changed the Kilmoter rate, but some travel expenses already exist for this user. All the non validated expenses will be recomputed with the new rate. Do you want to also recompute the validated expenses with the new rate?'
				);
				this.$bvModal
					.msgBoxConfirm(txt, {
						title: this.FormMSG(24, 'Confirm'),
						size: 'md',
						buttonSize: 'md',
						okVariant: 'primary',
						cancelVariant: 'secondary',
						okTitle: this.FormMSG(1591, 'Reccompute'),
						noCloseOnBackdrop: true,
						cancelTitle: this.FormMSG(1592, 'Leave validated with previous rate'),
						footerClass: 'p-2',
						hideHeaderClose: false,
						centered: false
					})
					.then(async (v) => {
						if (v == null) return;
						if (!v) {
							UpdatedContract.recomputeAllExpense = false;
							this.$apollo
								.mutate({
									mutation: mutation_contract,
									variables: {
										contractId: parseInt(this.contract.id, 10),
										UpdatedContract,
										JobRequestId: this.fromJobRequest === true ? +this.currentJobRequest.id : null
									}
								})
								.then((result) => {
									this.$emit('isLoading', false);
									this.$emit('successModal', true);
									if (this.isFilmSingle) {
										this.createToastForMobile(this.FormMSG(54, 'Success'), this.FormMSG(53, 'Contract saved successfully'), '');
									}

									this.isSubmitted = false;
									this.activePaste = false;
								})
								.catch((error) => {
									this.$emit('isLoading', false);
									this.$emit('erreur', error);
								});
						} else {
							UpdatedContract.recomputeAllExpense = true;
							this.$apollo
								.mutate({
									mutation: mutation_contract,
									variables: {
										contractId: parseInt(this.contract.id, 10),
										UpdatedContract
									}
								})
								.then((result) => {
									this.$emit('isLoading', false);
									this.$emit('successModal', true);
									if (this.isFilmSingle || this.fromJobRequest) {
										this.createToastForMobile(this.FormMSG(54, 'Success'), this.FormMSG(53, 'Contract saved successfully'), '');
									}

									this.isSubmitted = false;
									this.activePaste = false;
								})
								.catch((error) => {
									this.$emit('isLoading', false);
									this.$emit('erreur', error);
								});
						}
					})
					.catch((err) => console.log(err));
			} else {
				this.$apollo
					.mutate({
						mutation: mutation_contract,
						variables: {
							contractId: parseInt(this.contract.id, 10),
							UpdatedContract,
							JobRequestId: this.fromJobRequest === true ? +this.currentJobRequest.id : null
						}
					})
					.then((result) => {
						this.$emit('isLoading', false);
						this.$emit('successModal', true);
						if (this.isFilmSingle || this.fromJobRequest) {
							this.createToastForMobile(this.FormMSG(54, 'Success'), this.FormMSG(53, 'Contract saved successfully'), '');
						}

						this.isSubmitted = false;
						this.activePaste = false;
					})
					.catch((error) => {
						this.$emit('isLoading', false);
						this.$emit('erreur', error);
					});
			}
		},
		updateTime(value) {
			/*
			let now = new Date();
			const sValue = value.split(':');
			now = new Date(now.setHours(parseInt(sValue[0], 10)));
			now = new Date(now.setMinutes(parseInt(sValue[1], 10)));
			now = new Date(now.setSeconds(0));

			return `${moment(now).format('YYYY-MM-DDTHH:mm:ss')}Z`;
      */
			let now = moment();
			const sValue = value.split(':');
			now.utcOffset(0);
			now.set({
				hour: parseInt(sValue[0], 10),
				minute: parseInt(sValue[1], 10),
				second: 0,
				millisecond: 0
			});
			var retVal = `${moment(now).format('YYYY-MM-DDTHH:mm:ss')}Z`;
			console.log('return value from updateTime:', retVal);
			return retVal;
		},
		isInvalidateFields(payload) {
			this.isInvalid = payload;
		},
		async asyncIsinvalid() {
			return new Promise((resolve, reject) => {
				setTimeout(() => {
					resolve(this.isInvalid);
					reject(false);
				}, 200);
			});
		},
		getIconLucide(name) {
			return icons[name];
		},
		handleCollapsibleHeader(key) {
			this.configCardAccordion[key].isVisible = !this.configCardAccordion[key].isVisible;
			// console.log({data});
		},
		async handleViewDocumentPackage() {
			await getDocument(this.contract.documentPackageId).then((records) => {
				records.childDocList = records.childDocList.map((doc) => ({
					...doc,
					src: getFileSrc(doc),
					thumb: process.env.VUE_APP_PATH_IMG_THUMB_URL + doc.xid,
					ext: getFileExtension(doc.fileName),
					isHovered: false
				}));

				this.$previewImages({
					images: records.childDocList,
					focusIndex: 0,
					options: {
						presentationMode: 'doc',
						// showStatusFileTag: true,
						hideCommentButton: false,
						hideDisLikeButton: false,
						hideLikeDislike: false,
						hideCropButton: true,
						hideDeleteButton: true,
						showSignFileButton: true
					}
				});
			});
		},
		async handleResendDocumentPackage() {
			this.isHandleSendPackage = true;
			this.isSubmitted = false;

			this.$v.$touch();
			if (this.$v.$invalid) {
				return;
			}

			this.isHandleSendPackage = false;

			if (parseInt(this.oldDocumentPackageId, 10) === 0) {
				if (parseInt(this.contract.documentPackageId, 10) > 0) {
					await assignDocumentPackageToUser(this.contract.documentPackageId, this.userId, this.contract.id).then((result) => {
						if (result) {
							this.createToastForMobile(
								this.FormMSG(54, 'Success'),
								this.FormMSG(68, 'A new document package was sent successfully to this user.'),
								''
							);
							this.$apollo
								.mutate({
									mutation: mutation_contract,
									variables: {
										contractId: parseInt(this.contract.id, 10),
										UpdatedContract: {
											documentPackageId: parseInt(this.contract.documentPackageId, 10)
										}
									}
								})
								.then((result) => {});
						}
					});
				}
			} else {
				if (parseInt(this.contract.documentPackageId, 10) > 0) {
					if (parseInt(this.oldDocumentPackageId, 10) !== parseInt(this.contract.documentPackageId, 10)) {
						this.$bvModal
							.msgBoxConfirm(this.FormMSG(64, 'You have chosen a new document. Are you sure to continue sending this document ?'), {
								title: this.FormMSG(65, 'Confirm'),
								size: 'sm',
								buttonSize: 'sm',
								okVariant: 'danger',
								okTitle: this.FormMSG(66, 'YES'),
								cancelTitle: this.FormMSG(67, 'NO'),
								footerClass: 'p-2',
								hideHeaderClose: false,
								centered: false
							})
							.then(async (v) => {
								if (!v) {
									this.contract.documentPackageId = this.oldDocumentPackageId;

									return;
								}
								await assignDocumentPackageToUser(
									this.contract.documentPackageId,
									this.userId,
									this.contract.id,
									this.oldDocumentPackageId
								).then((result) => {
									if (result) {
										this.createToastForMobile(
											this.FormMSG(54, 'Success'),
											this.FormMSG(68, 'A new document package was sent successfully to this user.'),
											''
										);
										this.$apollo
											.mutate({
												mutation: mutation_contract,
												variables: {
													contractId: parseInt(this.contract.id, 10),
													UpdatedContract: {
														documentPackageId: parseInt(this.contract.documentPackageId, 10)
													}
												}
											})
											.then((result) => {});
									}
								});
							})
							.catch((err) => console.log(err));
					}
				}
			}
		},
		clearFieldsError() {
			this.isSubmitted = false;
		},
		async getDocumentTemplates() {
			if (this.documentPackageOptions.length === 1) this.documentPackageOptions[0].name = this.FormMSG(60, 'Please, select a document package');
			await getDocumentList({
				documentType: 11
			}).then((records) => {
				records.forEach((record) => {
					if (record.archived >= 2) return;
					record = {
						...record,
						id: parseInt(record.id, 10)
					};
					this.documentPackageOptions.push(record);
				});
			});
		},
		async initCostCenter(departmentId) {
			// set right name of costCenterPres, costCenterShooting, costCenterWrap
			await getBudgetHierarchicalCategoryJson(-2, true, 1, -1, false, false, this.fromJobRequest ? +this.currentJobRequest.targetProjectId : null).then(
				(result) => {
					let costCenterPrepsOptions = [];
					let costCenterShootingOptions = [];
					let costCenterWrapOptions = [];

					const removeNoneChildrenTree = removeAttributeTree(result, 'children', null);
					this.currentValidCategoryTree = removeNoneChildrenTree;

					costCenterPrepsOptions = removeNoneChildrenTree;
					costCenterShootingOptions = removeNoneChildrenTree;
					costCenterWrapOptions = removeNoneChildrenTree;

					this.costCenterPrepsOptions = [
						{
							id: 0,
							label: this.FormMSG(61, this.msgPrep)
						},
						...costCenterPrepsOptions
					];
					this.costCenterShootingOptions = [
						{
							id: 0,
							label: this.FormMSG(62, this.msgShooting)
						},
						...costCenterShootingOptions
					];
					this.costCenterWrapOptions = [
						{
							id: 0,
							label: this.FormMSG(63, this.msgWrap)
						},
						...costCenterWrapOptions
					];
				}
			);
		},
		selectDocumentPackage() {
			this.isHandleSendPackage = false;
		},
		initCostMsg() {
			this.costCenterPrepsOptions.push({
				id: 0,
				label: this.FormMSG(61, this.msgPrep)
			});

			this.costCenterShootingOptions.push({
				id: 0,
				label: this.FormMSG(62, this.msgShooting)
			});

			this.costCenterWrapOptions.push({
				id: 0,
				label: this.FormMSG(63, this.msgWrap)
			});
		},
		initializedPayCode(record) {
			record = {
				...record,
				baseSalary1PayCode: record.baseSalary1PayCode === 0 ? null : record.baseSalary1PayCode,
				baseSalary2PayCode: record.baseSalary2PayCode === 0 ? null : record.baseSalary2PayCode,
				baseSalary3PayCode: record.baseSalary3PayCode === 0 ? null : record.baseSalary3PayCode,
				baseSalary4PayCode: record.baseSalary4PayCode === 0 ? null : record.baseSalary4PayCode,
				dayOvt1PayCode: record.dayOvt1PayCode === 0 ? null : record.dayOvt1PayCode,
				dayOvt2PayCode: record.dayOvt2PayCode === 0 ? null : record.dayOvt2PayCode,
				dayOvt3PayCode: record.dayOvt3PayCode === 0 ? null : record.dayOvt3PayCode,
				weekOvt1PayCode: record.weekOvt1PayCode === 0 ? null : record.weekOvt1PayCode,
				weekOvt2PayCode: record.weekOvt2PayCode === 0 ? null : record.weekOvt2PayCode,
				weekOvt3PayCode: record.weekOvt3PayCode === 0 ? null : record.weekOvt3PayCode,
				nightTimePayCode: record.nightTimePayCode === 0 ? null : record.nightTimePayCode,
				restPayCode: record.restPayCode === 0 ? null : record.restPayCode,
				transportPayCode: record.transportPayCode === 0 ? null : record.transportPayCode,
				sundayPayCode: record.sundayPayCode === 0 ? null : record.sundayPayCode,
				seventhDayPayCode: record.seventhDayPayCode === 0 ? null : record.seventhDayPayCode,
				sixthDayPayCode: record.sixthDayPayCode === 0 ? null : record.sixthDayPayCode,
				beforeTimePayCode: record.beforeTimePayCode === 0 ? null : record.beforeTimePayCode,
				travelAllowancePayCode: record.travelAllowancePayCode === 0 ? null : record.travelAllowancePayCode,
				carAllowancePayCode: record.carAllowancePayCode === 0 ? null : record.carAllowancePayCode,
				phoneAllowancePayCode: record.phoneAllowancePayCode === 0 ? null : record.phoneAllowancePayCode,
				computerAllowancePayCode: record.computerAllowancePayCode === 0 ? null : record.computerAllowancePayCode,
				boxKitAllowancePayCode: record.boxKitAllowancePayCode === 0 ? null : record.boxKitAllowancePayCode,
				productionFeeAllowancePayCode: record.productionFeeAllowancePayCode === 0 ? null : record.productionFeeAllowancePayCode,
				lunchPerDiemPayCode: record.lunchPerDiemPayCode === 0 ? null : record.lunchPerDiemPayCode,
				dinerPerDiemPayCode: record.dinerPerDiemPayCode === 0 ? null : record.dinerPerDiemPayCode,
				hotelPerDiemPayCode: record.hotelPerDiemPayCode === 0 ? null : record.hotelPerDiemPayCode,
				abroadPerDiemPayCode: record.abroadPerDiemPayCode === 0 ? null : record.abroadPerDiemPayCode
			};

			return record;
		},
		initializedPayCodeByRef(parentRefName, record) {
			for (const key of payCodeKeys) {
				const el = this.$refs[parentRefName].$refs[this.generateTextId(key)];
				if (el) {
					el.payrollCodeSelected = record[key];
				}
			}
		},
		generateTextId(id) {
			return generateSecureId(id);
		},
		handleCcn2642(payload) {
			this.isHandledCcn2642 = payload;
		}
	},
	validations() {
		if (this.isHandleSendPackage) {
			return {
				originalDepartment: { required: this.fromJobRequest === true ? required : {} },
				originalFunction: { required: this.fromJobRequest === true ? required : {} },
				contract: {
					documentPackageId: {
						required,
						decimal,
						min: greaterThanZero
					}
				}
			};
		} else {
			if (this.isFilmSingle) {
				return {};
			}

			let result = {
				originalDepartment: { required: this.fromJobRequest === true ? required : {} },
				originalFunction: { required: this.fromJobRequest === true ? required : {} },
				contract: {}
			};

			if ([0, 1, 3, 4].includes(this.analyticPeriodType)) {
				result.contract = _.assign(result.contract, {
					costCenterPrepsType: {
						required,
						decimal,
						min: greaterThanZero
					}
				});
			}

			if ([1, 2, 4].includes(this.analyticPeriodType)) {
				result.contract = _.assign(result.contract, {
					costCenterShootingType: {
						required,
						decimal,
						min: greaterThanZero
					}
				});
			}

			if ([2, 3, 4].includes(this.analyticPeriodType)) {
				result.contract = _.assign(result.contract, {
					costCenterWrapType: {
						required,
						decimal,
						min: greaterThanZero
					}
				});
			}

			return result;
		}
	}
};
</script>
