var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "errorContainer",
    { attrs: { error: _vm.erreur, warning: _vm.warning } },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { cols: "12", md: "12" } },
            [
              _c(
                "b-card",
                {
                  staticClass: "card-border-blue-light",
                  attrs: { "no-body": "" },
                },
                [
                  _vm.User
                    ? _c(
                        "div",
                        { staticClass: "px-0", class: _vm.fillClassContainer },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "container-header-card-collapsible cursor-pointer w-100",
                              on: {
                                click: function ($event) {
                                  return _vm.handleClickHeader(
                                    "configAccordion"
                                  )
                                },
                              },
                            },
                            [
                              _c("div", { staticClass: "title" }, [
                                _c("div", { staticClass: "w-90" }, [
                                  _c("h4", [
                                    _vm._v(
                                      _vm._s(_vm.FormMSG(1, "User Information"))
                                    ),
                                  ]),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "icon w-10" },
                                  [
                                    !_vm.configAccordion.isVisible
                                      ? _c(_vm.getIconLucide("ChevronDown"), {
                                          tag: "component",
                                          attrs: { color: "#06263E", size: 22 },
                                        })
                                      : _vm._e(),
                                    _vm.configAccordion.isVisible
                                      ? _c(_vm.getIconLucide("ChevronUp"), {
                                          tag: "component",
                                          attrs: { color: "#06263E", size: 22 },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "b-collapse",
                            {
                              class: _vm.$screen.width <= 576 ? "mt-3" : "",
                              attrs: { visible: _vm.configAccordion.isVisible },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "content-card-collapsible" },
                                [
                                  _c("profile", {
                                    attrs: {
                                      selectedUserData: _vm.selectedUserData,
                                      data: _vm.User,
                                      disabled: true,
                                      handleAccessLevel: (
                                        item,
                                        value,
                                        data,
                                        screen
                                      ) =>
                                        this.$props.handleAccessLevel(
                                          item,
                                          value,
                                          data,
                                          screen
                                        ),
                                    },
                                    on: {
                                      change: _vm.handleUserEntryLevelChange,
                                      "memo-profile:user-custom-field-value":
                                        _vm.onUserCustomFieldValueChanged,
                                      "set-user-item": _vm.handleSetUser,
                                      "put-user": _vm.handleUpdateUser,
                                    },
                                  }),
                                  _vm.isDev
                                    ? _c(
                                        "template",
                                        { slot: "footer" },
                                        [
                                          _c(
                                            "b-button",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.becomeUser(
                                                    _vm.userId
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("Impersonate")]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        [
          this.isForProject()
            ? _c("b-col", { attrs: { cols: "12", md: "12" } }, [
                _vm.$screen.width <= 576
                  ? _c(
                      "div",
                      [
                        _vm._l(_vm.contracts, function (item) {
                          return _c("contract-mobile-view", {
                            key: item.id,
                            attrs: {
                              disableDelete: _vm.contracts.length === 1,
                              contractInput: item,
                              accordion: _vm.accordion,
                              "hide-send-package-btn": true,
                              "fill-class-container": "container-layout",
                              "user-id": _vm.userId,
                              "show-pay-code": _vm.showPayCode,
                            },
                            on: {
                              isLoading: function ($event) {
                                _vm.isLoading = $event
                              },
                              successModal: function ($event) {
                                _vm.successModal = $event
                              },
                              erreur: function ($event) {
                                _vm.erreur = $event
                              },
                              "contract:show-body": _vm.handleContractShowBody,
                            },
                          })
                        }),
                        _c(
                          "b-row",
                          {
                            staticClass: "d-flex justify-content-center",
                            class: `${_vm.displayAddBtn ? "pb-2" : ""}`,
                          },
                          [
                            _c(
                              "b-col",
                              {
                                style: `display: ${
                                  _vm.displayAddBtn ? "block" : "none"
                                } !important;`,
                                attrs: { sm: "12", md: "4", lg: "4", xl: "4" },
                              },
                              [
                                _c(
                                  "b-button",
                                  {
                                    ref: "new-contract",
                                    staticClass:
                                      "w-200-px d-flex justify-content-center align-items-center",
                                    attrs: {
                                      size: "sm",
                                      variant: "outline-primary",
                                      block: "",
                                    },
                                    on: { click: _vm.addContract },
                                  },
                                  [
                                    _c(_vm.getLucideIcon("PlusCircle"), {
                                      tag: "component",
                                      attrs: { size: 18, "stroke-width": 2 },
                                    }),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "px-2",
                                        staticStyle: {
                                          "margin-bottom": "-2px",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t" +
                                            _vm._s(
                                              _vm.FormMSG(51, "New contract")
                                            ) +
                                            "\n\t\t\t\t\t\t\t"
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      2
                    )
                  : _c("div", { staticClass: "animated fadeIn" }, [
                      _c(
                        "div",
                        { staticClass: "card card-border-blue-light" },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "container-header-card-collapsible cursor-pointer",
                              on: {
                                click: function ($event) {
                                  return _vm.handleClickHeader("accordion")
                                },
                              },
                            },
                            [
                              _c("div", { staticClass: "title" }, [
                                _c("div", { staticClass: "w-90" }, [
                                  _c("h4", [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t\t\t" +
                                        _vm._s(_vm.FormMSG(42, "Contracts")) +
                                        "\n\t\t\t\t\t\t\t\t"
                                    ),
                                  ]),
                                ]),
                                !_vm.isFilmSingle
                                  ? _c(
                                      "div",
                                      { staticClass: "icon w-10" },
                                      [
                                        !_vm.accordion.isVisible
                                          ? _c(
                                              _vm.getIconLucide("ChevronDown"),
                                              {
                                                tag: "component",
                                                attrs: {
                                                  color: "#06263E",
                                                  size: 22,
                                                },
                                              }
                                            )
                                          : _vm._e(),
                                        _vm.accordion.isVisible
                                          ? _c(_vm.getIconLucide("ChevronUp"), {
                                              tag: "component",
                                              attrs: {
                                                color: "#06263E",
                                                size: 22,
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]),
                            ]
                          ),
                          _c(
                            "b-collapse",
                            {
                              attrs: {
                                visible:
                                  _vm.accordion.isVisible || _vm.isFilmSingle,
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "p-3 mt-3" },
                                [
                                  _c("b-table", {
                                    staticStyle: { "text-align": "left" },
                                    attrs: {
                                      fields: _vm.contractsFields,
                                      items: _vm.contracts,
                                      hover: true,
                                      bordered: false,
                                      "table-variant": "light",
                                      "sticky-header": "300px",
                                      "empty-text": _vm.FormMSG(
                                        33,
                                        "No Contract assigned yet"
                                      ),
                                      selectable: "",
                                      "show-empty": "",
                                      small: "",
                                    },
                                    on: {
                                      "row-clicked": _vm.contractRowClicked,
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "table-busy",
                                          fn: function () {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex justify-content-center align-items-center",
                                                },
                                                [
                                                  _c("b-spinner", {
                                                    staticClass: "align-middle",
                                                    attrs: { small: "" },
                                                  }),
                                                  _c(
                                                    "strong",
                                                    { staticClass: "ml-2" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.FormMSG(
                                                            353,
                                                            "Loading"
                                                          )
                                                        ) + "..."
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                        {
                                          key: "cell(actionSelected)",
                                          fn: function (data) {
                                            return [
                                              _vm.isSendableStatusDpae(
                                                data.item.dpaeStatus
                                              ) &&
                                              !_vm.checkIfThereIsInvalidFields(
                                                _vm.User
                                              )
                                                ? _c("b-form-checkbox", {
                                                    attrs: { size: "lg" },
                                                    on: {
                                                      change:
                                                        _vm.setDpaeCounter,
                                                    },
                                                    model: {
                                                      value:
                                                        data.item.checkStatus,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          data.item,
                                                          "checkStatus",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "data.item.checkStatus",
                                                    },
                                                  })
                                                : _vm._e(),
                                            ]
                                          },
                                        },
                                        _vm.useDpae
                                          ? {
                                              key: "cell(dpaeStatus)",
                                              fn: function ({ item }) {
                                                return [
                                                  _c("DpaeTooltip", {
                                                    attrs: {
                                                      contractDpaeStatus:
                                                        item.dpaeStatus,
                                                      data: item,
                                                      user: _vm.User,
                                                      target: item.id,
                                                    },
                                                  }),
                                                  _c(
                                                    "div",
                                                    {
                                                      attrs: {
                                                        id:
                                                          "tooltip-target-" +
                                                          item.id,
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        [
                                                          _c(
                                                            _vm.getLucideIcon(
                                                              _vm.getDpaeStatusIcon(
                                                                item.dpaeStatus
                                                              )
                                                            ),
                                                            {
                                                              tag: "component",
                                                              staticClass:
                                                                "mr-2",
                                                              class:
                                                                _vm.getDpaeStatusClass(
                                                                  item.dpaeStatus
                                                                ),
                                                              attrs: {
                                                                size: 20,
                                                                "stroke-width": 2,
                                                              },
                                                            }
                                                          ),
                                                          _c(
                                                            "span",
                                                            {
                                                              class:
                                                                item.dpaeStatus ===
                                                                1
                                                                  ? _vm.getDpaeStatusClass(
                                                                      item.dpaeStatus
                                                                    )
                                                                  : "",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.getDpaeStatusText(
                                                                    item.dpaeStatus
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "ml-4 missing-info-text",
                                                        },
                                                        [
                                                          item.dpaeStatus ===
                                                            _vm.DPAE_STATUS
                                                              .NOT_SENT &&
                                                          _vm.checkIfThereIsInvalidFields(
                                                            _vm.User
                                                          )
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "text-center",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          999,
                                                                          "Missing Info"
                                                                        )
                                                                      ) +
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              },
                                            }
                                          : null,
                                        {
                                          key: "cell(actionDelete)",
                                          fn: function ({ item }) {
                                            return [
                                              _c(
                                                "b-button",
                                                { attrs: { variant: "link" } },
                                                [
                                                  _c(
                                                    _vm.getLucideIcon(
                                                      "XCircle"
                                                    ),
                                                    {
                                                      tag: "component",
                                                      staticClass:
                                                        "text-danger",
                                                      attrs: {
                                                        size: 25,
                                                        "stroke-width": 1.5,
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.showDeleteContractDialog(
                                                            item.id
                                                          )
                                                        },
                                                      },
                                                    }
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "px-3" },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      ref: "new-contract",
                                      staticClass:
                                        "w-200-px d-flex justify-content-center align-items-center",
                                      attrs: {
                                        size: "sm",
                                        variant: "outline-primary",
                                        block: "",
                                      },
                                      on: { click: _vm.onNewContract },
                                    },
                                    [
                                      _c(_vm.getLucideIcon("PlusCircle"), {
                                        tag: "component",
                                        attrs: { size: 18, "stroke-width": 2 },
                                      }),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "px-2",
                                          staticStyle: {
                                            "margin-bottom": "-2px",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t" +
                                              _vm._s(
                                                _vm.FormMSG(51, "New contract")
                                              ) +
                                              "\n\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm.isProd || _vm.isAdmin
                        ? _c(
                            "div",
                            { staticClass: "card card-border-blue-light" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "container-header-card-collapsible cursor-pointer",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleClickHeader(
                                        "projectSpecificInfo"
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("div", { staticClass: "title" }, [
                                    _c("div", { staticClass: "w-90" }, [
                                      _c("h4", [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t\t" +
                                            _vm._s(
                                              _vm.FormMSG(
                                                856,
                                                "Project specific information"
                                              )
                                            ) +
                                            "\n\t\t\t\t\t\t\t\t"
                                        ),
                                      ]),
                                    ]),
                                    !_vm.isFilmSingle
                                      ? _c(
                                          "div",
                                          { staticClass: "icon w-10" },
                                          [
                                            !_vm.projectSpecificInfo.isVisible
                                              ? _c(
                                                  _vm.getIconLucide(
                                                    "ChevronDown"
                                                  ),
                                                  {
                                                    tag: "component",
                                                    attrs: {
                                                      color: "#06263E",
                                                      size: 22,
                                                    },
                                                  }
                                                )
                                              : _vm._e(),
                                            _vm.projectSpecificInfo.isVisible
                                              ? _c(
                                                  _vm.getIconLucide(
                                                    "ChevronUp"
                                                  ),
                                                  {
                                                    tag: "component",
                                                    attrs: {
                                                      color: "#06263E",
                                                      size: 22,
                                                    },
                                                  }
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ]),
                                ]
                              ),
                              _c(
                                "b-collapse",
                                {
                                  attrs: {
                                    visible: _vm.projectSpecificInfo.isVisible,
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "content-card-collapsible" },
                                    [
                                      _c(
                                        "b-row",
                                        { staticClass: "pt-3 px-3" },
                                        _vm._l(
                                          _vm.customFields,
                                          function (customField, index) {
                                            return _c(
                                              "b-col",
                                              {
                                                key: index,
                                                staticClass:
                                                  "d-flex mb-2 w-100",
                                                attrs: {
                                                  xl: _vm.sizeFlagInput(
                                                    _vm.customFields.length
                                                  ),
                                                  lg: _vm.sizeFlagInput(
                                                    _vm.customFields.length
                                                  ),
                                                },
                                              },
                                              [
                                                _c("custom-field-template", {
                                                  staticClass: "w-100",
                                                  attrs: {
                                                    field: customField,
                                                    "name-model":
                                                      _vm.customFieldModels[
                                                        index
                                                      ],
                                                    "name-group":
                                                      customField.nameGroup,
                                                    index: index,
                                                  },
                                                  on: {
                                                    "custom-field-template:payload":
                                                      _vm.onFieldTemplateChanged,
                                                  },
                                                  model: {
                                                    value:
                                                      customField[
                                                        _vm.customFieldModels[
                                                          index
                                                        ]
                                                      ],
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        customField,
                                                        _vm.customFieldModels[
                                                          index
                                                        ],
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "customField[customFieldModels[index]]",
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("div", { staticClass: "p-3 mt-3" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex flex-row align-items-center",
                                      },
                                      [
                                        _c(
                                          "b-button",
                                          {
                                            staticClass:
                                              "w-270-px d-flex justify-content-center align-items-center",
                                            attrs: {
                                              size: "md",
                                              variant: "outline-primary",
                                              block: "",
                                            },
                                            on: {
                                              click:
                                                _vm.manageUserRequestCustomFields,
                                            },
                                          },
                                          [
                                            _c(
                                              _vm.getLucideIcon("PlusCircle"),
                                              {
                                                tag: "component",
                                                attrs: {
                                                  size: 20,
                                                  "stroke-width": 2.5,
                                                },
                                              }
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "px-2",
                                                staticStyle: {
                                                  "margin-bottom": "0px",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n\t\t\t\t\t\t\t\t\t\t" +
                                                    _vm._s(
                                                      _vm.FormMSG(
                                                        857,
                                                        "Add/Edit Custom Fields"
                                                      )
                                                    ) +
                                                    "\n\t\t\t\t\t\t\t\t\t"
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]),
              ])
            : _vm._e(),
          _c(
            "b-modal",
            {
              staticClass: "modal-success",
              attrs: {
                "header-class": "header-class-modal-doc-package",
                title: _vm.FormMSG(60, "Success!"),
                "ok-variant": "success",
                "ok-only": "",
              },
              on: {
                ok: function ($event) {
                  _vm.successModal = false
                },
              },
              model: {
                value: _vm.successModal,
                callback: function ($$v) {
                  _vm.successModal = $$v
                },
                expression: "successModal",
              },
            },
            [
              _vm._v(
                "\n\t\t\t" +
                  _vm._s(_vm.FormMSG(61, "Your information has been saved.")) +
                  "\n\t\t"
              ),
            ]
          ),
          _c("loading", {
            attrs: {
              active: _vm.isLoading,
              "is-full-page": true,
              color: "#00A09C",
            },
            on: {
              "update:active": function ($event) {
                _vm.isLoading = $event
              },
            },
          }),
          _c("common-warning-on-delete", {
            attrs: { message: _vm.messageOnDeleteContract },
            on: {
              "dialog:cancel": _vm.closeDeleteContractDialog,
              "dialog:confirm": _vm.deleteContract,
            },
            model: {
              value: _vm.openDeleteContractDialog,
              callback: function ($$v) {
                _vm.openDeleteContractDialog = $$v
              },
              expression: "openDeleteContractDialog",
            },
          }),
          _c(
            "b-modal",
            {
              attrs: {
                "header-class": "header-class-modal-doc-package",
                "cancel-variant": "light",
                title: _vm.contractDialogTitle,
                size: "xxl",
                "footer-class": "w-100",
                centered: "",
                "no-close-on-backdrop": "",
              },
              on: {
                cancel: _vm.onCancelContractForm,
                close: _vm.onCancelContractForm,
              },
              scopedSlots: _vm._u(
                [
                  _vm.$screen.width > 576
                    ? {
                        key: "modal-footer",
                        fn: function ({ cancel }) {
                          return [
                            _c(
                              "b-container",
                              { attrs: { fluid: "" } },
                              [
                                _c(
                                  "b-row",
                                  { attrs: { "align-h": "between" } },
                                  [
                                    _c(
                                      "b-col",
                                      {
                                        staticClass:
                                          "d-flex align-items-center",
                                        staticStyle: { gap: "15px" },
                                        attrs: { cols: "12", sm: "auto" },
                                      },
                                      [
                                        _vm.useDpae
                                          ? _c(
                                              "b-button",
                                              {
                                                staticClass:
                                                  "px-4 btn-custom-outline-gray",
                                                attrs: {
                                                  variant: "light",
                                                  disabled:
                                                    !_vm.isSendableStatusDpae(
                                                      _vm.selectedContractData
                                                        .dpaeStatus
                                                    ),
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.$emit(
                                                      "user-dialog:send-single-dpae",
                                                      [
                                                        _vm.selectedContractData
                                                          .id,
                                                      ]
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n\t\t\t\t\t\t\t\t" +
                                                    _vm._s(
                                                      _vm.FormMSG(
                                                        357,
                                                        "Send to DPAE"
                                                      )
                                                    ) +
                                                    "\n\t\t\t\t\t\t\t"
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm.useDpae
                                          ? _c(
                                              "div",
                                              [
                                                _c(
                                                  _vm.getLucideIcon(
                                                    _vm.getDpaeStatusIcon(
                                                      _vm.selectedContractData
                                                        .dpaeStatus
                                                    )
                                                  ),
                                                  {
                                                    tag: "component",
                                                    staticClass: "mr-2",
                                                    class:
                                                      _vm.getDpaeStatusClass(
                                                        _vm.selectedContractData
                                                          .dpaeStatus
                                                      ),
                                                    attrs: {
                                                      size: 20,
                                                      "stroke-width": 2,
                                                    },
                                                  }
                                                ),
                                                _c(
                                                  "span",
                                                  {
                                                    class:
                                                      _vm.selectedContractData
                                                        .dpaeStatus === 1
                                                        ? _vm.getDpaeStatusClass(
                                                            _vm
                                                              .selectedContractData
                                                              .dpaeStatus
                                                          )
                                                        : "",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.getDpaeStatusText(
                                                          _vm
                                                            .selectedContractData
                                                            .dpaeStatus
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      {
                                        staticClass: "d-flex",
                                        staticStyle: { gap: "10px" },
                                        attrs: { cols: "12", sm: "auto" },
                                      },
                                      [
                                        _c(
                                          "b-button",
                                          {
                                            staticClass: "px-4",
                                            attrs: { variant: "light" },
                                            on: {
                                              click: function ($event) {
                                                return cancel()
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t" +
                                                _vm._s(
                                                  _vm.FormMSG(354, "Cancel")
                                                ) +
                                                "\n\t\t\t\t\t\t\t"
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "b-button",
                                          {
                                            staticClass: "px-4",
                                            attrs: {
                                              variant: "secondary",
                                              disabled:
                                                _vm.setSaveContractButtonDisable,
                                            },
                                            on: { click: _vm.onSaveContract },
                                          },
                                          [
                                            _c("b-spinner", {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.setSaveContractButtonDisable &&
                                                    _vm.executeSaveContractAction,
                                                  expression:
                                                    "setSaveContractButtonDisable && executeSaveContractAction",
                                                },
                                              ],
                                              staticClass: "mr-3",
                                              attrs: { small: "" },
                                            }),
                                            _vm._v(
                                              _vm._s(_vm.FormMSG(356, "Save")) +
                                                "\n\t\t\t\t\t\t\t"
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      }
                    : null,
                ],
                null,
                true
              ),
              model: {
                value: _vm.showContractModal,
                callback: function ($$v) {
                  _vm.showContractModal = $$v
                },
                expression: "showContractModal",
              },
            },
            [
              _vm.$screen.width > 576
                ? _c(
                    "b-container",
                    { attrs: { fluid: "" } },
                    [
                      _c(
                        "b-row",
                        { attrs: { "align-h": "end" } },
                        [
                          _c(
                            "b-col",
                            {
                              staticStyle: { display: "none" },
                              attrs: { cols: "2" },
                            },
                            [
                              _vm.selectedContractId
                                ? _c("v-select", {
                                    staticClass: "w-75 border-radius-status",
                                    attrs: {
                                      id: "contract-status-selector",
                                      label: "text",
                                      options: _vm.getStatusMenu,
                                      reduce: (option) => option.value,
                                    },
                                    on: { input: _vm.setContractStatus },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "option",
                                          fn: function (option) {
                                            return [
                                              _c(_vm.getLucideIcon("Circle"), {
                                                tag: "component",
                                                staticClass: "mr-3",
                                                class: option.selectColorClass,
                                                staticStyle: {
                                                  "border-radius": "100%",
                                                },
                                                attrs: {
                                                  size: 12,
                                                  "stroke-width": 1.5,
                                                },
                                              }),
                                              _vm._v(
                                                "\n\t\t\t\t\t\t\t\t" +
                                                  _vm._s(option.text) +
                                                  "\n\t\t\t\t\t\t\t"
                                              ),
                                            ]
                                          },
                                        },
                                        {
                                          key: "selected-option",
                                          fn: function ({
                                            text,
                                            selectColorClass,
                                          }) {
                                            return [
                                              _c(_vm.getLucideIcon("Circle"), {
                                                tag: "component",
                                                staticClass: "mr-3",
                                                class: selectColorClass,
                                                staticStyle: {
                                                  "border-radius": "100%",
                                                },
                                                attrs: {
                                                  size: 12,
                                                  "stroke-width": 1.5,
                                                },
                                              }),
                                              _vm._v(
                                                "\n\t\t\t\t\t\t\t\t" +
                                                  _vm._s(text) +
                                                  "\n\t\t\t\t\t\t\t"
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      2020378146
                                    ),
                                    model: {
                                      value: _vm.currentContractStatus,
                                      callback: function ($$v) {
                                        _vm.currentContractStatus = $$v
                                      },
                                      expression: "currentContractStatus",
                                    },
                                  })
                                : _c(
                                    "span",
                                    [
                                      _c(_vm.getLucideIcon("Circle"), {
                                        tag: "component",
                                        staticClass: "mr-3",
                                        class:
                                          _vm.getStatusMenu[0].selectColorClass,
                                        staticStyle: {
                                          "border-radius": "100%",
                                        },
                                        attrs: {
                                          size: 14,
                                          "stroke-width": 1.5,
                                        },
                                      }),
                                      _vm._v(_vm._s(_vm.getStatusMenu[0].text)),
                                    ],
                                    1
                                  ),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            {
                              staticClass: "d-flex px-0",
                              attrs: { cols: "auto" },
                            },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: _vm.FormMSG(11, "Contract no."),
                                    "label-for": "userId",
                                    "label-cols": "auto",
                                    "content-cols": "auto",
                                    horizontal: true,
                                  },
                                },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      id: "userId",
                                      value: _vm.selectedContractData.id
                                        ? _vm.selectedContractData.id
                                        : "",
                                      type: "text",
                                      disabled: "",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-dropdown",
                                {
                                  staticClass:
                                    "btn-transparent d-flex justify-content-end align-items-start",
                                  attrs: {
                                    "no-caret": "",
                                    boundary: "window",
                                    variant: "none",
                                    size: "sm",
                                    dropleft: "",
                                    offset: "35",
                                    lazy: "",
                                    disabled: true,
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "button-content",
                                        fn: function () {
                                          return [
                                            _c(
                                              _vm.getLucideIcon("MoreVertical"),
                                              {
                                                tag: "component",
                                                attrs: {
                                                  size: 25,
                                                  "stroke-width": 1.5,
                                                },
                                              }
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    false,
                                    2480355669
                                  ),
                                },
                                [
                                  _c("b-dropdown-item", [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "text-color-rhapsody-in-blue d-flex align-items-center",
                                      },
                                      [
                                        _c(_vm.getLucideIcon("Mail"), {
                                          tag: "component",
                                          attrs: { size: 16 },
                                        }),
                                        _c("div", { staticClass: "ml-2" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.FormMSG(125, "Email user")
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _c("b-dropdown-item", [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "text-color-rhapsody-in-blue d-flex align-items-center",
                                      },
                                      [
                                        _c(_vm.getLucideIcon("Download"), {
                                          tag: "component",
                                          attrs: { size: 16 },
                                        }),
                                        _c("div", { staticClass: "ml-2" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.FormMSG(
                                                2003,
                                                "Download infosheet"
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _c("b-dropdown-item", [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "text-color-rhapsody-in-blue d-flex align-items-center",
                                      },
                                      [
                                        _c(_vm.getLucideIcon("Trash"), {
                                          tag: "component",
                                          staticClass: "text-danger",
                                          attrs: { size: 16 },
                                        }),
                                        _c("div", { staticClass: "ml-2" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.FormMSG(63, "Delete user")
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c("contract-dialog", {
                attrs: {
                  contractInput: _vm.selectedContractData,
                  "hide-send-package-btn": true,
                  "fill-class-container": "container-layout",
                  "user-id": _vm.userId,
                  "show-pay-code": _vm.showPayCode,
                  "is-saved": _vm.executeSaveContractAction,
                },
                on: {
                  isLoading: function ($event) {
                    _vm.isLoading = $event
                  },
                  successModal: function ($event) {
                    _vm.successModal = $event
                  },
                  erreur: ($event) => {
                    _vm.erreur = $event
                    _vm.setSaveContractButtonDisable = false
                    _vm.executeSaveContractAction = false
                  },
                  "contract-dialog:contract-data-change": function ($event) {
                    _vm.setSaveContractButtonDisable = false
                  },
                  "contract-dialog:contract-saved": _vm.contractSaved,
                  "contract-dialog:custom-field-value-inputs:changed":
                    _vm.onCustomFieldValueInputsChanged,
                },
              }),
            ],
            1
          ),
          _c("custom-field-modal", {
            ref: "userRequestCustomField",
            attrs: {
              "parent-type": _vm.defaultUserRequestCustomFieldParentType,
            },
            on: {
              "custom-field-main-modal:closed":
                _vm.onUserRequestCustomFieldMainModalClosed,
              "custom-field-main-modal:reload":
                _vm.onUserRequestCustomFieldMainModalReloaded,
            },
            model: {
              value: _vm.isUserRequestCustomFieldModalOpen,
              callback: function ($$v) {
                _vm.isUserRequestCustomFieldModalOpen = $$v
              },
              expression: "isUserRequestCustomFieldModalOpen",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }