var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-tooltip",
    {
      attrs: {
        id: "dpaetooltip",
        "custom-class": "dpae-custom-tooltip custom-tooltip",
        target: "tooltip-target-" + _vm.target,
        triggers: "hover",
        placement: "left",
      },
    },
    [
      _vm.contractDpaeStatus === _vm.DPAE_STATUS.NOT_SENT &&
      _vm.checkIfThereIsInvalidFields(_vm.user)
        ? _c(
            "b-row",
            {
              staticClass:
                "align-items-start text-start v-boottrap-custom-tooltip",
            },
            [
              _c(
                "b-col",
                { staticClass: "text-start py-1", attrs: { sm: "12" } },
                [
                  _c(
                    "div",
                    { staticClass: "missing-info-title bottom-separator" },
                    [_vm._v(_vm._s(_vm.FormMSG(9, "Missing info")))]
                  ),
                  _vm.checkIfThereIsInvalidFieldsInUser(_vm.user)
                    ? _c(
                        "div",
                        { staticClass: "missing-info-sub-title pt-1" },
                        [_vm._v(_vm._s(_vm.FormMSG(7, "User :")))]
                      )
                    : _vm._e(),
                  _vm.checkIfThereIsInvalidFieldsInUser(_vm.user)
                    ? _c("div", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.getUserInvalidFields(
                              _vm.user.dpaeInvalidFields.userInvalidFields
                            )
                          ),
                        },
                      })
                    : _vm._e(),
                  _vm.checkIfThereIsInvalidFieldsInLegalEntity(_vm.user)
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "missing-info-sub-title top-separator pt-1",
                        },
                        [
                          _vm._v(
                            "\n\t\t\t\t" +
                              _vm._s(_vm.FormMSG(8, "Legal Entity :")) +
                              "\n\t\t\t"
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.checkIfThereIsInvalidFieldsInLegalEntity(_vm.user)
                    ? _c("div", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.getUserInvalidFields(
                              _vm.user.dpaeInvalidFields
                                .legalEntityInvalidFields
                            )
                          ),
                        },
                      })
                    : _vm._e(),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.contractDpaeStatus === _vm.DPAE_STATUS.PROCESSING
        ? _c(
            "b-row",
            {
              staticClass:
                "align-items-start text-start v-boottrap-custom-tooltip",
            },
            [
              _c(
                "b-col",
                {
                  staticClass:
                    "text-start py-1 d-flex flex-row align-items-center",
                  attrs: { sm: "12" },
                },
                [
                  _c("div", { staticClass: "missing-info-sub-title" }, [
                    _vm._v(_vm._s(_vm.FormMSG(1, "Sent to DPAE :"))),
                  ]),
                  _c("div", { staticClass: "pl-2" }, [
                    _vm._v(
                      "\n\t\t\t\t" +
                        _vm._s(
                          _vm.formatDate(
                            _vm.forUser
                              ? _vm.data.contractDpaeSendDate
                              : _vm.data.dpaeSendDate
                          )
                        ) +
                        "\n\t\t\t"
                    ),
                  ]),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.contractDpaeStatus === _vm.DPAE_STATUS.CONFIRMED
        ? _c(
            "b-row",
            {
              staticClass:
                "align-items-start text-start v-boottrap-custom-tooltip",
            },
            [
              _c(
                "b-col",
                {
                  staticClass:
                    "text-start py-1 d-flex flex-row align-items-center",
                  attrs: { sm: "12" },
                },
                [
                  _c("div", { staticClass: "missing-info-sub-title" }, [
                    _vm._v(_vm._s(_vm.FormMSG(1, "Sent to DPAE :"))),
                  ]),
                  _c("div", { staticClass: "pl-2" }, [
                    _vm._v(
                      "\n\t\t\t\t" +
                        _vm._s(
                          _vm.formatDate(
                            _vm.forUser
                              ? _vm.data.contractDpaeSendDate
                              : _vm.data.dpaeSendDate
                          )
                        ) +
                        "\n\t\t\t"
                    ),
                  ]),
                ]
              ),
              _c(
                "b-col",
                {
                  staticClass:
                    "text-start py-1 d-flex flex-row align-items-center",
                  attrs: { sm: "12" },
                },
                [
                  _c("div", { staticClass: "missing-info-sub-title" }, [
                    _vm._v(_vm._s(_vm.FormMSG(5, "DPAE Publication :"))),
                  ]),
                  _c("div", { staticClass: "pl-2" }, [
                    _vm._v(
                      "\n\t\t\t\t" +
                        _vm._s(
                          _vm.formatDate(
                            _vm.forUser
                              ? _vm.data.contractDpaePublicationDate
                              : _vm.data.dpaePublicationDate
                          )
                        ) +
                        "\n\t\t\t"
                    ),
                  ]),
                ]
              ),
              _c(
                "b-col",
                {
                  staticClass:
                    "text-start py-1 d-flex flex-row align-items-center",
                  attrs: { sm: "12" },
                },
                [
                  _c("div", { staticClass: "missing-info-sub-title" }, [
                    _vm._v(_vm._s(_vm.FormMSG(2, "Ref Dossier :"))),
                  ]),
                  _c("div", { staticClass: "pl-2" }, [
                    _vm._v(
                      "\n\t\t\t\t" +
                        _vm._s(
                          _vm.forUser
                            ? _vm.data.contractDpaeContractRef
                            : _vm.data.dpaeContractRef
                        ) +
                        "\n\t\t\t"
                    ),
                  ]),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.contractDpaeStatus === _vm.DPAE_STATUS.NOT_SENT_MISSING_INFO
        ? _c(
            "b-row",
            {
              staticClass:
                "align-items-start text-start v-boottrap-custom-tooltip",
            },
            [
              _c(
                "b-col",
                { staticClass: "text-start py-1", attrs: { sm: "12" } },
                [
                  _vm._v(
                    _vm._s(_vm.FormMSG(1, "Sent to DPAE :")) +
                      " " +
                      _vm._s(
                        _vm.formatDate(
                          _vm.forUser
                            ? _vm.data.contractDpaeSendDate
                            : _vm.data.dpaeSendDate
                        )
                      ) +
                      "\n\t\t"
                  ),
                ]
              ),
              _c(
                "b-col",
                { staticClass: "text-start py-1", attrs: { sm: "12" } },
                [
                  _vm._v(
                    _vm._s(_vm.FormMSG(2, "Ref Dossier :")) +
                      " " +
                      _vm._s(
                        _vm.forUser
                          ? _vm.data.contractDpaeContractRef
                          : _vm.data.dpaeContractRef
                      ) +
                      "\n\t\t"
                  ),
                ]
              ),
              _c(
                "b-col",
                { staticClass: "text-start py-1", attrs: { sm: "12" } },
                [
                  _vm._v(
                    _vm._s(_vm.FormMSG(3, "Return Code :")) +
                      " " +
                      _vm._s(
                        _vm.forUser
                          ? _vm.data.contractDpaeReturnMessage
                          : _vm.data.dpaeReturnMessage
                      ) +
                      "\n\t\t"
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.contractDpaeStatus === _vm.DPAE_STATUS.FAILED
        ? _c(
            "b-row",
            {
              staticClass:
                "align-items-start text-start v-boottrap-custom-tooltip",
            },
            [
              _c(
                "b-col",
                {
                  staticClass:
                    "text-start py-1 d-flex flex-row align-items-center",
                  attrs: { sm: "12" },
                },
                [
                  _c("div", { staticClass: "missing-info-sub-title" }, [
                    _vm._v(_vm._s(_vm.FormMSG(1, "Sent to DPAE :"))),
                  ]),
                  _c("div", { staticClass: "pl-2" }, [
                    _vm._v(
                      "\n\t\t\t\t" +
                        _vm._s(
                          _vm.formatDate(
                            _vm.forUser
                              ? _vm.data.contractDpaeSendDate
                              : _vm.data.dpaeSendDate
                          )
                        ) +
                        "\n\t\t\t"
                    ),
                  ]),
                ]
              ),
              _c(
                "b-col",
                {
                  staticClass:
                    "text-start py-1 d-flex flex-row align-items-center",
                  attrs: { sm: "12" },
                },
                [
                  _c("div", { staticClass: "missing-info-sub-title" }, [
                    _vm._v(_vm._s(_vm.FormMSG(2, "Ref Dossier :"))),
                  ]),
                  _c("div", { staticClass: "pl-2" }, [
                    _vm._v(
                      "\n\t\t\t\t" +
                        _vm._s(
                          _vm.forUser
                            ? _vm.data.contractDpaeContractRef
                            : _vm.data.dpaeContractRef
                        ) +
                        "\n\t\t\t"
                    ),
                  ]),
                ]
              ),
              _c(
                "b-col",
                {
                  staticClass:
                    "text-start py-1 d-flex flex-row align-items-center",
                  attrs: { sm: "12" },
                },
                [
                  _c("div", { staticClass: "missing-info-sub-title" }, [
                    _vm._v(_vm._s(_vm.FormMSG(3, "Return Code :"))),
                  ]),
                  _c("div", { staticClass: "pl-2" }, [
                    _vm._v(
                      "\n\t\t\t\t" +
                        _vm._s(
                          _vm.forUser
                            ? _vm.data.contractDpaeReturnMessage
                            : _vm.data.dpaeReturnMessage
                        ) +
                        "\n\t\t\t"
                    ),
                  ]),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }