var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "errorContainer",
    { attrs: { error: _vm.erreur } },
    [
      _vm.Users
        ? _c("div", [
            _c("div", { staticClass: "w-auto" }, [
              _c(
                "h1",
                {
                  class: [
                    `${!_vm.$screen.sm ? "main-page-title" : ""}`,
                    { "is-pwa": _vm.$isPwa() },
                  ],
                },
                [
                  _vm._v(
                    "\n\t\t\t\t" +
                      _vm._s(this.FormMSG(10000, "Crew setup")) +
                      "\n\t\t\t"
                  ),
                ]
              ),
            ]),
            _c(
              "div",
              { class: `${_vm.$screen.width <= 576 ? "my-project" : ""}` },
              [
                _c(
                  "transition",
                  { attrs: { name: "slide" } },
                  [
                    _c(
                      "b-tabs",
                      {
                        on: {
                          "activate-tab": (n, p, e) => _vm.onTabClicked(n),
                        },
                        model: {
                          value: _vm.tabs.activeTabs,
                          callback: function ($$v) {
                            _vm.$set(_vm.tabs, "activeTabs", $$v)
                          },
                          expression: "tabs.activeTabs",
                        },
                      },
                      [
                        _vm.canSeeApprove &&
                        !_vm.isForProjects &&
                        !_vm.isForGreenshotUser &&
                        _vm.useDoc
                          ? _c(
                              "b-tab",
                              {
                                attrs: {
                                  lazy: "",
                                  title: _vm.FormMSG(1600, "Approve"),
                                },
                              },
                              [
                                _c("ValidateDocuments", {
                                  ref: "validate-documents",
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.canSeeUserSetting ||
                        (_vm.isDev && _vm.isForProjects) ||
                        (_vm.isDev && _vm.isForGreenshotUser)
                          ? _c(
                              "b-tab",
                              {
                                attrs: {
                                  lazy: "",
                                  title: _vm.FormMSG(1, "Users"),
                                },
                              },
                              [
                                _c(
                                  "b-card",
                                  {
                                    staticClass:
                                      "card-border-blue-light form p-4",
                                    attrs: { "no-body": "" },
                                  },
                                  [
                                    _vm.Users && _vm.$screen.width >= 992
                                      ? _c(
                                          "b-row",
                                          {
                                            staticClass:
                                              "form align-items-center",
                                          },
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pb-3",
                                                attrs: {
                                                  sm: "12",
                                                  md: "12",
                                                  lg: "6",
                                                  xl: "6",
                                                },
                                              },
                                              [
                                                _vm.$screen.width >= 992
                                                  ? _c(
                                                      "b-input-group",
                                                      [
                                                        _c(
                                                          "filter-input-search",
                                                          {
                                                            on: {
                                                              "filtered-users":
                                                                _vm.filteredUsers,
                                                              "handle-init-filter":
                                                                _vm.onHandleInitFilter,
                                                            },
                                                          }
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                            _vm.isForProject
                                              ? _c(
                                                  "b-col",
                                                  {
                                                    staticClass: "pb-3",
                                                    attrs: {
                                                      sm: "4",
                                                      md: "4",
                                                      lg: "4",
                                                      xl: "4",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "b-row",
                                                      [
                                                        _c(
                                                          "b-col",
                                                          {
                                                            attrs: {
                                                              sm: "2",
                                                              md: "2",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.FormMSG(
                                                                    1715,
                                                                    "Show"
                                                                  )
                                                                )
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "b-col",
                                                          {
                                                            attrs: {
                                                              sm: "4",
                                                              md: "4",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "b-row",
                                                              [
                                                                _c(
                                                                  "radio-button",
                                                                  {
                                                                    attrs: {
                                                                      inputId:
                                                                        "users",
                                                                      name: "users",
                                                                      value:
                                                                        "users",
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm.usersListDisplayedBy,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.usersListDisplayedBy =
                                                                            $$v
                                                                        },
                                                                      expression:
                                                                        "usersListDisplayedBy",
                                                                    },
                                                                  }
                                                                ),
                                                                _c(
                                                                  "label",
                                                                  {
                                                                    staticClass:
                                                                      "ml-2",
                                                                    attrs: {
                                                                      for: "users",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          279,
                                                                          "By Users"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "b-col",
                                                          {
                                                            attrs: {
                                                              sm: "4",
                                                              md: "4",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "b-row",
                                                              [
                                                                _c(
                                                                  "radio-button",
                                                                  {
                                                                    attrs: {
                                                                      inputId:
                                                                        "contract",
                                                                      name: "contract",
                                                                      value:
                                                                        "contract",
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm.usersListDisplayedBy,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.usersListDisplayedBy =
                                                                            $$v
                                                                        },
                                                                      expression:
                                                                        "usersListDisplayedBy",
                                                                    },
                                                                  }
                                                                ),
                                                                _c(
                                                                  "label",
                                                                  {
                                                                    staticClass:
                                                                      "ml-2",
                                                                    attrs: {
                                                                      for: "contract",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                        _vm._s(
                                                                          _vm.FormMSG(
                                                                            278,
                                                                            "By Contract"
                                                                          )
                                                                        ) +
                                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _c(
                                              "b-col",
                                              {
                                                staticClass:
                                                  "pb-3 d-flex justify-content-end",
                                              },
                                              [
                                                _vm.useDpae
                                                  ? _c(
                                                      "b-button",
                                                      {
                                                        attrs: {
                                                          variant: "primary",
                                                        },
                                                        on: {
                                                          click: _vm.reloadData,
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          _vm.getLucideIcon(
                                                            "RefreshCw"
                                                          ),
                                                          {
                                                            tag: "component",
                                                            attrs: { size: 16 },
                                                          }
                                                        ),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass: "ml-1",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.FormMSG(
                                                                    110,
                                                                    "Retrieve Data"
                                                                  )
                                                                )
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.$screen.width < 992
                                      ? _c("CardListBuilder", {
                                          attrs: {
                                            items: _vm.Users,
                                            fields: _vm.Mobilefields,
                                            omittedFields: [
                                              "isProd",
                                              "isAdmin",
                                              "validationLevel",
                                              "pict",
                                              "canAssign",
                                              "Screen Access",
                                            ],
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "body",
                                                fn: function (data) {
                                                  return [
                                                    _c(
                                                      "b-row",
                                                      {
                                                        staticStyle: {
                                                          "margin-top": "20px",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "b-col",
                                                          {
                                                            attrs: { md: "12" },
                                                          },
                                                          [
                                                            _c(
                                                              "b-form-select",
                                                              {
                                                                attrs: {
                                                                  options:
                                                                    _vm.optionsForValidationLevel,
                                                                  id: "valLevel",
                                                                  size: "md",
                                                                },
                                                                on: {
                                                                  change:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.manageValidationChange(
                                                                        data.item
                                                                      )
                                                                    },
                                                                },
                                                                model: {
                                                                  value:
                                                                    data.item
                                                                      .validationLevel,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        data.item,
                                                                        "validationLevel",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "data.item.validationLevel",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "b-row",
                                                      {
                                                        staticClass: "lh-22",
                                                        staticStyle: {
                                                          "margin-top": "20px",
                                                          "flex-direction":
                                                            "row",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "b-col",
                                                          {
                                                            attrs: { xs: "6" },
                                                          },
                                                          [
                                                            _c(
                                                              "b",
                                                              {
                                                                staticClass:
                                                                  "mb-2",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.FormMSG(
                                                                      1000,
                                                                      "Screen Access"
                                                                    )
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "b-col",
                                                              {
                                                                staticClass:
                                                                  "mb-3",
                                                                attrs: {
                                                                  md: "12",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "b-form-checkbox",
                                                                  {
                                                                    attrs: {
                                                                      size: "sm",
                                                                      value: true,
                                                                      "unchecked-value": false,
                                                                    },
                                                                    on: {
                                                                      change:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.handleAccessLevel(
                                                                            "canManageCrewList",
                                                                            $event,
                                                                            data.item,
                                                                            _vm.screenAccess
                                                                          )
                                                                        },
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        data
                                                                          .item
                                                                          .canManageCrewList,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            data.item,
                                                                            "canManageCrewList",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "data.item.canManageCrewList",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                        _vm._s(
                                                                          _vm.FormMSG(
                                                                            2970,
                                                                            "Manage crew list"
                                                                          )
                                                                        ) +
                                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "b-col",
                                                              {
                                                                staticClass:
                                                                  "mb-3",
                                                                attrs: {
                                                                  md: "12",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "b-form-checkbox",
                                                                  {
                                                                    attrs: {
                                                                      size: "sm",
                                                                      value: true,
                                                                      "unchecked-value": false,
                                                                    },
                                                                    on: {
                                                                      change:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.handleAccessLevel(
                                                                            "canManageDocuments",
                                                                            $event,
                                                                            data.item,
                                                                            _vm.screenAccess
                                                                          )
                                                                        },
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        data
                                                                          .item
                                                                          .canManageDocuments,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            data.item,
                                                                            "canManageDocuments",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "data.item.canManageDocuments",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                        _vm._s(
                                                                          _vm.FormMSG(
                                                                            2971,
                                                                            "Manage documents"
                                                                          )
                                                                        ) +
                                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "b-col",
                                                              {
                                                                staticClass:
                                                                  "mb-3",
                                                                attrs: {
                                                                  md: "12",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "b-form-checkbox",
                                                                  {
                                                                    attrs: {
                                                                      size: "sm",
                                                                      value: true,
                                                                      "unchecked-value": false,
                                                                    },
                                                                    on: {
                                                                      change:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.handleAccessLevel(
                                                                            "canManageDocumentPackage",
                                                                            $event,
                                                                            data.item,
                                                                            _vm.screenAccess
                                                                          )
                                                                        },
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        data
                                                                          .item
                                                                          .canManageDocumentPackage,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            data.item,
                                                                            "canManageDocumentPackage",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "data.item.canManageDocumentPackage",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                        _vm._s(
                                                                          _vm.FormMSG(
                                                                            2440,
                                                                            "Manage document package"
                                                                          )
                                                                        ) +
                                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "b-col",
                                                              {
                                                                staticClass:
                                                                  "mb-3",
                                                                attrs: {
                                                                  md: "12",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "b-form-checkbox",
                                                                  {
                                                                    attrs: {
                                                                      size: "sm",
                                                                      value: true,
                                                                      "unchecked-value": false,
                                                                    },
                                                                    on: {
                                                                      change:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.handleAccessLevel(
                                                                            "canManageCallSheets",
                                                                            $event,
                                                                            data.item,
                                                                            _vm.screenAccess
                                                                          )
                                                                        },
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        data
                                                                          .item
                                                                          .canManageCallSheets,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            data.item,
                                                                            "canManageCallSheets",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "data.item.canManageCallSheets",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                        _vm._s(
                                                                          _vm.FormMSG(
                                                                            2450,
                                                                            "Manage call sheets"
                                                                          )
                                                                        ) +
                                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "b-col",
                                                              {
                                                                staticClass:
                                                                  "mb-3",
                                                                attrs: {
                                                                  md: "12",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "b-form-checkbox",
                                                                  {
                                                                    attrs: {
                                                                      size: "sm",
                                                                      value: true,
                                                                      "unchecked-value": false,
                                                                    },
                                                                    on: {
                                                                      change:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.handleAccessLevel(
                                                                            "canManageAccommodations",
                                                                            $event,
                                                                            data.item,
                                                                            _vm.screenAccess
                                                                          )
                                                                        },
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        data
                                                                          .item
                                                                          .canManageAccommodations,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            data.item,
                                                                            "canManageAccommodations",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "data.item.canManageAccommodations",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                        _vm._s(
                                                                          _vm.FormMSG(
                                                                            2973,
                                                                            "Manage accomodations"
                                                                          )
                                                                        ) +
                                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "b-col",
                                                              {
                                                                staticClass:
                                                                  "mb-3",
                                                                attrs: {
                                                                  md: "12",
                                                                },
                                                              },
                                                              [
                                                                _vm.useLoc
                                                                  ? _c(
                                                                      "b-form-checkbox",
                                                                      {
                                                                        attrs: {
                                                                          size: "sm",
                                                                          value: true,
                                                                          "unchecked-value": false,
                                                                        },
                                                                        on: {
                                                                          change:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.handleAccessLevel(
                                                                                "canManageLocations",
                                                                                $event,
                                                                                data.item,
                                                                                _vm.screenAccess
                                                                              )
                                                                            },
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            data
                                                                              .item
                                                                              .canManageLocations,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                data.item,
                                                                                "canManageLocations",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "data.item.canManageLocations",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                            _vm._s(
                                                                              _vm.FormMSG(
                                                                                2449,
                                                                                "Manage locations"
                                                                              )
                                                                            ) +
                                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "b-col",
                                                              {
                                                                staticClass:
                                                                  "mb-3",
                                                                attrs: {
                                                                  md: "12",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "b-form-checkbox",
                                                                  {
                                                                    attrs: {
                                                                      size: "sm",
                                                                      value: true,
                                                                      "unchecked-value": false,
                                                                    },
                                                                    on: {
                                                                      change:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.handleAccessLevel(
                                                                            "canExportImport",
                                                                            $event,
                                                                            data.item,
                                                                            _vm.screenAccess
                                                                          )
                                                                        },
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        data
                                                                          .item
                                                                          .canExportImport,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            data.item,
                                                                            "canExportImport",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "data.item.canExportImport",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                        _vm._s(
                                                                          _vm.FormMSG(
                                                                            2455,
                                                                            "Can import/export"
                                                                          )
                                                                        ) +
                                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "b-col",
                                                              {
                                                                staticClass:
                                                                  "mb-3",
                                                                attrs: {
                                                                  md: "12",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "b-form-checkbox",
                                                                  {
                                                                    attrs: {
                                                                      size: "sm",
                                                                      value: true,
                                                                      "unchecked-value": false,
                                                                    },
                                                                    on: {
                                                                      change:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.handleAccessLevel(
                                                                            "canViewGlobalBudget",
                                                                            $event,
                                                                            data.item,
                                                                            _vm.screenAccess
                                                                          )
                                                                        },
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        data
                                                                          .item
                                                                          .canViewGlobalBudget,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            data.item,
                                                                            "canViewGlobalBudget",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "data.item.canViewGlobalBudget",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                        _vm._s(
                                                                          _vm.FormMSG(
                                                                            24430,
                                                                            "View global budget"
                                                                          )
                                                                        ) +
                                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "b-col",
                                                              {
                                                                staticClass:
                                                                  "mb-3",
                                                                attrs: {
                                                                  md: "12",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "b-form-checkbox",
                                                                  {
                                                                    attrs: {
                                                                      size: "sm",
                                                                      value: true,
                                                                      "unchecked-value": false,
                                                                    },
                                                                    on: {
                                                                      change:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.handleAccessLevel(
                                                                            "canAccessGreenReporting",
                                                                            $event,
                                                                            data.item,
                                                                            _vm.screenAccess
                                                                          )
                                                                        },
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        data
                                                                          .item
                                                                          .canAccessGreenReporting,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            data.item,
                                                                            "canAccessGreenReporting",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "data.item.canAccessGreenReporting",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                        _vm._s(
                                                                          _vm.FormMSG(
                                                                            24560,
                                                                            "View Green reporting screens"
                                                                          )
                                                                        ) +
                                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "b-col",
                                                              {
                                                                staticClass:
                                                                  "mb-3",
                                                                attrs: {
                                                                  md: "12",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "b-form-checkbox",
                                                                  {
                                                                    attrs: {
                                                                      size: "sm",
                                                                      value: true,
                                                                      "unchecked-value": false,
                                                                    },
                                                                    on: {
                                                                      change:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.handleAccessLevel(
                                                                            "canManageCarbonRemoval",
                                                                            $event,
                                                                            data.item,
                                                                            _vm.screenAccess
                                                                          )
                                                                        },
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        data
                                                                          .item
                                                                          .canManageCarbonRemoval,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            data.item,
                                                                            "canManageCarbonRemoval",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "data.item.canManageCarbonRemoval",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                        _vm._s(
                                                                          _vm.FormMSG(
                                                                            24480,
                                                                            "Manage carbon removal"
                                                                          )
                                                                        ) +
                                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "b-col",
                                                              {
                                                                staticClass:
                                                                  "mb-3",
                                                                attrs: {
                                                                  md: "12",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "b-form-checkbox",
                                                                  {
                                                                    attrs: {
                                                                      size: "sm",
                                                                      value: true,
                                                                      "unchecked-value": false,
                                                                    },
                                                                    on: {
                                                                      change:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.handleAccessLevel(
                                                                            "canManageTipOfTheDay",
                                                                            $event,
                                                                            data.item,
                                                                            _vm.screenAccess
                                                                          )
                                                                        },
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        data
                                                                          .item
                                                                          .canManageTipOfTheDay,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            data.item,
                                                                            "canManageTipOfTheDay",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "data.item.canManageTipOfTheDay",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                        _vm._s(
                                                                          _vm.FormMSG(
                                                                            24405,
                                                                            "Manage tip of the day"
                                                                          )
                                                                        ) +
                                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "b-col",
                                                              {
                                                                staticClass:
                                                                  "mb-3",
                                                                attrs: {
                                                                  md: "12",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "b-form-checkbox",
                                                                  {
                                                                    attrs: {
                                                                      size: "sm",
                                                                      value: true,
                                                                      "unchecked-value": false,
                                                                    },
                                                                    on: {
                                                                      change:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.handleAccessLevel(
                                                                            "canAccessConfig",
                                                                            $event,
                                                                            data.item,
                                                                            _vm.screenAccess
                                                                          )
                                                                        },
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        data
                                                                          .item
                                                                          .canAccessConfig,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            data.item,
                                                                            "canAccessConfig",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "data.item.canAccessConfig",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                        _vm._s(
                                                                          _vm.FormMSG(
                                                                            246,
                                                                            "Can access config"
                                                                          )
                                                                        ) +
                                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _vm.isPme
                                                              ? _c(
                                                                  "b-col",
                                                                  {
                                                                    staticClass:
                                                                      "mb-3",
                                                                    attrs: {
                                                                      md: "12",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "b-form-checkbox",
                                                                      {
                                                                        attrs: {
                                                                          size: "sm",
                                                                          value: true,
                                                                          "unchecked-value": false,
                                                                        },
                                                                        on: {
                                                                          change:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.handleAccessLevel(
                                                                                _vm.canAssign,
                                                                                $event,
                                                                                data.item,
                                                                                _vm.screenAccess
                                                                              )
                                                                            },
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            data
                                                                              .item
                                                                              .canAssign,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                data.item,
                                                                                "canAssign",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "data.item.canAssign",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                            _vm._s(
                                                                              _vm.FormMSG(
                                                                                30,
                                                                                "Planning Manager"
                                                                              )
                                                                            ) +
                                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e(),
                                                            !_vm.isForProject
                                                              ? _c(
                                                                  "b-col",
                                                                  {
                                                                    staticClass:
                                                                      "mb-3",
                                                                    attrs: {
                                                                      md: "12",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "b-form-checkbox",
                                                                      {
                                                                        attrs: {
                                                                          size: "sm",
                                                                          value: true,
                                                                          "unchecked-value": false,
                                                                        },
                                                                        on: {
                                                                          change:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.manageIsDevChange(
                                                                                $event,
                                                                                data.item
                                                                              )
                                                                            },
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            data
                                                                              .item
                                                                              .isDev,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                data.item,
                                                                                "isDev",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "data.item.isDev",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                            _vm._s(
                                                                              _vm.FormMSG(
                                                                                42,
                                                                                "View TheGreenShot Menu"
                                                                              )
                                                                            ) +
                                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e(),
                                                            !_vm.isForProject
                                                              ? _c(
                                                                  "b-col",
                                                                  {
                                                                    staticClass:
                                                                      "mb-3",
                                                                    attrs: {
                                                                      md: "12",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "b-form-checkbox",
                                                                      {
                                                                        attrs: {
                                                                          size: "sm",
                                                                          value: true,
                                                                          "unchecked-value": false,
                                                                        },
                                                                        on: {
                                                                          change:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.manageIsTranslatorChange(
                                                                                $event,
                                                                                data.item
                                                                              )
                                                                            },
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            data
                                                                              .item
                                                                              .isTranslator,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                data.item,
                                                                                "isTranslator",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "data.item.isTranslator",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                            _vm._s(
                                                                              _vm.FormMSG(
                                                                                43,
                                                                                "Translator"
                                                                              )
                                                                            ) +
                                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e(),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "b-col",
                                                          {
                                                            attrs: { xs: "6" },
                                                          },
                                                          [
                                                            _c(
                                                              "b",
                                                              {
                                                                staticClass:
                                                                  "mb-2",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.FormMSG(
                                                                      1001,
                                                                      "Role"
                                                                    )
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "b-form-checkbox",
                                                              {
                                                                staticClass:
                                                                  "mb-3",
                                                                attrs: {
                                                                  size: "sm",
                                                                },
                                                                on: {
                                                                  change:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.handleAccessLevel(
                                                                        _vm.gateKeeper,
                                                                        $event,
                                                                        data.item,
                                                                        _vm.screenAccess
                                                                      )
                                                                    },
                                                                },
                                                                model: {
                                                                  value:
                                                                    data.item
                                                                      .gateKeeper,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        data.item,
                                                                        "gateKeeper",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "data.item.gateKeeper",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                    _vm._s(
                                                                      _vm.FormMSG(
                                                                        266,
                                                                        "Gate Keeper"
                                                                      )
                                                                    ) +
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "b-form-checkbox",
                                                              {
                                                                staticClass:
                                                                  "mb-3",
                                                                attrs: {
                                                                  size: "sm",
                                                                },
                                                                on: {
                                                                  change:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.handleAccessLevel(
                                                                        _vm.productionAccountant,
                                                                        $event,
                                                                        data.item,
                                                                        _vm.screenAccess
                                                                      )
                                                                    },
                                                                },
                                                                model: {
                                                                  value:
                                                                    data.item
                                                                      .productionAccountant,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        data.item,
                                                                        "productionAccountant",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "data.item.productionAccountant",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                    _vm._s(
                                                                      _vm.FormMSG(
                                                                        261,
                                                                        "Production Account"
                                                                      )
                                                                    ) +
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "b-form-checkbox",
                                                              {
                                                                staticClass:
                                                                  "mb-3",
                                                                attrs: {
                                                                  size: "sm",
                                                                },
                                                                on: {
                                                                  change:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.manageUpmChange(
                                                                        $event,
                                                                        data.item
                                                                      )
                                                                    },
                                                                },
                                                                model: {
                                                                  value:
                                                                    data.item
                                                                      .upm,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        data.item,
                                                                        "upm",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "data.item.upm",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                    _vm._s(
                                                                      _vm.FormMSG(
                                                                        262,
                                                                        "UPM"
                                                                      )
                                                                    ) +
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "b-form-checkbox",
                                                              {
                                                                staticClass:
                                                                  "mb-3",
                                                                attrs: {
                                                                  size: "sm",
                                                                },
                                                                on: {
                                                                  change:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.handleAccessLevel(
                                                                        _vm.lineProducer,
                                                                        $event,
                                                                        data.item,
                                                                        _vm.screenAccess
                                                                      )
                                                                    },
                                                                },
                                                                model: {
                                                                  value:
                                                                    data.item
                                                                      .lineProducer,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        data.item,
                                                                        "lineProducer",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "data.item.lineProducer",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                    _vm._s(
                                                                      _vm.FormMSG(
                                                                        263,
                                                                        "Line Producer"
                                                                      )
                                                                    ) +
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "b-form-checkbox",
                                                              {
                                                                staticClass:
                                                                  "mb-3",
                                                                attrs: {
                                                                  size: "sm",
                                                                },
                                                                on: {
                                                                  change:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.manageStudioChange(
                                                                        $event,
                                                                        data.item
                                                                      )
                                                                    },
                                                                },
                                                                model: {
                                                                  value:
                                                                    data.item
                                                                      .studio,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        data.item,
                                                                        "studio",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "data.item.studio",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                    _vm._s(
                                                                      _vm.FormMSG(
                                                                        265,
                                                                        "Studio"
                                                                      )
                                                                    ) +
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                              },
                                              {
                                                key: "actions",
                                                fn: function (data) {
                                                  return [
                                                    _c(
                                                      "b-button",
                                                      {
                                                        staticClass:
                                                          "mr-1 btn bg-transparent border-0",
                                                        attrs: {
                                                          variant: "success",
                                                          size: "sm",
                                                          title: "Send email",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.sendEmail(
                                                              data.item
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          _vm.getLucideIcon(
                                                            _vm.ICONS.MAIL.name
                                                          ),
                                                          {
                                                            tag: "component",
                                                            attrs: {
                                                              color:
                                                                _vm.ICONS.MAIL
                                                                  .color,
                                                              size: 20,
                                                            },
                                                          }
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "b-button",
                                                      {
                                                        staticClass:
                                                          "mr-1 btn bg-transparent border-0",
                                                        attrs: {
                                                          variant: "success",
                                                          disabled:
                                                            data.item.phone
                                                              .length < 1,
                                                          size: "sm",
                                                          title:
                                                            "Call by phone",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.callPhone(
                                                              data.item
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          _vm.getLucideIcon(
                                                            _vm.ICONS.PHONE.name
                                                          ),
                                                          {
                                                            tag: "component",
                                                            attrs: {
                                                              color:
                                                                _vm.ICONS.PHONE
                                                                  .color,
                                                              size: 20,
                                                            },
                                                          }
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "b-button",
                                                      {
                                                        staticClass:
                                                          "btn bg-transparent border-0",
                                                        attrs: {
                                                          variant: "success",
                                                          disabled:
                                                            data.item.picture
                                                              .length < 1,
                                                          size: "sm",
                                                          title: "View picture",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.showPicture(
                                                              data.item.picture
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          _vm.getLucideIcon(
                                                            _vm.ICONS.PICTURE
                                                              .name
                                                          ),
                                                          {
                                                            tag: "component",
                                                            attrs: {
                                                              color:
                                                                _vm.ICONS
                                                                  .PICTURE
                                                                  .color,
                                                              size: 20,
                                                            },
                                                          }
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "b-button",
                                                      {
                                                        staticClass:
                                                          "mr-1 btn bg-transparent border-0",
                                                        attrs: {
                                                          variant: "success",
                                                          size: "sm",
                                                          title:
                                                            "Download infosheet",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.downloadInfosheet(
                                                              data.item.id
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          _vm.getLucideIcon(
                                                            _vm.ICONS.EYE.name
                                                          ),
                                                          {
                                                            tag: "component",
                                                            attrs: {
                                                              color: "#225CBD",
                                                              size: 20,
                                                            },
                                                          }
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "b-button",
                                                      {
                                                        staticClass:
                                                          "btn bg-transparent border-0",
                                                        attrs: {
                                                          variant: "primary",
                                                          size: "sm",
                                                          title: "Update user",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.rowClicked(
                                                              data.item
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          _vm.getLucideIcon(
                                                            _vm.ICONS.EDIT.name
                                                          ),
                                                          {
                                                            tag: "component",
                                                            attrs: {
                                                              color:
                                                                _vm.ICONS.EDIT
                                                                  .color,
                                                              size: 20,
                                                            },
                                                          }
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "b-button",
                                                      {
                                                        staticClass:
                                                          "btn bg-transparent border-0",
                                                        attrs: {
                                                          size: "sm",
                                                          disabled:
                                                            _vm.isDisabled(
                                                              data
                                                            ),
                                                          title: "Delete user",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.eraseUser(
                                                              data.item
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("delete", {
                                                          attrs: {
                                                            color: "tomato",
                                                            size: 20,
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            3156666122
                                          ),
                                        })
                                      : _vm._e(),
                                    _vm.$screen.width >= 992
                                      ? _c(
                                          "b-table",
                                          {
                                            ref: "table-users",
                                            attrs: {
                                              hover: _vm.hover,
                                              responsive: "",
                                              selectable: "",
                                              "selected-variant":
                                                _vm.selectedColor,
                                              "select-mode": _vm.selectMode,
                                              items: _vm.Users,
                                              fields: _vm.fields,
                                              "current-page": _vm.currentPage,
                                              "per-page": _vm.perPage,
                                              "sticky-header": "700px",
                                              "head-variant": _vm.hv,
                                              bordered: "",
                                              small: "",
                                              busy: _vm.isBusy,
                                              id: "containerUsers",
                                              "empty-text": _vm.FormMSG(
                                                250,
                                                "No data found"
                                              ),
                                              "show-empty": "",
                                            },
                                            on: {
                                              "row-clicked": _vm.rowClicked,
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "table-busy",
                                                  fn: function () {
                                                    return [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex justify-content-center align-items-center",
                                                        },
                                                        [
                                                          _c("b-spinner", {
                                                            staticClass:
                                                              "align-middle",
                                                            attrs: {
                                                              small: "",
                                                            },
                                                          }),
                                                          _c(
                                                            "strong",
                                                            {
                                                              staticClass:
                                                                "ml-2",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.FormMSG(
                                                                    353,
                                                                    "Loading"
                                                                  )
                                                                ) + "..."
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                                {
                                                  key: "head(actionSelected)",
                                                  fn: function () {
                                                    return [
                                                      _c("b-form-checkbox", {
                                                        attrs: { size: "lg" },
                                                        on: {
                                                          change:
                                                            _vm.selectAllItem,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.isAllItemSelected,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.isAllItemSelected =
                                                              $$v
                                                          },
                                                          expression:
                                                            "isAllItemSelected",
                                                        },
                                                      }),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                                {
                                                  key: "cell(actionSelected)",
                                                  fn: function (data) {
                                                    return [
                                                      _vm.isSendableStatusDpae(
                                                        data.item
                                                          .contractDpaeStatus
                                                      ) &&
                                                      !_vm.checkIfThereIsInvalidFields(
                                                        data.item
                                                      )
                                                        ? _c(
                                                            "b-form-checkbox",
                                                            {
                                                              attrs: {
                                                                size: "lg",
                                                              },
                                                              on: {
                                                                change:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.selectItem(
                                                                      data.item
                                                                    )
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  data.item
                                                                    .isSelected,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      data.item,
                                                                      "isSelected",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "data.item.isSelected",
                                                              },
                                                            }
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  },
                                                },
                                                {
                                                  key: "cell(isMandatoryFieldComplete)",
                                                  fn: function (data) {
                                                    return [
                                                      !data.item
                                                        .isMandatoryFieldComplete
                                                        ? _c(
                                                            _vm.getLucideIcon(
                                                              "AlertTriangle"
                                                            ),
                                                            {
                                                              tag: "component",
                                                              staticStyle: {
                                                                color:
                                                                  "#f5c567",
                                                              },
                                                              attrs: {
                                                                size: 16,
                                                              },
                                                            }
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t" +
                                                          _vm._s(
                                                            data.item
                                                              .isMandatoryFieldComplete
                                                              ? _vm.FormMSG(
                                                                  370,
                                                                  "Complete"
                                                                )
                                                              : _vm.FormMSG(
                                                                  371,
                                                                  "Missing Info"
                                                                )
                                                          ) +
                                                          "\n\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  },
                                                },
                                                {
                                                  key: "cell(functionName)",
                                                  fn: function (data) {
                                                    return [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t" +
                                                          _vm._s(
                                                            data.item
                                                              .customFunctionName
                                                              ? data.item
                                                                  .customFunctionName
                                                              : data.item
                                                                  .functionName
                                                          ) +
                                                          "\n\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  },
                                                },
                                                _vm.useDpae
                                                  ? {
                                                      key: "cell(contractDpaeStatus)",
                                                      fn: function (data) {
                                                        return [
                                                          _c("DpaeTooltip", {
                                                            attrs: {
                                                              contractDpaeStatus:
                                                                data.item
                                                                  .contractDpaeStatus,
                                                              data: data.item,
                                                              user: data.item,
                                                              target:
                                                                _vm.usersListDisplayedBy ===
                                                                "users"
                                                                  ? data.item.id
                                                                  : data.item
                                                                      .contractId,
                                                              "for-user": "",
                                                            },
                                                          }),
                                                          _c(
                                                            "div",
                                                            {
                                                              attrs: {
                                                                id:
                                                                  "tooltip-target-" +
                                                                  (_vm.usersListDisplayedBy ===
                                                                  "users"
                                                                    ? data.item
                                                                        .id
                                                                    : data.item
                                                                        .contractId),
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                [
                                                                  _c(
                                                                    _vm.getLucideIcon(
                                                                      _vm.getDpaeStatusIcon(
                                                                        data
                                                                          .item
                                                                          .contractDpaeStatus
                                                                      )
                                                                    ),
                                                                    {
                                                                      tag: "component",
                                                                      staticClass:
                                                                        "mr-2",
                                                                      class:
                                                                        _vm.getDpaeStatusClass(
                                                                          data
                                                                            .item
                                                                            .contractDpaeStatus
                                                                        ),
                                                                      attrs: {
                                                                        size: 20,
                                                                        "stroke-width": 2,
                                                                      },
                                                                    }
                                                                  ),
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      class:
                                                                        data
                                                                          .item
                                                                          .contractDpaeStatus ===
                                                                        1
                                                                          ? _vm.getDpaeStatusClass(
                                                                              data
                                                                                .item
                                                                                .contractDpaeStatus
                                                                            )
                                                                          : "",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.getDpaeStatusText(
                                                                            data
                                                                              .item
                                                                              .contractDpaeStatus
                                                                          )
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "ml-4 missing-info-text",
                                                                },
                                                                [
                                                                  data.item
                                                                    .contractDpaeStatus ===
                                                                    _vm
                                                                      .DPAE_STATUS
                                                                      .NOT_SENT &&
                                                                  _vm.checkIfThereIsInvalidFields(
                                                                    data.item
                                                                  )
                                                                    ? _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "text-center",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                              _vm._s(
                                                                                _vm.FormMSG(
                                                                                  999,
                                                                                  "Missing Info"
                                                                                )
                                                                              ) +
                                                                              "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                    }
                                                  : null,
                                                {
                                                  key: "cell(pict)",
                                                  fn: function (data) {
                                                    return [
                                                      _c(
                                                        "b-dropdown",
                                                        {
                                                          staticStyle: {
                                                            "border-right":
                                                              "0px !important",
                                                            "padding-top":
                                                              "0px !important",
                                                          },
                                                          attrs: {
                                                            "no-caret": "",
                                                            dropleft: "",
                                                            offset: "25",
                                                            variant: "none",
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "button-content",
                                                                fn: function () {
                                                                  return [
                                                                    _c(
                                                                      "more-vertical"
                                                                    ),
                                                                  ]
                                                                },
                                                                proxy: true,
                                                              },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                        },
                                                        [
                                                          _c(
                                                            "b-dropdown-item-button",
                                                            {
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.sendEmail(
                                                                      data.item
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                _vm.getLucideIcon(
                                                                  _vm.ICONS.MAIL
                                                                    .name
                                                                ),
                                                                {
                                                                  tag: "component",
                                                                  attrs: {
                                                                    color:
                                                                      _vm.ICONS
                                                                        .MAIL
                                                                        .color,
                                                                    size: 20,
                                                                  },
                                                                }
                                                              ),
                                                              _vm._v(
                                                                "  \n\t\t\t\t\t\t\t\t\t\t\t"
                                                              ),
                                                              _c("b", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.FormMSG(
                                                                      2000,
                                                                      "Send email"
                                                                    )
                                                                  )
                                                                ),
                                                              ]),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "b-dropdown-item-button",
                                                            {
                                                              attrs: {
                                                                disabled:
                                                                  data.item
                                                                    .phone
                                                                    .length < 1,
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.callPhone(
                                                                      data.item
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                _vm.getLucideIcon(
                                                                  _vm.ICONS
                                                                    .PHONE.name
                                                                ),
                                                                {
                                                                  tag: "component",
                                                                  attrs: {
                                                                    color:
                                                                      _vm.ICONS
                                                                        .PHONE
                                                                        .color,
                                                                    size: 20,
                                                                  },
                                                                }
                                                              ),
                                                              _vm._v(
                                                                "  \n\t\t\t\t\t\t\t\t\t\t\t"
                                                              ),
                                                              _c("b", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.FormMSG(
                                                                      2001,
                                                                      "Call by phone"
                                                                    )
                                                                  )
                                                                ),
                                                              ]),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "b-dropdown-item-button",
                                                            {
                                                              attrs: {
                                                                disabled:
                                                                  data.item
                                                                    .picture
                                                                    .length < 1,
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.showPicture(
                                                                      data.item
                                                                        .picture
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                _vm.getLucideIcon(
                                                                  _vm.ICONS
                                                                    .PICTURE
                                                                    .name
                                                                ),
                                                                {
                                                                  tag: "component",
                                                                  attrs: {
                                                                    color:
                                                                      _vm.ICONS
                                                                        .PICTURE
                                                                        .color,
                                                                    size: 20,
                                                                  },
                                                                }
                                                              ),
                                                              _vm._v(
                                                                "  \n\t\t\t\t\t\t\t\t\t\t\t"
                                                              ),
                                                              _c("b", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.FormMSG(
                                                                      2002,
                                                                      "View picture"
                                                                    )
                                                                  )
                                                                ),
                                                              ]),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "b-dropdown-item-button",
                                                            {
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.downloadInfosheet(
                                                                      data.item
                                                                        .id
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                _vm.getLucideIcon(
                                                                  "Download"
                                                                ),
                                                                {
                                                                  tag: "component",
                                                                  attrs: {
                                                                    color:
                                                                      "#225CBD",
                                                                    size: 20,
                                                                  },
                                                                }
                                                              ),
                                                              _vm._v(
                                                                "  \n\t\t\t\t\t\t\t\t\t\t\t"
                                                              ),
                                                              _c("b", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.FormMSG(
                                                                      2003,
                                                                      "Download infosheet"
                                                                    )
                                                                  )
                                                                ),
                                                              ]),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "b-dropdown-item-button",
                                                            {
                                                              attrs: {
                                                                disabled:
                                                                  _vm.isDisabled(
                                                                    data
                                                                  ),
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.eraseUser(
                                                                      data.item
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c("delete", {
                                                                attrs: {
                                                                  color:
                                                                    "tomato",
                                                                },
                                                              }),
                                                              _vm._v("   "),
                                                              _c("b", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.FormMSG(
                                                                      2004,
                                                                      "Delete user"
                                                                    )
                                                                  )
                                                                ),
                                                              ]),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  },
                                                },
                                                {
                                                  key: "cell(Impersonate)",
                                                  fn: function (data) {
                                                    return [
                                                      _c(
                                                        "b-button",
                                                        {
                                                          staticClass:
                                                            "btn bg-transparent border-0",
                                                          attrs: {
                                                            variant: "primary",
                                                            size: "sm",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.becomeUser(
                                                                data.item
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            _vm.getLucideIcon(
                                                              _vm.ICONS.EYE.name
                                                            ),
                                                            {
                                                              tag: "component",
                                                              attrs: {
                                                                color:
                                                                  "#225CBD",
                                                                size: 20,
                                                              },
                                                            }
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  },
                                                },
                                                {
                                                  key: "cell(delUser)",
                                                  fn: function (data) {
                                                    return [
                                                      _vm.myUserId !=
                                                      data.item.id
                                                        ? _c(
                                                            "b-button",
                                                            {
                                                              attrs: {
                                                                variant: "none",
                                                                size: "sm",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.deleteUser(
                                                                      data.item
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                _vm.getLucideIcon(
                                                                  _vm.ICONS
                                                                    .TRASH.name
                                                                ),
                                                                {
                                                                  tag: "component",
                                                                  attrs: {
                                                                    color:
                                                                      _vm.ICONS
                                                                        .TRASH
                                                                        .color,
                                                                    size: 20,
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  },
                                                },
                                                {
                                                  key: "cell(isDev)",
                                                  fn: function (data) {
                                                    return [
                                                      _c("b-form-checkbox", {
                                                        attrs: { size: "lg" },
                                                        on: {
                                                          change: function (
                                                            $event
                                                          ) {
                                                            return _vm.manageIsDevChange(
                                                              $event,
                                                              data.item
                                                            )
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            data.item.isDev,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              data.item,
                                                              "isDev",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "data.item.isDev",
                                                        },
                                                      }),
                                                    ]
                                                  },
                                                },
                                                {
                                                  key: "cell(isTranslator)",
                                                  fn: function (data) {
                                                    return [
                                                      _c("b-form-checkbox", {
                                                        attrs: { size: "lg" },
                                                        on: {
                                                          change: function (
                                                            $event
                                                          ) {
                                                            return _vm.manageIsTranslatorChange(
                                                              $event,
                                                              data.item
                                                            )
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            data.item
                                                              .isTranslator,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              data.item,
                                                              "isTranslator",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "data.item.isTranslator",
                                                        },
                                                      }),
                                                    ]
                                                  },
                                                },
                                                {
                                                  key: "cell(validationLevel)",
                                                  fn: function (data) {
                                                    return [
                                                      _c("b-form-select", {
                                                        attrs: {
                                                          options:
                                                            _vm.optionsForValidationLevel,
                                                          id: "valLevel",
                                                          size: "md",
                                                        },
                                                        on: {
                                                          change: function (
                                                            $event
                                                          ) {
                                                            return _vm.manageValidationChange(
                                                              data.item
                                                            )
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            data.item
                                                              .validationLevel,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              data.item,
                                                              "validationLevel",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "data.item.validationLevel",
                                                        },
                                                      }),
                                                    ]
                                                  },
                                                },
                                                {
                                                  key: "cell(info)",
                                                  fn: function (data) {
                                                    return [
                                                      _c(
                                                        "a",
                                                        {
                                                          attrs: { href: "#" },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              $event.preventDefault()
                                                              return _vm.rowClicked(
                                                                data.item
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            _vm.getLucideIcon(
                                                              _vm.ICONS.INFO
                                                                .name
                                                            ),
                                                            {
                                                              tag: "component",
                                                              attrs: {
                                                                color:
                                                                  _vm.ICONS.INFO
                                                                    .color,
                                                                size: 20,
                                                              },
                                                            }
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  },
                                                },
                                                {
                                                  key: "cell(permissions)",
                                                  fn: function (data) {
                                                    return [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex justify-content-center",
                                                          staticStyle: {
                                                            "min-width": "60px",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "b-dropdown",
                                                            {
                                                              class:
                                                                _vm.lengthUsers <=
                                                                8
                                                                  ? "position-absolute"
                                                                  : "",
                                                              attrs: {
                                                                id: "dropdown-offer-action",
                                                                size: "sm",
                                                                text: _vm.FormMSG(
                                                                  249,
                                                                  "Permissions"
                                                                ),
                                                                variant:
                                                                  "warning",
                                                                dropleft: "",
                                                                boundary:
                                                                  "window",
                                                              },
                                                            },
                                                            [
                                                              _vm.isForProject
                                                                ? _c(
                                                                    "b-dropdown-item",
                                                                    {
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.handleAccessLevel(
                                                                              "canManageCrewList",
                                                                              !data
                                                                                .item
                                                                                .canManageCrewList,
                                                                              data.item,
                                                                              _vm.screenAccess
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "b-form-checkbox",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              size: "sm",
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                data
                                                                                  .item
                                                                                  .canManageCrewList,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    data.item,
                                                                                    "canManageCrewList",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "data.item.canManageCrewList",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                              _vm._s(
                                                                                _vm.FormMSG(
                                                                                  2970,
                                                                                  "Manage crew list"
                                                                                )
                                                                              ) +
                                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                              _vm.isForProject &&
                                                              _vm.useDoc
                                                                ? _c(
                                                                    "b-dropdown-item",
                                                                    {
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.handleAccessLevel(
                                                                              "canManageDocuments",
                                                                              !data
                                                                                .item
                                                                                .canManageDocuments,
                                                                              data.item,
                                                                              _vm.screenAccess
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "b-form-checkbox",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              size: "sm",
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                data
                                                                                  .item
                                                                                  .canManageDocuments,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    data.item,
                                                                                    "canManageDocuments",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "data.item.canManageDocuments",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                              _vm._s(
                                                                                _vm.FormMSG(
                                                                                  2971,
                                                                                  "Manage documents"
                                                                                )
                                                                              ) +
                                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                              _vm.isForProject &&
                                                              _vm.useDoc
                                                                ? _c(
                                                                    "b-dropdown-item",
                                                                    {
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.handleAccessLevel(
                                                                              "canManageDocumentPackage",
                                                                              !data
                                                                                .item
                                                                                .canManageDocumentPackage,
                                                                              data.item,
                                                                              _vm.screenAccess
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "b-form-checkbox",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              size: "sm",
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                data
                                                                                  .item
                                                                                  .canManageDocumentPackage,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    data.item,
                                                                                    "canManageDocumentPackage",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "data.item.canManageDocumentPackage",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                              _vm._s(
                                                                                _vm.FormMSG(
                                                                                  2440,
                                                                                  "Manage document package"
                                                                                )
                                                                              ) +
                                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                              _vm.isForProject &&
                                                              _vm.useCallSheet
                                                                ? _c(
                                                                    "b-dropdown-item",
                                                                    {
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.handleAccessLevel(
                                                                              "canManageCallSheets",
                                                                              !data
                                                                                .item
                                                                                .canManageCallSheets,
                                                                              data.item,
                                                                              _vm.screenAccess
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "b-form-checkbox",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              size: "sm",
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                data
                                                                                  .item
                                                                                  .canManageCallSheets,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    data.item,
                                                                                    "canManageCallSheets",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "data.item.canManageCallSheets",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                              _vm._s(
                                                                                _vm.FormMSG(
                                                                                  2450,
                                                                                  "Manage call sheets"
                                                                                )
                                                                              ) +
                                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                              _vm.isForProject &&
                                                              _vm.useAcc
                                                                ? _c(
                                                                    "b-dropdown-item",
                                                                    {
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.handleAccessLevel(
                                                                              "canManageAccommodations",
                                                                              !data
                                                                                .item
                                                                                .canManageAccommodations,
                                                                              data.item,
                                                                              _vm.screenAccess
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "b-form-checkbox",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              size: "sm",
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                data
                                                                                  .item
                                                                                  .canManageAccommodations,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    data.item,
                                                                                    "canManageAccommodations",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "data.item.canManageAccommodations",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                              _vm._s(
                                                                                _vm.FormMSG(
                                                                                  2973,
                                                                                  "Manage accomodations"
                                                                                )
                                                                              ) +
                                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                              _vm.isForProject &&
                                                              _vm.useLoc
                                                                ? _c(
                                                                    "b-dropdown-item",
                                                                    {
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.handleAccessLevel(
                                                                              "canManageLocations",
                                                                              !data
                                                                                .item
                                                                                .canManageLocations,
                                                                              data.item,
                                                                              _vm.screenAccess
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "b-form-checkbox",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              size: "sm",
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                data
                                                                                  .item
                                                                                  .canManageLocations,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    data.item,
                                                                                    "canManageLocations",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "data.item.canManageLocations",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                              _vm._s(
                                                                                _vm.FormMSG(
                                                                                  2449,
                                                                                  "Manage locations"
                                                                                )
                                                                              ) +
                                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                              _vm.isForProject &&
                                                              _vm.useTrans
                                                                ? _c(
                                                                    "b-dropdown-item",
                                                                    {
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.handleAccessLevel(
                                                                              "canManageTransport",
                                                                              !data
                                                                                .item
                                                                                .canManageTransport,
                                                                              data.item,
                                                                              _vm.screenAccess
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "b-form-checkbox",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              size: "sm",
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                data
                                                                                  .item
                                                                                  .canManageTransport,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    data.item,
                                                                                    "canManageTransport",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "data.item.canManageTransport",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                              _vm._s(
                                                                                _vm.FormMSG(
                                                                                  24049,
                                                                                  "Manage transport"
                                                                                )
                                                                              ) +
                                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                              _vm.isForProject &&
                                                              _vm.useImportExport
                                                                ? _c(
                                                                    "b-dropdown-item",
                                                                    {
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.handleAccessLevel(
                                                                              "canExportImport",
                                                                              !data
                                                                                .item
                                                                                .canExportImport,
                                                                              data.item,
                                                                              _vm.screenAccess
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "b-form-checkbox",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              size: "sm",
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                data
                                                                                  .item
                                                                                  .canExportImport,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    data.item,
                                                                                    "canExportImport",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "data.item.canExportImport",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                              _vm._s(
                                                                                _vm.FormMSG(
                                                                                  2455,
                                                                                  "Can import/export"
                                                                                )
                                                                              ) +
                                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                              _vm.isForProject &&
                                                              _vm.useBudget
                                                                ? _c(
                                                                    "b-dropdown-item",
                                                                    {
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.handleAccessLevel(
                                                                              "canViewGlobalBudget",
                                                                              !data
                                                                                .item
                                                                                .canViewGlobalBudget,
                                                                              data.item,
                                                                              _vm.screenAccess
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "b-form-checkbox",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              size: "sm",
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                data
                                                                                  .item
                                                                                  .canViewGlobalBudget,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    data.item,
                                                                                    "canViewGlobalBudget",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "data.item.canViewGlobalBudget",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                              _vm._s(
                                                                                _vm.FormMSG(
                                                                                  24430,
                                                                                  "View global budget"
                                                                                )
                                                                              ) +
                                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                              _vm.isForProject &&
                                                              _vm.useGreenTable
                                                                ? _c(
                                                                    "b-dropdown-item",
                                                                    {
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.handleAccessLevel(
                                                                              "canViewGreenTable",
                                                                              !data
                                                                                .item
                                                                                .canViewGreenTable,
                                                                              data.item,
                                                                              _vm.screenAccess
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "b-form-checkbox",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              size: "sm",
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                data
                                                                                  .item
                                                                                  .canViewGreenTable,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    data.item,
                                                                                    "canViewGreenTable",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "data.item.canViewGreenTable",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                              _vm._s(
                                                                                _vm.FormMSG(
                                                                                  244030,
                                                                                  "View green table"
                                                                                )
                                                                              ) +
                                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                              _vm.isForProject &&
                                                              _vm.useReporting
                                                                ? _c(
                                                                    "b-dropdown-item",
                                                                    {
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.handleAccessLevel(
                                                                              "canAccessGreenReporting",
                                                                              !data
                                                                                .item
                                                                                .canAccessGreenReporting,
                                                                              data.item,
                                                                              _vm.screenAccess
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "b-form-checkbox",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              size: "sm",
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                data
                                                                                  .item
                                                                                  .canAccessGreenReporting,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    data.item,
                                                                                    "canAccessGreenReporting",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "data.item.canAccessGreenReporting",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                              _vm._s(
                                                                                _vm.FormMSG(
                                                                                  24560,
                                                                                  "View Green reporting screens"
                                                                                )
                                                                              ) +
                                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                              _vm.isForProject &&
                                                              _vm.useCarbonRemoval
                                                                ? _c(
                                                                    "b-dropdown-item",
                                                                    {
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.handleAccessLevel(
                                                                              "canManageCarbonRemoval",
                                                                              !data
                                                                                .item
                                                                                .canManageCarbonRemoval,
                                                                              data.item,
                                                                              _vm.screenAccess
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "b-form-checkbox",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              size: "sm",
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                data
                                                                                  .item
                                                                                  .canManageCarbonRemoval,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    data.item,
                                                                                    "canManageCarbonRemoval",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "data.item.canManageCarbonRemoval",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                              _vm._s(
                                                                                _vm.FormMSG(
                                                                                  24480,
                                                                                  "Manage carbon removal"
                                                                                )
                                                                              ) +
                                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                              _vm.isForProject &&
                                                              _vm.useWaste
                                                                ? _c(
                                                                    "b-dropdown-item",
                                                                    {
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.handleAccessLevel(
                                                                              "canViewWasteScreens",
                                                                              !data
                                                                                .item
                                                                                .canViewWasteScreens,
                                                                              data.item,
                                                                              _vm.screenAccess
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "b-form-checkbox",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              size: "sm",
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                data
                                                                                  .item
                                                                                  .canViewWasteScreens,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    data.item,
                                                                                    "canViewWasteScreens",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "data.item.canViewWasteScreens",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                              _vm._s(
                                                                                _vm.FormMSG(
                                                                                  24481,
                                                                                  "View waste screens"
                                                                                )
                                                                              ) +
                                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                              _vm.isForProject &&
                                                              _vm.useTipOfDay
                                                                ? _c(
                                                                    "b-dropdown-item",
                                                                    {
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.handleAccessLevel(
                                                                              "canManageTipOfTheDay",
                                                                              !data
                                                                                .item
                                                                                .canManageTipOfTheDay,
                                                                              data.item,
                                                                              _vm.screenAccess
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "b-form-checkbox",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              size: "sm",
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                data
                                                                                  .item
                                                                                  .canManageTipOfTheDay,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    data.item,
                                                                                    "canManageTipOfTheDay",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "data.item.canManageTipOfTheDay",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                              _vm._s(
                                                                                _vm.FormMSG(
                                                                                  24405,
                                                                                  "Manage tip of the day"
                                                                                )
                                                                              ) +
                                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                              _vm.isForProject
                                                                ? _c(
                                                                    "b-dropdown-item",
                                                                    {
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.handleAccessLevel(
                                                                              "canAccessConfig",
                                                                              !data
                                                                                .item
                                                                                .canAccessConfig,
                                                                              data.item,
                                                                              _vm.screenAccess
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "b-form-checkbox",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              size: "sm",
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                data
                                                                                  .item
                                                                                  .canAccessConfig,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    data.item,
                                                                                    "canAccessConfig",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "data.item.canAccessConfig",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                              _vm._s(
                                                                                _vm.FormMSG(
                                                                                  246,
                                                                                  "Can access config"
                                                                                )
                                                                              ) +
                                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  },
                                                },
                                                {
                                                  key: "cell(role)",
                                                  fn: function (data) {
                                                    return [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex justify-content-center",
                                                          staticStyle: {
                                                            "min-width": "60px",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "b-dropdown",
                                                            {
                                                              class:
                                                                _vm.lengthUsers <=
                                                                8
                                                                  ? "position-absolute"
                                                                  : "",
                                                              attrs: {
                                                                id: "dropdown-offer-action",
                                                                size: "sm",
                                                                text: _vm.FormMSG(
                                                                  2490,
                                                                  "Roles"
                                                                ),
                                                                variant:
                                                                  "warning",
                                                                dropleft: "",
                                                                boundary:
                                                                  "window",
                                                              },
                                                            },
                                                            [
                                                              _vm.isForProject
                                                                ? _c(
                                                                    "b-dropdown-item",
                                                                    {
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.handleAccessLevel(
                                                                              _vm.gateKeeper,
                                                                              !data
                                                                                .item
                                                                                .gateKeeper,
                                                                              data.item,
                                                                              _vm.isRole
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "b-form-checkbox",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              size: "sm",
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                data
                                                                                  .item
                                                                                  .gateKeeper,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    data.item,
                                                                                    "gateKeeper",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "data.item.gateKeeper",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                              _vm._s(
                                                                                _vm.FormMSG(
                                                                                  266,
                                                                                  "Gate Keeper"
                                                                                )
                                                                              ) +
                                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                              _vm.isForProject
                                                                ? _c(
                                                                    "b-dropdown-item",
                                                                    {
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.handleAccessLevel(
                                                                              _vm.productionAccountant,
                                                                              !data
                                                                                .item
                                                                                .productionAccountant,
                                                                              data.item,
                                                                              _vm.isRole
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "b-form-checkbox",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              size: "sm",
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                data
                                                                                  .item
                                                                                  .productionAccountant,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    data.item,
                                                                                    "productionAccountant",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "data.item.productionAccountant",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                              _vm._s(
                                                                                _vm.FormMSG(
                                                                                  261,
                                                                                  "Production Account"
                                                                                )
                                                                              ) +
                                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                              _vm.isForProject
                                                                ? _c(
                                                                    "b-dropdown-item",
                                                                    {
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.handleAccessLevel(
                                                                              _vm.upm,
                                                                              !data
                                                                                .item
                                                                                .upm,
                                                                              data.item,
                                                                              _vm.isRole
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "b-form-checkbox",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              size: "sm",
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                data
                                                                                  .item
                                                                                  .upm,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    data.item,
                                                                                    "upm",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "data.item.upm",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                              _vm._s(
                                                                                _vm.FormMSG(
                                                                                  262,
                                                                                  "UPM"
                                                                                )
                                                                              ) +
                                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                              _vm.isForProject
                                                                ? _c(
                                                                    "b-dropdown-item",
                                                                    {
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.handleAccessLevel(
                                                                              _vm.lineProducer,
                                                                              !data
                                                                                .item
                                                                                .lineProducer,
                                                                              data.item,
                                                                              _vm.isRole
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "b-form-checkbox",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              size: "sm",
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                data
                                                                                  .item
                                                                                  .lineProducer,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    data.item,
                                                                                    "lineProducer",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "data.item.lineProducer",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                              _vm._s(
                                                                                _vm.FormMSG(
                                                                                  263,
                                                                                  "Line Producer"
                                                                                )
                                                                              ) +
                                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                              _vm.isForProject
                                                                ? _c(
                                                                    "b-dropdown-item",
                                                                    {
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.handleAccessLevel(
                                                                              _vm.studio,
                                                                              !data
                                                                                .item
                                                                                .studio,
                                                                              data.item,
                                                                              _vm.isRole
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "b-form-checkbox",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              size: "sm",
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                data
                                                                                  .item
                                                                                  .studio,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    data.item,
                                                                                    "studio",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "data.item.studio",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                              _vm._s(
                                                                                _vm.FormMSG(
                                                                                  265,
                                                                                  "Studio"
                                                                                )
                                                                              ) +
                                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                              _vm.isForProject
                                                                ? _c(
                                                                    "b-dropdown-item",
                                                                    {
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.handleAccessLevel(
                                                                              _vm.canValidateI9,
                                                                              !data
                                                                                .item
                                                                                .canValidateI9,
                                                                              data.item,
                                                                              _vm.screenAccess
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "b-form-checkbox",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              size: "sm",
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                data
                                                                                  .item
                                                                                  .canValidateI9,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    data.item,
                                                                                    "canValidateI9",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "data.item.canValidateI9",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.FormMSG(
                                                                                68,
                                                                                "Can validate I-9"
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          },
                                          [
                                            _c(
                                              "template",
                                              { slot: "HEAD_isProd" },
                                              [_vm._v(" Prod Rights ")]
                                            ),
                                            _c(
                                              "template",
                                              { slot: "HEAD_isAdmin" },
                                              [_vm._v(" Admin Rights ")]
                                            ),
                                          ],
                                          2
                                        )
                                      : _vm._e(),
                                    _c(
                                      "b-row",
                                      [
                                        _c("b-col", { attrs: { cols: "12" } }, [
                                          _c(
                                            "div",
                                            { staticClass: "nb-text" },
                                            [
                                              _vm._v(
                                                "\n\t\t\t\t\t\t\t\t\t\t" +
                                                  _vm._s(
                                                    _vm.FormMSG(300, "Totals:")
                                                  ) +
                                                  " " +
                                                  _vm._s(
                                                    _vm.globalUsers.length
                                                  ) +
                                                  " " +
                                                  _vm._s(
                                                    _vm.FormMSG(301, "of")
                                                  ) +
                                                  " " +
                                                  _vm._s(_vm.totalOfUsers) +
                                                  "\n\t\t\t\t\t\t\t\t\t\t" +
                                                  _vm._s(
                                                    _vm.FormMSG(302, "records")
                                                  ) +
                                                  "\n\t\t\t\t\t\t\t\t\t"
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _vm.useDpae
                                      ? _c(
                                          "b-row",
                                          { staticClass: "mt-3" },
                                          [
                                            _c(
                                              "b-col",
                                              { attrs: { cols: "12" } },
                                              [
                                                _c(
                                                  "b-button",
                                                  {
                                                    attrs: {
                                                      disabled:
                                                        _vm.selectedItem
                                                          .length === 0,
                                                      variant: "primary",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        _vm.sendDpae(
                                                          _vm.filterContractIdInUserArray(
                                                            _vm.selectedItem
                                                          )
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t\t" +
                                                        _vm._s(
                                                          _vm.FormMSG(
                                                            267,
                                                            "Send to DPAE"
                                                          )
                                                        ) +
                                                        " " +
                                                        _vm._s(
                                                          _vm.selectedItem
                                                            .length > 0
                                                            ? "(" +
                                                                _vm.selectedItem
                                                                  .length +
                                                                ")"
                                                            : ""
                                                        ) +
                                                        "\n\t\t\t\t\t\t\t\t\t"
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c(
                                      "b-modal",
                                      {
                                        attrs: {
                                          "header-class":
                                            "header-class-modal-doc-package",
                                          "cancel-variant": "light",
                                          title: `${
                                            _vm.selectedUserId > 0
                                              ? _vm.FormMSG(100, "Edit User") +
                                                " : " +
                                                _vm.selectedUserData.firstName +
                                                " " +
                                                _vm.selectedUserData.name +
                                                ""
                                              : _vm.FormMSG(111, "New User")
                                          }`,
                                          size: "xxl",
                                          "footer-class": "w-100",
                                          centered: "",
                                          "no-close-on-backdrop": "",
                                        },
                                        on: {
                                          close: _vm.refreshData,
                                          cancel: _vm.refreshData,
                                          ok: _vm.dispatchEvent,
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            _vm.$screen.width > 576
                                              ? {
                                                  key: "modal-footer",
                                                  fn: function ({
                                                    ok,
                                                    cancel,
                                                  }) {
                                                    return [
                                                      _c(
                                                        "b-container",
                                                        {
                                                          attrs: { fluid: "" },
                                                        },
                                                        [
                                                          _c(
                                                            "b-row",
                                                            {
                                                              attrs: {
                                                                "align-h":
                                                                  "between",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "b-col",
                                                                {
                                                                  attrs: {
                                                                    cols: "12",
                                                                    sm: "auto",
                                                                  },
                                                                },
                                                                [
                                                                  _vm.useDpae
                                                                    ? _c(
                                                                        "b-button",
                                                                        {
                                                                          staticClass:
                                                                            "px-4 btn-custom-outline-gray",
                                                                          attrs:
                                                                            {
                                                                              variant:
                                                                                "light",
                                                                              disabled:
                                                                                _vm.disableSendDpaeModal,
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.sendDpae(
                                                                                  _vm.dpaeToSendModal
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                              _vm._s(
                                                                                _vm.FormMSG(
                                                                                  267,
                                                                                  "Send to DPAE"
                                                                                )
                                                                              )
                                                                          ),
                                                                          _vm.dpaeCounterModal >
                                                                          0
                                                                            ? _c(
                                                                                "span",
                                                                                [
                                                                                  _vm._v(
                                                                                    "(" +
                                                                                      _vm._s(
                                                                                        _vm.dpaeCounterModal
                                                                                      ) +
                                                                                      ")"
                                                                                  ),
                                                                                ]
                                                                              )
                                                                            : _vm._e(),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "b-col",
                                                                {
                                                                  staticClass:
                                                                    "d-flex",
                                                                  staticStyle: {
                                                                    gap: "10px",
                                                                  },
                                                                  attrs: {
                                                                    cols: "12",
                                                                    sm: "auto",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "b-button",
                                                                    {
                                                                      staticClass:
                                                                        "px-4",
                                                                      attrs: {
                                                                        variant:
                                                                          "light",
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return cancel()
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                          _vm._s(
                                                                            _vm.FormMSG(
                                                                              354,
                                                                              "Cancel"
                                                                            )
                                                                          ) +
                                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "b-button",
                                                                    {
                                                                      staticClass:
                                                                        "px-4",
                                                                      attrs: {
                                                                        variant:
                                                                          "secondary",
                                                                        disabled:
                                                                          _vm.setSaveButtonDisable,
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return ok()
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "b-spinner",
                                                                        {
                                                                          directives:
                                                                            [
                                                                              {
                                                                                name: "show",
                                                                                rawName:
                                                                                  "v-show",
                                                                                value:
                                                                                  _vm.setSaveButtonDisable &&
                                                                                  _vm.executeSaveAction,
                                                                                expression:
                                                                                  "setSaveButtonDisable && executeSaveAction",
                                                                              },
                                                                            ],
                                                                          staticClass:
                                                                            "mr-3",
                                                                          attrs:
                                                                            {
                                                                              small:
                                                                                "",
                                                                            },
                                                                        }
                                                                      ),
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.FormMSG(
                                                                            359,
                                                                            "Save"
                                                                          )
                                                                        ) +
                                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  },
                                                }
                                              : null,
                                          ],
                                          null,
                                          true
                                        ),
                                        model: {
                                          value: _vm.showUserModal,
                                          callback: function ($$v) {
                                            _vm.showUserModal = $$v
                                          },
                                          expression: "showUserModal",
                                        },
                                      },
                                      [
                                        _vm.$screen.width > 576
                                          ? _c(
                                              "b-container",
                                              { attrs: { fluid: "" } },
                                              [
                                                _c(
                                                  "b-row",
                                                  {
                                                    attrs: { "align-h": "end" },
                                                  },
                                                  [
                                                    _c(
                                                      "b-col",
                                                      {
                                                        staticStyle: {
                                                          display: "none",
                                                        },
                                                        attrs: { cols: "2" },
                                                      },
                                                      [
                                                        _vm.selectedUserId
                                                          ? _c("v-select", {
                                                              staticClass:
                                                                "w-75 border-radius-status",
                                                              attrs: {
                                                                id: "user-status-selector",
                                                                label: "text",
                                                                options:
                                                                  _vm.getStatusMenu,
                                                                reduce: (
                                                                  option
                                                                ) =>
                                                                  option.value,
                                                              },
                                                              on: {
                                                                input:
                                                                  _vm.setUserStatus,
                                                              },
                                                              scopedSlots:
                                                                _vm._u(
                                                                  [
                                                                    {
                                                                      key: "option",
                                                                      fn: function (
                                                                        option
                                                                      ) {
                                                                        return [
                                                                          _c(
                                                                            _vm.getLucideIcon(
                                                                              "Circle"
                                                                            ),
                                                                            {
                                                                              tag: "component",
                                                                              staticClass:
                                                                                "mr-3",
                                                                              class:
                                                                                option.colorClass,
                                                                              staticStyle:
                                                                                {
                                                                                  "border-radius":
                                                                                    "100%",
                                                                                },
                                                                              attrs:
                                                                                {
                                                                                  size: 12,
                                                                                  "stroke-width": 1.5,
                                                                                },
                                                                            }
                                                                          ),
                                                                          _vm._v(
                                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                              _vm._s(
                                                                                option.text
                                                                              ) +
                                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                          ),
                                                                        ]
                                                                      },
                                                                    },
                                                                    {
                                                                      key: "selected-option",
                                                                      fn: function ({
                                                                        text,
                                                                        colorClass,
                                                                      }) {
                                                                        return [
                                                                          _c(
                                                                            _vm.getLucideIcon(
                                                                              "Circle"
                                                                            ),
                                                                            {
                                                                              tag: "component",
                                                                              staticClass:
                                                                                "mr-3",
                                                                              class:
                                                                                colorClass,
                                                                              staticStyle:
                                                                                {
                                                                                  "border-radius":
                                                                                    "100%",
                                                                                },
                                                                              attrs:
                                                                                {
                                                                                  size: 12,
                                                                                  "stroke-width": 1.5,
                                                                                },
                                                                            }
                                                                          ),
                                                                          _vm._v(
                                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                              _vm._s(
                                                                                text
                                                                              ) +
                                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                          ),
                                                                        ]
                                                                      },
                                                                    },
                                                                  ],
                                                                  null,
                                                                  false,
                                                                  3060815946
                                                                ),
                                                              model: {
                                                                value:
                                                                  _vm.currentUserStatus,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.currentUserStatus =
                                                                      $$v
                                                                  },
                                                                expression:
                                                                  "currentUserStatus",
                                                              },
                                                            })
                                                          : _c(
                                                              "span",
                                                              [
                                                                _c(
                                                                  _vm.getLucideIcon(
                                                                    "Circle"
                                                                  ),
                                                                  {
                                                                    tag: "component",
                                                                    staticClass:
                                                                      "mr-3",
                                                                    class:
                                                                      _vm
                                                                        .getStatusMenu[0]
                                                                        .colorClass,
                                                                    staticStyle:
                                                                      {
                                                                        "border-radius":
                                                                          "100%",
                                                                      },
                                                                    attrs: {
                                                                      size: 14,
                                                                      "stroke-width": 1.5,
                                                                    },
                                                                  }
                                                                ),
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm
                                                                      .getStatusMenu[0]
                                                                      .text
                                                                  )
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "b-col",
                                                      {
                                                        staticClass:
                                                          "d-flex px-0",
                                                        attrs: { cols: "auto" },
                                                      },
                                                      [
                                                        _c(
                                                          "b-form-group",
                                                          {
                                                            attrs: {
                                                              label:
                                                                _vm.FormMSG(
                                                                  333,
                                                                  "User ID"
                                                                ),
                                                              "label-for":
                                                                "userId",
                                                              "label-cols":
                                                                "auto",
                                                              "content-cols":
                                                                "auto",
                                                              horizontal: true,
                                                            },
                                                          },
                                                          [
                                                            _c("b-form-input", {
                                                              attrs: {
                                                                id: "userId",
                                                                value: _vm
                                                                  .selectedUserData
                                                                  .id
                                                                  ? _vm
                                                                      .selectedUserData
                                                                      .id
                                                                  : "",
                                                                type: "text",
                                                                disabled: "",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "b-dropdown",
                                                          {
                                                            staticClass:
                                                              "btn-transparent d-flex justify-content-end align-items-start",
                                                            attrs: {
                                                              "no-caret": "",
                                                              boundary:
                                                                "window",
                                                              variant: "none",
                                                              size: "sm",
                                                              dropleft: "",
                                                              offset: "35",
                                                              lazy: "",
                                                              disabled: true,
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "button-content",
                                                                  fn: function () {
                                                                    return [
                                                                      _c(
                                                                        _vm.getLucideIcon(
                                                                          "MoreVertical"
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              size: 25,
                                                                              "stroke-width": 1.5,
                                                                            },
                                                                        }
                                                                      ),
                                                                    ]
                                                                  },
                                                                  proxy: true,
                                                                },
                                                              ],
                                                              null,
                                                              false,
                                                              2480355669
                                                            ),
                                                          },
                                                          [
                                                            _c(
                                                              "b-dropdown-item",
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "text-color-rhapsody-in-blue d-flex align-items-center",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      _vm.getLucideIcon(
                                                                        "Mail"
                                                                      ),
                                                                      {
                                                                        tag: "component",
                                                                        attrs: {
                                                                          size: 16,
                                                                        },
                                                                      }
                                                                    ),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "ml-2",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.FormMSG(
                                                                              125,
                                                                              "Email user"
                                                                            )
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "b-dropdown-item",
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "text-color-rhapsody-in-blue d-flex align-items-center",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      _vm.getLucideIcon(
                                                                        "Download"
                                                                      ),
                                                                      {
                                                                        tag: "component",
                                                                        attrs: {
                                                                          size: 16,
                                                                        },
                                                                      }
                                                                    ),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "ml-2",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.FormMSG(
                                                                              2003,
                                                                              "Download infosheet"
                                                                            )
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "b-dropdown-item",
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "text-color-rhapsody-in-blue d-flex align-items-center",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      _vm.getLucideIcon(
                                                                        "Trash"
                                                                      ),
                                                                      {
                                                                        tag: "component",
                                                                        staticClass:
                                                                          "text-danger",
                                                                        attrs: {
                                                                          size: 16,
                                                                        },
                                                                      }
                                                                    ),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "ml-2",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.FormMSG(
                                                                              63,
                                                                              "Delete user"
                                                                            )
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.selectedUserId
                                          ? _c("user-dialog", {
                                              attrs: {
                                                projectId: _vm.projectId,
                                                selectedUserData:
                                                  _vm.selectedUserData,
                                                userId: _vm.selectedUserId,
                                                "is-saved":
                                                  _vm.executeSaveAction,
                                                "display-add-btn": false,
                                                "reload-contracts":
                                                  _vm.reloadContractsModal,
                                                handleAccessLevel: (
                                                  item,
                                                  value,
                                                  data,
                                                  screenAccess
                                                ) =>
                                                  _vm.handleAccessLevel(
                                                    item,
                                                    value,
                                                    data,
                                                    screenAccess
                                                  ),
                                              },
                                              on: {
                                                closeForm: _vm.toggleUserModal,
                                                "user-dialog:send-single-dpae":
                                                  _vm.sendDpae,
                                                "user-dialog:dpae-counter-change":
                                                  _vm.prepareDpaeToSendModal,
                                                "user-dialog:contracts-reloaded":
                                                  function ($event) {
                                                    _vm.reloadContractsModal = false
                                                  },
                                                "user-dialog:user-data-change":
                                                  function ($event) {
                                                    _vm.setSaveButtonDisable = false
                                                  },
                                                "user-dialog:user-saved":
                                                  function ($event) {
                                                    _vm.executeSaveAction = false
                                                  },
                                              },
                                            })
                                          : _c("user-form", {
                                              attrs: {
                                                projectId: _vm.projectId,
                                              },
                                              on: {
                                                closeForm: _vm.toggleUserModal,
                                                "user-form:success":
                                                  _vm.refreshData,
                                              },
                                            }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-modal",
                                      {
                                        staticClass: "modal-success",
                                        attrs: {
                                          "header-class":
                                            "header-class-modal-doc-package",
                                          title: _vm.FormMSG(
                                            62,
                                            "Delete User?"
                                          ),
                                          "ok-variant": "primary",
                                          "ok-title": this.FormMSG(
                                            63,
                                            "Delete user"
                                          ),
                                        },
                                        on: {
                                          ok: function ($event) {
                                            return _vm.confirmedDeleteUser()
                                          },
                                        },
                                        model: {
                                          value: _vm.successDeleteUserModal,
                                          callback: function ($$v) {
                                            _vm.successDeleteUserModal = $$v
                                          },
                                          expression: "successDeleteUserModal",
                                        },
                                      },
                                      [
                                        _c("p", [
                                          _vm._v(
                                            _vm._s(this.emailOfUserToDelete)
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _c(
                                      "b-modal",
                                      {
                                        staticClass: "modal-success",
                                        attrs: {
                                          "header-class":
                                            "header-class-modal-doc-package",
                                          title: _vm.FormMSG(20, "Picture"),
                                          "ok-variant": "success",
                                          "ok-only": "",
                                        },
                                        on: {
                                          ok: function ($event) {
                                            _vm.showContactPicture = false
                                          },
                                        },
                                        model: {
                                          value: _vm.showContactPicture,
                                          callback: function ($$v) {
                                            _vm.showContactPicture = $$v
                                          },
                                          expression: "showContactPicture",
                                        },
                                      },
                                      _vm._l(
                                        this.contactImagePath,
                                        function (oneImage, i) {
                                          return _c(
                                            "b-card",
                                            { key: i },
                                            [
                                              _c(
                                                "b-container",
                                                {
                                                  staticClass: "p-4 bg-dark",
                                                  attrs: { fluid: "" },
                                                },
                                                [
                                                  _c(
                                                    "b-row",
                                                    [
                                                      _c(
                                                        "b-col",
                                                        [
                                                          _c("b-img", {
                                                            attrs: {
                                                              fluid: "",
                                                              src: oneImage,
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        }
                                      ),
                                      1
                                    ),
                                    _c("script-export-modal", {
                                      attrs: {
                                        items: _vm.scripts,
                                        custom: _vm.customReport,
                                        "export-type": "user",
                                      },
                                      on: {
                                        "script-export-modal:loading":
                                          _vm.handleLoading,
                                        "script-export-modal:closed":
                                          _vm.userRerpotOnClosed,
                                      },
                                      model: {
                                        value: _vm.isScriptInfosheetOpen,
                                        callback: function ($$v) {
                                          _vm.isScriptInfosheetOpen = $$v
                                        },
                                        expression: "isScriptInfosheetOpen",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.canSeeUserSetting &&
                        !_vm.isForProjects &&
                        !_vm.isForGreenshotUser
                          ? _c(
                              "b-tab",
                              {
                                attrs: {
                                  lazy: "",
                                  title: _vm.FormMSG(1230, "Onboarding"),
                                },
                              },
                              [_c("Views")],
                              1
                            )
                          : _vm._e(),
                        _vm.canSeeDocPackage &&
                        !_vm.isForProjects &&
                        !_vm.isForGreenshotUser &&
                        _vm.useDoc
                          ? _c(
                              "b-tab",
                              {
                                attrs: {
                                  lazy: "",
                                  title: _vm.FormMSG(1200, "Document Package"),
                                },
                              },
                              [_c("DocumentPackageIndex")],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      _c(
        "b-modal",
        {
          attrs: {
            size: "md",
            "header-class": "header-class-modal-doc-package",
            "header-bg-variant": "light",
            title: _vm.FormMSG(51, "Error"),
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            "hide-header-close": "",
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "w-100" },
                    [
                      _c(
                        "b-row",
                        { staticClass: "padding-c-1" },
                        [
                          _c("b-col", { attrs: { cols: "12" } }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex flex-row justify-content-end align-items-center w-100",
                                staticStyle: { gap: "20px" },
                              },
                              [
                                _c(
                                  "b-button",
                                  {
                                    staticClass:
                                      "w-100-px d-flex justify-content-center align-items-center",
                                    attrs: { size: "sm", variant: "light" },
                                    on: {
                                      click: function ($event) {
                                        _vm.showDpaeMessage = false
                                      },
                                    },
                                  },
                                  [_c("span", [_vm._v("OK")])]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.showDpaeMessage,
            callback: function ($$v) {
              _vm.showDpaeMessage = $$v
            },
            expression: "showDpaeMessage",
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "d-flex align-items-center w-100",
              staticStyle: { gap: "10px" },
            },
            [
              _c(_vm.getLucideIcon("XCircle"), {
                tag: "component",
                staticClass: "text-danger",
              }),
              _c("span", [_vm._v(_vm._s(_vm.dpaeMsg))]),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }