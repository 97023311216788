<style>
.hidden_header {
	display: none;
}

.missing-info-text {
	color: rgba(117, 102, 31, 0.74) !important;
	font-style: italic;
}
</style>

<template>
	<errorContainer :error="erreur" :warning="warning">
		<b-row>
			<b-col cols="12" md="12">
				<b-card no-body class="card-border-blue-light">
					<div v-if="User" :class="fillClassContainer" class="px-0">
						<div class="container-header-card-collapsible cursor-pointer w-100" @click="handleClickHeader('configAccordion')">
							<div class="title">
								<div class="w-90">
									<h4>{{ FormMSG(1, 'User Information') }}</h4>
								</div>
								<div class="icon w-10">
									<component v-if="!configAccordion.isVisible" :is="getIconLucide('ChevronDown')" color="#06263E" :size="22" />
									<component v-if="configAccordion.isVisible" :is="getIconLucide('ChevronUp')" color="#06263E" :size="22" />
								</div>
							</div>
						</div>
						<b-collapse :visible="configAccordion.isVisible" :class="$screen.width <= 576 ? 'mt-3' : ''">
							<div class="content-card-collapsible">
								<profile
									:selectedUserData="selectedUserData"
									:data="User"
									@change="handleUserEntryLevelChange"
									@memo-profile:user-custom-field-value="onUserCustomFieldValueChanged"
									:disabled="true"
									:handleAccessLevel="(item, value, data, screen) => this.$props.handleAccessLevel(item, value, data, screen)"
									@set-user-item="handleSetUser"
									@put-user="handleUpdateUser"
								/>
								<!--<b-row class="d-flex justify-content-center pt-4">
									<b-col sm="12" md="4" lg="4" xl="4">
										<b-button block @click="updateUser()" variant="secondary" class="w-100"> {{ FormMSG(50, 'Save user') }}</b-button>
									</b-col>
								</b-row>-->
								<template slot="footer" v-if="isDev">
									<b-button v-on:click="becomeUser(userId)">Impersonate</b-button>
								</template>
							</div>
						</b-collapse>
					</div>
				</b-card>
				<!-- {{ contracts }} :disableSave="warning.length > 0"-->
			</b-col>
		</b-row>
		<b-row>
			<b-col cols="12" md="12" v-if="this.isForProject()">
				<div v-if="$screen.width <= 576">
					<contract-mobile-view
						v-for="item in contracts"
						:disableDelete="contracts.length === 1"
						:key="item.id"
						:contractInput="item"
						:accordion="accordion"
						:hide-send-package-btn="true"
						:fill-class-container="'container-layout'"
						:user-id="userId"
						:show-pay-code="showPayCode"
						@isLoading="isLoading = $event"
						@successModal="successModal = $event"
						@erreur="erreur = $event"
						@contract:show-body="handleContractShowBody"
					/>
					<b-row class="d-flex justify-content-center" :class="`${displayAddBtn ? 'pb-2' : ''}`">
						<b-col sm="12" md="4" lg="4" xl="4" :style="`display: ${displayAddBtn ? 'block' : 'none'} !important;`">
							<b-button
								ref="new-contract"
								size="sm"
								variant="outline-primary"
								class="w-200-px d-flex justify-content-center align-items-center"
								block
								@click="addContract"
							>
								<component :is="getLucideIcon('PlusCircle')" :size="18" :stroke-width="2" />
								<div class="px-2" style="margin-bottom: -2px">
									{{ FormMSG(51, 'New contract') }}
								</div>
							</b-button>
						</b-col>
					</b-row>
				</div>
				<div v-else class="animated fadeIn">
					<div class="card card-border-blue-light">
						<div class="container-header-card-collapsible cursor-pointer" @click="handleClickHeader('accordion')">
							<div class="title">
								<div class="w-90">
									<h4>
										{{ FormMSG(42, 'Contracts') }}
									</h4>
								</div>
								<div v-if="!isFilmSingle" class="icon w-10">
									<component v-if="!accordion.isVisible" :is="getIconLucide('ChevronDown')" color="#06263E" :size="22" />
									<component v-if="accordion.isVisible" :is="getIconLucide('ChevronUp')" color="#06263E" :size="22" />
								</div>
							</div>
						</div>
						<b-collapse :visible="accordion.isVisible || isFilmSingle">
							<div class="p-3 mt-3">
								<b-table
									:fields="contractsFields"
									:items="contracts"
									:hover="true"
									:bordered="false"
									table-variant="light"
									:sticky-header="'300px'"
									style="text-align: left"
									:empty-text="FormMSG(33, 'No Contract assigned yet')"
									@row-clicked="contractRowClicked"
									selectable
									show-empty
									small
								>
									<template #table-busy>
										<div class="d-flex justify-content-center align-items-center">
											<b-spinner class="align-middle" small></b-spinner>
											<strong class="ml-2">{{ FormMSG(353, 'Loading') }}...</strong>
										</div>
									</template>
									<template #cell(actionSelected)="data">
										<b-form-checkbox
											v-model="data.item.checkStatus"
											v-if="isSendableStatusDpae(data.item.dpaeStatus) && !checkIfThereIsInvalidFields(User)"
											@change="setDpaeCounter"
											size="lg"
										/>
									</template>
									<template v-if="useDpae" #cell(dpaeStatus)="{ item }">
										<DpaeTooltip :contractDpaeStatus="item.dpaeStatus" :data="item" :user="User" :target="item.id" />
										<div :id="'tooltip-target-' + item.id">
											<div>
												<component
													:is="getLucideIcon(getDpaeStatusIcon(item.dpaeStatus))"
													:size="20"
													:stroke-width="2"
													:class="getDpaeStatusClass(item.dpaeStatus)"
													class="mr-2"
												/>
												<span :class="item.dpaeStatus === 1 ? getDpaeStatusClass(item.dpaeStatus) : ''">{{
													getDpaeStatusText(item.dpaeStatus)
												}}</span>
											</div>
											<div class="ml-4 missing-info-text">
												<span v-if="item.dpaeStatus === DPAE_STATUS.NOT_SENT && checkIfThereIsInvalidFields(User)" class="text-center">
													{{ FormMSG(999, 'Missing Info') }}
												</span>
											</div>
										</div>
									</template>
									<!--<template #cell(contractStatus)="{ item }">
										<b-button variant="link" size="lg" class="p-0" disabled>
											<b-badge pill variant="secondary" class="text-dark" :class="getStatusMenu[0].colorClass" disabled>{{
												item.contractStatus ? getStatusMenu[item.contractStatus].text : 'disabled'
											}}</b-badge>
										</b-button>
									</template>-->
									<template #cell(actionDelete)="{ item }">
										<b-button variant="link">
											<component
												:is="getLucideIcon('XCircle')"
												:size="25"
												:stroke-width="1.5"
												class="text-danger"
												@click="showDeleteContractDialog(item.id)"
											/>
										</b-button>
									</template>
								</b-table>
							</div>
							<div class="px-3">
								<b-button
									ref="new-contract"
									size="sm"
									variant="outline-primary"
									class="w-200-px d-flex justify-content-center align-items-center"
									block
									@click="onNewContract"
								>
									<component :is="getLucideIcon('PlusCircle')" :size="18" :stroke-width="2" />
									<div class="px-2" style="margin-bottom: -2px">
										{{ FormMSG(51, 'New contract') }}
									</div>
								</b-button>
							</div>
						</b-collapse>
					</div>
					<div class="card card-border-blue-light" v-if="isProd || isAdmin">
						<div class="container-header-card-collapsible cursor-pointer" @click="handleClickHeader('projectSpecificInfo')">
							<div class="title">
								<div class="w-90">
									<h4>
										{{ FormMSG(856, 'Project specific information') }}
									</h4>
								</div>
								<div v-if="!isFilmSingle" class="icon w-10">
									<component v-if="!projectSpecificInfo.isVisible" :is="getIconLucide('ChevronDown')" color="#06263E" :size="22" />
									<component v-if="projectSpecificInfo.isVisible" :is="getIconLucide('ChevronUp')" color="#06263E" :size="22" />
								</div>
							</div>
						</div>
						<b-collapse :visible="projectSpecificInfo.isVisible">
							<div class="content-card-collapsible">
								<b-row class="pt-3 px-3">
									<!-- <pre>{{ customFields }}</pre> -->
									<b-col
										:xl="sizeFlagInput(customFields.length)"
										:lg="sizeFlagInput(customFields.length)"
										class="d-flex mb-2 w-100"
										v-for="(customField, index) in customFields"
										:key="index"
									>
										<custom-field-template
											v-model="customField[customFieldModels[index]]"
											:field="customField"
											:name-model="customFieldModels[index]"
											:name-group="customField.nameGroup"
											:index="index"
											class="w-100"
											@custom-field-template:payload="onFieldTemplateChanged"
										/>
									</b-col>
								</b-row>
							</div>
							<div class="p-3 mt-3">
								<div class="d-flex flex-row align-items-center">
									<b-button
										size="md"
										variant="outline-primary"
										class="w-270-px d-flex justify-content-center align-items-center"
										@click="manageUserRequestCustomFields"
										block
									>
										<component :is="getLucideIcon('PlusCircle')" :size="20" :stroke-width="2.5" />
										<div class="px-2" style="margin-bottom: 0px">
											{{ FormMSG(857, 'Add/Edit Custom Fields') }}
										</div>
									</b-button>
								</div>
							</div>
						</b-collapse>
					</div>
				</div>
			</b-col>
			<b-modal
				header-class="header-class-modal-doc-package"
				:title="FormMSG(60, 'Success!')"
				class="modal-success"
				v-model="successModal"
				@ok="successModal = false"
				ok-variant="success"
				ok-only
			>
				{{ FormMSG(61, 'Your information has been saved.') }}
			</b-modal>
			<loading :active.sync="isLoading" :is-full-page="true" color="#00A09C"></loading>
			<!--Warning on delete contracts-->
			<common-warning-on-delete
				v-model="openDeleteContractDialog"
				:message="messageOnDeleteContract"
				@dialog:cancel="closeDeleteContractDialog"
				@dialog:confirm="deleteContract"
			/>
			<!--Warning on delete contracts-->
			<!--Contract Modal-->
			<b-modal
				header-class="header-class-modal-doc-package"
				v-model="showContractModal"
				@cancel="onCancelContractForm"
				@close="onCancelContractForm"
				cancel-variant="light"
				:title="contractDialogTitle"
				size="xxl"
				footer-class="w-100"
				centered
				no-close-on-backdrop
			>
				<b-container fluid v-if="$screen.width > 576">
					<b-row align-h="end">
						<b-col cols="2" style="display: none">
							<v-select
								id="contract-status-selector"
								label="text"
								v-if="selectedContractId"
								v-model="currentContractStatus"
								:options="getStatusMenu"
								:reduce="(option) => option.value"
								@input="setContractStatus"
								class="w-75 border-radius-status"
							>
								<template #option="option">
									<component
										:is="getLucideIcon('Circle')"
										class="mr-3"
										:class="option.selectColorClass"
										:size="12"
										:stroke-width="1.5"
										style="border-radius: 100%"
									/>
									{{ option.text }}
								</template>
								<template #selected-option="{ text, selectColorClass }">
									<component
										:is="getLucideIcon('Circle')"
										class="mr-3"
										:class="selectColorClass"
										:size="12"
										:stroke-width="1.5"
										style="border-radius: 100%"
									/>
									{{ text }}
								</template>
							</v-select>
							<span v-else
								><component
									:is="getLucideIcon('Circle')"
									class="mr-3"
									:class="getStatusMenu[0].selectColorClass"
									:size="14"
									:stroke-width="1.5"
									style="border-radius: 100%"
								/>{{ getStatusMenu[0].text }}</span
							>
						</b-col>
						<b-col cols="auto" class="d-flex px-0">
							<b-form-group :label="FormMSG(11, 'Contract no.')" label-for="userId" label-cols="auto" content-cols="auto" :horizontal="true">
								<b-form-input id="userId" :value="selectedContractData.id ? selectedContractData.id : ''" type="text" disabled></b-form-input>
							</b-form-group>
							<b-dropdown
								no-caret
								boundary="window"
								class="btn-transparent d-flex justify-content-end align-items-start"
								variant="none"
								size="sm"
								dropleft
								offset="35"
								lazy
								:disabled="true"
							>
								<template #button-content>
									<component :is="getLucideIcon('MoreVertical')" :size="25" :stroke-width="1.5" />
								</template>
								<b-dropdown-item>
									<div class="text-color-rhapsody-in-blue d-flex align-items-center">
										<component :is="getLucideIcon('Mail')" :size="16" />
										<div class="ml-2">{{ FormMSG(125, 'Email user') }}</div>
									</div>
								</b-dropdown-item>
								<b-dropdown-item>
									<div class="text-color-rhapsody-in-blue d-flex align-items-center">
										<component :is="getLucideIcon('Download')" :size="16" />
										<div class="ml-2">{{ FormMSG(2003, 'Download infosheet') }}</div>
									</div>
								</b-dropdown-item>
								<b-dropdown-item>
									<div class="text-color-rhapsody-in-blue d-flex align-items-center">
										<component :is="getLucideIcon('Trash')" :size="16" class="text-danger" />
										<div class="ml-2">{{ FormMSG(63, 'Delete user') }}</div>
									</div>
								</b-dropdown-item>
							</b-dropdown>
						</b-col>
					</b-row></b-container
				>
				<contract-dialog
					:contractInput="selectedContractData"
					:hide-send-package-btn="true"
					:fill-class-container="'container-layout'"
					:user-id="userId"
					:show-pay-code="showPayCode"
					:is-saved="executeSaveContractAction"
					@isLoading="isLoading = $event"
					@successModal="successModal = $event"
					@erreur="
						($event) => {
							erreur = $event;
							setSaveContractButtonDisable = false;
							executeSaveContractAction = false;
						}
					"
					@contract-dialog:contract-data-change="setSaveContractButtonDisable = false"
					@contract-dialog:contract-saved="contractSaved"
					@contract-dialog:custom-field-value-inputs:changed="onCustomFieldValueInputsChanged"
				/>
				<template #modal-footer="{ cancel }" v-if="$screen.width > 576">
					<b-container fluid>
						<b-row align-h="between">
							<b-col cols="12" sm="auto" class="d-flex align-items-center" style="gap: 15px">
								<b-button
									variant="light"
									class="px-4 btn-custom-outline-gray"
									@click="$emit('user-dialog:send-single-dpae', [selectedContractData.id])"
									v-if="useDpae"
									:disabled="!isSendableStatusDpae(selectedContractData.dpaeStatus)"
								>
									{{ FormMSG(357, 'Send to DPAE') }}
								</b-button>
								<div v-if="useDpae">
									<component
										:is="getLucideIcon(getDpaeStatusIcon(selectedContractData.dpaeStatus))"
										:size="20"
										:stroke-width="2"
										:class="getDpaeStatusClass(selectedContractData.dpaeStatus)"
										class="mr-2"
									/>
									<span :class="selectedContractData.dpaeStatus === 1 ? getDpaeStatusClass(selectedContractData.dpaeStatus) : ''">{{
										getDpaeStatusText(selectedContractData.dpaeStatus)
									}}</span>
								</div>
							</b-col>
							<b-col cols="12" sm="auto" class="d-flex" style="gap: 10px">
								<b-button variant="light" @click="cancel()" class="px-4">
									{{ FormMSG(354, 'Cancel') }}
								</b-button>
								<b-button variant="secondary" @click="onSaveContract" class="px-4" :disabled="setSaveContractButtonDisable">
									<b-spinner v-show="setSaveContractButtonDisable && executeSaveContractAction" small class="mr-3" />{{
										FormMSG(356, 'Save')
									}}
								</b-button></b-col
							>
						</b-row></b-container
					>
				</template>
			</b-modal>
			<!--Contract Modal-->
			<custom-field-modal
				ref="userRequestCustomField"
				v-model="isUserRequestCustomFieldModalOpen"
				:parent-type="defaultUserRequestCustomFieldParentType"
				@custom-field-main-modal:closed="onUserRequestCustomFieldMainModalClosed"
				@custom-field-main-modal:reload="onUserRequestCustomFieldMainModalReloaded"
			/>
		</b-row>
	</errorContainer>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import gql from 'graphql-tag';
import { store } from '@/store';
import { DateTime, Interval } from 'luxon';
import ContractMobileView from './ContractMobileView';
import ContractDialog from './ContractDialog';
import languageMessages from '@/mixins/languageMessages';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { addEncodingValidation } from '@/cruds/users.crud';
//import AddressForm from "~/components/Address/Forms/Address.vue";
import AddressForm from '@/components/Forms/Address';
import verte from 'verte';
import 'verte/dist/verte.css';
import dpaeMixin from '@/mixins/dpae.mixin';
import { DPAE_STATUS } from '@/shared/constants';
import DpaeTooltip from '@/components/DPAE/DpaeTooltip';
import GlobalMixin from '@/mixins/global.mixin';
import isSingleProjectMixin from '@/mixins/isSingleProject.mixin';
import { updateUserProjectReference, updateUser, getUserById } from '@/cruds/users.crud';
import * as icons from 'lucide-vue';
import Profile from '@/components/MemoDeal/Profile';
import { contracts, addContractService, deleteContractService } from '@/cruds/contract.crud';
import CustomFieldModal from '@/components/CustomField/MainModal';
import { CUSTOM_FIELD_PARENT_TYPE, CUSTOM_FIELD_TYPE } from '@/shared/constants';
// import { getCategoryByFunctionId } from '@/cruds/categorie.crud'
//on delete contract
import CommonWarningOnDelete from '@/components/Dialogs/CommonWarningOnDeleteDialog';
import { getCustomFields } from '@/cruds/custom-field.crud';
import CustomFieldTemplate from '@/components/CustomField/CustomFieldTemplate';
import { generateSecureId, noTilde, isNil } from '@/shared/utils';

const query_user = gql`
	query ($userid: ID!) {
		User(userID: $userid) {
			id
			firstName
			name
			email
			iban
			birthDate
			language
			socSecNumber
			secondIban
			secondBic
			secondBankAccountOwnerName
			secondBankDetailXid
			civilStatus
			placeOfBirth
			placeOfBirthZip
			nationalRegistryNumber
			bic
			phone
			picture
			nationality
			companyNumber
			isDev
			isTranslator
			currencySymbol
			distanceUnit
			appLanguage
			department
			departmentName
			function
			functionName
			contractStartDate
			contractEndDate
			gender
			driverLicense
			driverLicenseNumber
			allergies
			spouseName
			spouseFirstName
			spouseBirthDate
			spouseProfession
			spouseHasSalary
			numberOfDependentChild
			numberOfDisabledChild
			numberOfDependentPerson
			numberOfDisabledPerson
			personToContact
			customFunctionName
			personToContactPhone
			disabled
			color
			showWizard
			addressID
			address {
				street
				number
				zip
				city
				box
				state
				country
				countryCode
				countryKey
			}
			reference
			idNumber
			shoeSize
			tShirtSize
			showMyEmailInCrew
			showMyPhoneInCrew
			idName
			pictureId
			pictureIdVerso
			pictureDriverLicense
			pictureDriverLicenseVerso
			signatureId
			taxesPercentage
			otherStudyLevel
			size
			pantSize
			jacketSize
			vehicule {
				id
				name
				brand
				model
				numberPlate
				type
				subType
				additionalSubType
				energy
				reference
				kgCoTwoPerKm
				taxHorsepower
				grayCardXid
				grayCardVersoXid
			}
			pictureRib
			maidenName
			title
			birthCountry
			congesSpectacle
			bankAccountOwnerName
			bankDetailXid
			taxCountry
			postalAddressID
			postalAddress {
				id
				street
				number
				box
				zip
				state
				city
				country
				countryCode
				countryKey
				other
				lat
				lng
			}
			socialSecurityCardXid
			insuranceCertificateXid
			socialSecurityCenter
			socialSecurityCenterAddressId
			lastMedicalVisit
			medicalVisitValidity
			medicalVisitDocumentXid
			disabilityNotification
			disabilityType
			disabilityStatus
			invalidity
			rqth
			invalidityPourcentage
			retired
			retirementDate
			trainLoyaltyCard
			trainDiscountCard
			flyingLoyaltyCard
			otherAirlineCard
			residencePermitNumber
			residencePermitEndValidity
			residentPermitXid
			workCardXid
			pseudonym
			professionalAssociation
			session1A
			session1B
			session2A
			session2B
			session3A
			session3B
			session4A
			session4B
			session5A
			session5B
			session6A
			session6B
			session7A
			session7B
			session8A
			session8B
			contractDpaeStatus
			dpaeInvalidFields {
				legalEntityInvalidFields
				userInvalidFields
			}
		}
	}
`;

// const mutation_updUser = gql`
// 	mutation ($UserId: ID!, $UpdatedUser: UserInput!) {
// 		UpdUser(UserId: $UserId, UpdatedUser: $UpdatedUser) {
// 			id
// 		}
// 	}
// `;

const mutation_signInAs = gql`
	mutation ($userid: ID!) {
		signInAs(userID: $userid)
	}
`;

export default {
	name: 'UserDialog',
	mixins: [languageMessages, GlobalMixin, isSingleProjectMixin, dpaeMixin],
	props: {
		selectedUserData: {
			type: Object,
			required: false,
			default: () => {}
		},
		caption: {
			type: String,
			default: 'User id'
		},
		userId: {
			type: String
		},
		fillClassContainer: {
			type: String,
			required: false,
			default: 'container-layout' // default empty, or 'container-layout'
		},
		displayAddBtn: {
			type: Boolean,
			required: false,
			default: true
		},
		handleAccessLevel: {
			type: Function,
			required: false
		},
		projectId: {
			type: Number,
			required: false,
			default: 0
		},
		isSaved: {
			type: Boolean,
			required: true,
			default: false
		},
		reloadContracts: {
			type: Boolean,
			required: true,
			default: false
		}
	},
	components: {
		Loading,
		ContractDialog,
		ContractMobileView,
		AddressForm,
		verte,
		Profile,
		CommonWarningOnDelete,
		CustomFieldModal,
		CustomFieldTemplate,
		DpaeTooltip
	},
	apollo: {
		User: {
			query: query_user,
			variables() {
				return {
					userid: parseInt(this.userId, 10)
				};
			},
			error(err) {
				this.erreur = err;
			},
			fetchPolicy: 'no-cache'
		}
	},
	data: () => {
		return {
			searchAddressFromVat: null,
			googleAutoCompleteRestrictedCountry: null,
			erreur: {},
			warning: '',
			User: null,
			contracts: [],
			successModal: false,
			isLoading: false,
			accordion: {
				isVisible: true
			},
			configAccordion: {
				isVisible: false
			},
			projectSpecificInfo: {
				isVisible: false
			},
			userEntryData: {},
			showPayCode: false,
			// check change in user data to active save button
			userDataCopy: {},
			activeSaveEmitOnce: false,
			// all about delete contract
			contractToDelete: null,
			openDeleteContractDialog: false,
			// all about contract dialog
			showContractModal: false,
			selectedContractId: null,
			selectedContractData: {},
			currentContractStatus: 0,
			isUpdatingContract: false,
			setSaveContractButtonDisable: true,
			executeSaveContractAction: false,
			CUSTOM_FIELD_PARENT_TYPE,
			isUserRequestCustomFieldModalOpen: false,
			defaultUserRequestCustomFieldParentType: CUSTOM_FIELD_PARENT_TYPE.userProject,
			customFields: [],
			customFieldModels: [],
			userRequestCustomFieldValueInputs: [],
			userCustomFieldValueInputs: [],
			customFieldValueInputs: [],
			DPAE_STATUS: DPAE_STATUS
		};
	},
	async created() {
		this.userDataCopy = {};
		this.activeSaveEmitOnce = false;
		await this.fetchContractsList();
		this.showPayCode = !(store.getCurrentProjectConfig().payrollInterface === 0);
		this.customFields = await this.customFieldList();
	},
	watch: {
		// whenever question changes, this function will run
		contracts: {
			handler: function (newContracts) {
				for (let i = 0; i < newContracts.length; i++) {
					for (let j = i + 1; j < newContracts.length; j++) {
						const start1 = DateTime.fromISO(newContracts[i].startDate);
						const end1 = DateTime.fromISO(newContracts[i].endDate);
						const start2 = DateTime.fromISO(newContracts[j].startDate);
						const end2 = DateTime.fromISO(newContracts[j].endDate);

						const interval1 = Interval.fromDateTimes(start1, end1);
						const interval2 = Interval.fromDateTimes(start2, end2);

						if (interval1.overlaps(interval2)) {
							this.warning = this.FormMSG(70, 'Some of the contract dates overlap');
							return;
						} else {
							this.warning = '';
						}
					}
				}
				// this.contractsTableList = newContracts.map((item, index) => {
				// 	const matchingItem = this.contractsTableList.find((item2) => item2.id === item.id);
				// 	return {
				// 		...item,
				// 		checkStatus: matchingItem ? matchingItem.checkStatus : false
				// 	};
				// });
			},
			deep: true,
			immediate: true
		},
		'store.state.myProject.payrollInterface': {
			handler(value) {
				if (!isNil(value)) {
					this.showPayCode = value === 0 ? false : true;
				}
			}
		},
		isSaved: {
			handler: async function (value) {
				if (value) await this.updateUser();
			},
			deep: true,
			immediate: true
		},
		User: {
			handler: function (newUserData) {
				if (_.isEmpty(this.userDataCopy) && !_.isEmpty(newUserData) && !_.isNil(newUserData)) {
					this.userDataCopy = { ...newUserData };
				} else if (!_.isEqual(this.userDataCopy, newUserData) && !_.isNil(newUserData) && !this.activeSaveEmitOnce) {
					this.activeSaveEmitOnce = true;
					this.$emit('user-dialog:user-data-change');
				}
			},
			deep: true,
			immediate: true
		},
		reloadContracts: {
			handler: async function (value) {
				if (value) {
					await this.fetchContractsList();
					this.$emit('user-dialog:contracts-reloaded');
					this.setDpaeCounter();
				}
			},
			deep: true,
			immediate: true
		},
		customFieldValueInputs: {
			handler(value) {
				if (value.length > 0) {
					this.$emit('user-dialog:user-data-change');
				}
			},
			deep: true,
			immediate: true
		}
	},
	computed: {
		messageOnDeleteContract() {
			return this.FormMSG(
				3333,
				'if you delete this contract, you will not be able to recover it. This action is irreversible. Are your sure you want to delete this contract?'
			);
		},
		contractsFields() {
			const originalFields = [
				{
					label: this.FormMSG(11, 'Contract no.'),
					key: 'id',
					sortable: true,
					thStyle: { color: '#47c7bf' },
					tdClass: 'align-middle'
				},
				{
					label: this.FormMSG(20, 'Start date & time'),
					key: 'startTime',
					sortable: true,
					formatter: (v) => moment(v).format('DD/MM/YYYY HH:mm'),
					thStyle: { color: '#47c7bf' },
					tdClass: 'align-middle'
				},
				{
					label: this.FormMSG(21, 'End date'),
					key: 'endDate',
					sortable: true,
					formatter: (v) => moment(v).format('DD/MM/YYYY'),
					thStyle: { color: '#47c7bf' },
					tdClass: 'align-middle'
				},
				{
					label: this.FormMSG(22, 'Department'),
					key: 'departmentName',
					sortable: true,
					formatter: (v) => v,
					thStyle: { color: '#47c7bf' },
					tdClass: 'align-middle'
				},
				{
					label: this.FormMSG(23, 'Position'),
					key: 'functionName',
					sortable: true,
					formatter: (v) => v,
					thStyle: { color: '#47c7bf' },
					tdClass: 'align-middle'
				}
			];

			if (this.useDpae) {
				originalFields.unshift({
					label: '',
					key: 'actionSelected',
					class: 'text-center align-middle',
					sortable: false,
					thStyle: { width: '70px' },
					tdClass: 'align-middle'
				});
				originalFields.push({
					label: this.FormMSG(24, 'DPAE status'),
					key: 'dpaeStatus',
					sortable: true,
					formatter: (v) => (v ? v : ''),
					thStyle: { color: '#47c7bf' },
					tdClass: 'align-middle'
				});
			}

			originalFields.push(
				// {
				// 	label: this.FormMSG(25, 'Status'),
				// 	key: 'contractStatus',
				// 	sortable: true,
				// 	thStyle: { color: '#47c7bf' }
				// },
				{
					label: '',
					key: 'actionDelete',
					class: 'text-center align-middle',
					sortable: false,
					thStyle: { width: '70px' },
					tdClass: 'align-middle'
				}
			);

			return originalFields;
		},
		contractDialogTitle() {
			if (this.User) {
				if (!this.selectedContractId || this.selectedContractId === 0) {
					return this.FormMSG(101, 'New Contract for') + ' "' + this.User.firstName + ' ' + this.User.name + '"';
				} else {
					return this.FormMSG(102, 'Edit Contract for') + ' "' + this.User.firstName + ' ' + this.User.name + '"';
				}
			}
			return '';
		},
		useTask() {
			return store.getCurrentProjectConfig().useTask;
		},
		optionsForCountryCode() {
			return this.FormMenu(9);
		},
		optionsForCivilStatus() {
			return this.FormMenu(1001);
		},
		optionsForLanguages() {
			let menus = this.FormMenu(1);

			menus.sort((a, b) => {
				if (noTilde(a.text.trim()).toLowerCase() < noTilde(b.text.trim()).toLowerCase()) {
					return -1;
				}
				if (noTilde(a.text.trim()).toLowerCase() > noTilde(b.text.trim()).toLowerCase()) {
					return 1;
				}

				return 0;
			});

			return menus;
		},
		imageName() {
			if (this.User.picture && this.User.picture.length > 0) {
				var strImg = process.env.VUE_APP_GQL + '/images/' + this.User.picture;
				//  console.log("pictPath:",strImg);
				return strImg;
			} else {
				return 'img/brand/M.JPG';
			}
		},
		isDev() {
			return store.isDev();
		},
		isProd() {
			return store.isProd();
		},
		isAdmin() {
			return store.isAdmin();
		}
	},
	methods: {
		async fetchContractsList() {
			try {
				this.contracts = await contracts(+this.userId);
			} catch (error) {
				console.log({ error });
			}
		},
		handleUpdateUser(item) {
			const { field, value } = item;
			this.User[field] = value;
		},
		handleSetUser(item) {
			const { payload, field } = item;
			if (_.isNil(payload)) {
				this.User[field] = '0001-01-01T00:00:00Z';
			} else {
				const _date = payload.id;
				if (moment(_date).format('YYYY-MM-DD') === moment(this.User[field]).format('YYYY-MM-DD')) {
					this.User[field] = '0001-01-01T00:00:00Z';
				} else {
					this.User[field] = new Date(_date);
				}
			}
		},
		showDeleteContractDialog(contractToDetete) {
			this.contractToDelete = +contractToDetete;
			this.openDeleteContractDialog = true;
		},
		closeDeleteContractDialog() {
			this.contractToDelete = null;
			this.openDeleteContractDialog = false;
		},
		async callToDeleteContractService(contractToDelete) {
			const isContractDeleted = await deleteContractService(contractToDelete);
			if (isContractDeleted) await this.fetchContractsList();
		},
		async deleteContract() {
			if (this.contractToDelete) {
				try {
					await this.callToDeleteContractService(this.contractToDelete);
					this.createToastForMobile('Success', this.FormMSG(222, 'Contract deleted successfully'), '', 'success');
				} catch (error) {
					console.log(error);
				} finally {
					this.contractToDelete = null;
					this.openDeleteContractDialog = false;
				}
			}
			this.openDeleteContractDialog = false;
		},
		setDpaeCounter() {
			const checkedContracts = this.contracts.filter((contract) => {
				// if (contract.checkStatus === true) return _.omit(contract, ['checkStatus']);
				if (contract.checkStatus === true) return contract.id;
			});
			this.$emit('user-dialog:dpae-counter-change', checkedContracts);
		},
		contractRowClicked(contract) {
			this.selectedContractData = Object.assign({}, contract);
			if (!this.selectedContractData.status) this.selectedContractData.status = 0;
			this.currentContractStatus = this.selectedContractData.status;
			this.selectedContractId = this.selectedContractData.id;
			this.isUpdatingContract = true;
			this.showContractModal = true;
		},
		setContractStatus(status) {
			this.selectedContractData.status = status;
		},
		async onNewContract() {
			try {
				const newContractObj = await this.addContract();
				const newContractData = this.contracts.find((contract) => contract.id == newContractObj.id);
				this.selectedContractData = Object.assign({}, newContractData);
				this.selectedContractData.endDate = '';
				if (!this.selectedContractData.status) this.selectedContractData.status = 0;
				this.currentContractStatus = this.selectedContractData.status;
			} catch (error) {
				console.log(error);
			} finally {
				this.isUpdatingContract = false;
				this.showContractModal = true;
			}
		},
		async onCancelContractForm() {
			if (!this.isUpdatingContract) await this.callToDeleteContractService(+this.selectedContractData.id);
			this.isUpdatingContract = false;
			this.showContractModal = false;
		},
		async onSaveContract() {
			this.showContractModal = true;
			this.setSaveContractButtonDisable = true;
			this.executeSaveContractAction = true;
		},
		async contractSaved() {
			await this.fetchContractsList();
			this.executeSaveContractAction = false;
			this.showContractModal = false;
		},
		handleUserEntryLevelChange(data) {
			if (!_.isEmpty(this.userEntryData) && !this.activeSaveEmitOnce) {
				this.activeSaveEmitOnce = true;
				this.$emit('user-dialog:user-data-change');
			}
			this.userEntryData = Object.assign({}, data);
		},
		handleContractShowBody(payload) {
			this.accordion = payload;
		},
		handleAdress(address) {
			this.User.address = address;
		},
		isForProject() {
			//console.log("in isForProject");
			if (this.$route.path.includes('project')) {
				return true;
			} else {
				return false;
			}
		},
		updateBirthDate: function ($event) {
			if (this.User.birthDate.startsWith('T')) {
				this.User.birthDate = $event + this.User.birthDate;
			} else {
				this.User.birthDate = $event + this.User.birthDate.substring(10);
			}
		},
		isDisabled() {
			return false;
		},
		becomeUser: function (id) {
			var userid = parseInt(id, 10);
			this.$apollo
				.mutate({
					mutation: mutation_signInAs,
					variables: {
						userid
					}
				})
				.then(async (result) => {
					const response = await store.onNewToken(this.$apollo.provider.defaultClient, result.data.signInAs);
					if (response) {
						//this.$router.push("/my-dashboard")
						this.$router.go();
					}
				})
				.catch((error) => {
					this.erreur = error;
				});
		},
		addContract: async function () {
			const userid = parseInt(this.userId, 10);
			try {
				const newContractObj = await addContractService(userid);
				if (newContractObj) {
					await this.fetchContractsList();
					return newContractObj;
				} else {
					return {};
				}
			} catch (error) {
				this.erreur = error;
				return {};
			}
		},
		async updateUser() {
			await addEncodingValidation(
				parseInt(store.projectID(), 10),
				this.userEntryData.userId,
				this.userEntryData.timeSheets,
				this.userEntryData.department,
				this.userEntryData.expenses,
				this.userEntryData.po
			);

			const customFieldValueInputs = [...this.userRequestCustomFieldValueInputs, ...this.userCustomFieldValueInputs];

			let updatedUser = {
				firstName: this.User.firstName,
				name: this.User.name,
				email: this.User.email,
				phone: this.User.phone,
				iban: this.User.iban,
				bic: this.User.bic,
				secondIban: this.User.secondIban,
				secondBic: this.User.secondBic,
				secondBankAccountOwnerName: this.User.secondBankAccountOwnerName,
				secondBankDetailDocument: this.User.secondBankDetailDocument,
				secondBankDetailDocumentXid: this.User.secondBankDetailDocumentXid,
				language: this.User.language,
				socSecNumber: this.User.socSecNumber,
				civilStatus: this.User.civilStatus,
				nationalRegistryNumber: this.User.nationalRegistryNumber,
				nationality: this.User.nationality,
				birthDate: this.User.birthDate,
				companyNumber: this.User.companyNumber,
				picture: this.User.picture,
				placeOfBirth: this.User.placeOfBirth,
				placeOfBirthZip: this.User.placeOfBirthZip,
				gender: parseInt(this.User.gender),
				driverLicense: this.User.driverLicense,
				driverLicenseNumber: this.User.driverLicenseNumber,
				allergies: this.User.allergies,
				spouseName: this.User.spouseName,
				spouseFirstName: this.User.spouseFirstName,
				spouseBirthDate: this.User.spouseBirthDate,
				spouseProfession: this.User.spouseProfession,
				spouseHasSalary: this.User.spouseHasSalary,
				numberOfDependentChild: parseInt(this.User.numberOfDependentChild),
				numberOfDisabledChild: parseInt(this.User.numberOfDisabledChild),
				numberOfDependentPerson: parseInt(this.User.numberOfDependentPerson),
				numberOfDisabledPerson: parseInt(this.User.numberOfDisabledPerson),
				personToContact: this.User.personToContact,
				personToContactPhone: this.User.personToContactPhone,
				disabled: this.User.disabled,
				color: this.User.color,
				idNumber: this.User.idNumber,
				shoeSize: this.User.shoeSize,
				size: this.User.size,
				pantSize: this.User.pantSize,
				jacketSize: this.User.jacketSize,
				otherStudyLevel: this.User.otherStudyLevel,
				tShirtSize: this.User.tShirtSize,
				showMyEmailInCrew: this.User.showMyEmailInCrew,
				showMyPhoneInCrew: this.User.showMyPhoneInCrew,
				address: {
					street: this.User.address.street,
					number: this.User.address.number,
					zip: this.User.address.zip,
					city: this.User.address.city,
					box: this.User.address.box,
					state: this.User.address.state,
					country: this.User.address.country,
					countryKey: this.User.address.countryKey,
					lat: this.User.address.lat,
					lng: this.User.address.lng
				},
				idName: this.User.idName,
				taxesPercentage: parseInt(this.User.taxesPercentage, 10),
				vehicule: {
					name: this.User.vehicule.name,
					model: this.User.vehicule.model,
					brand: this.User.vehicule.brand,
					numberPlate: this.User.vehicule.numberPlate,
					type: +this.User.vehicule.type,
					subType: +this.User.vehicule.subType,
					additionalSubType: +this.User.vehicule.additionalSubType,
					energy: +this.User.vehicule.energy,
					kgCoTwoPerKm: parseFloat(this.User.vehicule.kgCoTwoPerKm)
				},
				pictureRib: this.User.pictureRib,
				maidenName: this.User.maidenName,
				title: this.User.title,
				birthCountry: this.User.birthCountry,
				congesSpectacle: this.User.congesSpectacle,
				bankAccountOwnerName: this.User.bankAccountOwnerName,
				bankDetailXid: this.User.bankDetailXid,
				taxCountry: this.User.taxCountry,
				postalAddressID: this.User.postalAddressID,
				postalAddress: {
					id: this.User.postalAddress.id,
					street: this.User.postalAddress.street,
					number: this.User.postalAddress.number,
					box: this.User.postalAddress.box,
					zip: this.User.postalAddress.zip,
					state: this.User.postalAddress.state,
					city: this.User.postalAddress.city,
					country: this.User.postalAddress.country,
					countryCode: this.User.postalAddress.countryCode,
					countryKey: this.User.postalAddress.countryKey,
					other: this.User.postalAddress.other,
					lat: this.User.postalAddress.lat,
					lng: this.User.postalAddress.lng
				},
				socialSecurityCardXid: this.User.socialSecurityCardXid,
				insuranceCertificateXid: this.User.insuranceCertificateXid,
				socialSecurityCenter: this.User.socialSecurityCenter,
				socialSecurityCenterAddressId: this.User.socialSecurityCenterAddressId,
				lastMedicalVisit: this.User.lastMedicalVisit,
				medicalVisitValidity: this.User.medicalVisitValidity,
				medicalVisitDocumentXid: this.User.medicalVisitDocumentXid,
				disabilityNotification: this.User.disabilityNotification,
				disabilityType: this.User.disabilityType,
				disabilityStatus: this.User.disabilityStatus,
				invalidity: this.User.invalidity,
				rqth: this.User.rqth,
				invalidityPourcentage: this.User.invalidityPourcentage,
				retired: this.User.retired,
				retirementDate: this.User.retirementDate,
				trainLoyaltyCard: this.User.trainLoyaltyCard,
				trainDiscountCard: this.User.trainDiscountCard,
				flyingLoyaltyCard: this.User.flyingLoyaltyCard,
				otherAirlineCard: this.User.otherAirlineCard,
				residencePermitNumber: this.User.residencePermitNumber,
				residencePermitEndValidity: this.User.residencePermitEndValidity,
				residentPermitXid: this.User.residentPermitXid,
				workCardXid: this.User.workCardXid,
				pseudonym: this.User.pseudonym,
				professionalAssociation: this.User.professionalAssociation,
				session1A: this.User.session1A,
				session1B: this.User.session1B,
				session2A: this.User.session2A,
				session2B: this.User.session2B,
				session3A: this.User.session3A,
				session3B: this.User.session3B,
				session4A: this.User.session4A,
				session4B: this.User.session4B,
				session5A: this.User.session5A,
				session5B: this.User.session5B,
				session6A: this.User.session6A,
				session6B: this.User.session6B,
				session7A: this.User.session7A,
				session7B: this.User.session7B,
				session8A: this.User.session8A,
				session8B: this.User.session8B,
				customFunctionName: this.User.customFunctionName
			};

			await updateUser(this.User.id, updatedUser, customFieldValueInputs)
				.then((result) => {
					updateUserProjectReference({ userId: +this.User.id, reference: this.User.reference })
						.then((bool) => {
							if (bool) {
								// console.log(result)
								this.createToastForMobile(this.FormMSG(27, 'Success'), this.FormMSG(26, 'User data updated successfully'), '', 'success');
								// this.finishFormSubmission();
							}
						})
						.catch((e) => {
							console.log({ e });
						});
				})
				.catch((e) => {
					console.error(encodeURIComponent);
				})
				.finally(async () => {
					this.activeSaveEmitOnce = false;
					this.userRequestCustomFieldValueInputs = [];
					this.userCustomFieldValueInputs = [];
					this.customFieldValueInputs = [];
					this.User = await getUserById(+this.userId);
					await this.fetchContractsList();
					this.$emit('user-dialog:user-saved');
				});
		},
		finishFormSubmission() {
			this.$emit('closeForm');
		},
		getIconLucide(name) {
			return icons[name];
		},
		handleClickHeader(payload) {
			this[payload].isVisible = !this[payload].isVisible;
		},
		async customFieldList() {
			let items = await getCustomFields({ type: this.defaultUserRequestCustomFieldParentType, userId: +this.userId });

			return items.map((item, index) => {
				const prefixModel = 'custom-field-model-',
					prefixNameGroup = 'custom-field-name-group-';
				const model = generateSecureId(prefixModel + item.id);
				const nameGroup = generateSecureId(prefixNameGroup + item.id);
				const customFieldValues = item.customFieldValues;

				let value = null,
					ids = null;

				switch (+item.type) {
					case CUSTOM_FIELD_TYPE.Checkbox:
						value = [];
						if (customFieldValues.length > 0) {
							ids = [];
							for (const customFieldValue of customFieldValues) {
								ids.push(+customFieldValue.customFieldMenuChoiceValue);
							}
							value = ids;
						}

						break;
					case CUSTOM_FIELD_TYPE.Date:
						// value = moment().format('YYYY-MM-DD');
						value = null;
						if (customFieldValues.length > 0) {
							value = moment(customFieldValues[0].value).format('YYYY-MM-DD');
						}

						break;
					case CUSTOM_FIELD_TYPE.Dropdown:
						value = null;
						if (customFieldValues.length > 0) {
							value = customFieldValues[0].customFieldMenuChoiceValue.toString();
						}

						break;
					case CUSTOM_FIELD_TYPE.Freetext:
						value = null;
						if (customFieldValues.length > 0) {
							value = customFieldValues[0].value;
						}

						break;
					case CUSTOM_FIELD_TYPE.Number:
						value = 0;
						if (customFieldValues.length > 0) {
							value = customFieldValues[0].value;
						}

						break;
					case CUSTOM_FIELD_TYPE.Percentage:
						value = 0;
						if (customFieldValues.length > 0) {
							value = customFieldValues[0].value;
						}

						break;
					case CUSTOM_FIELD_TYPE.Radiobutton:
						value = null;
						if (customFieldValues.length > 0) {
							value = +customFieldValues[0].customFieldMenuChoiceValue;
						}

						break;
				}

				this.customFieldModels[index] = model;

				item = {
					...item,
					[model]: value,
					nameGroup
				};

				return item;
			});
		},
		manageUserRequestCustomFields() {
			this.isUserRequestCustomFieldModalOpen = true;
		},
		onUserRequestCustomFieldMainModalClosed() {
			this.isUserRequestCustomFieldModalOpen = false;
		},
		sizeFlagInput(length) {
			let size = 3;

			// if (length >= 4) {
			// 	size = 3;
			// }

			return size;
		},
		async onUserRequestCustomFieldMainModalReloaded() {
			this.customFields = await this.customFieldList();
		},
		onFieldTemplateChanged(payload) {
			// console.log({ userRequestPayload: payload });
			const findIndex = this.userRequestCustomFieldValueInputs.findIndex((customFieldValue) => +customFieldValue.index === +payload.index);
			let value = null;

			if (payload.type === CUSTOM_FIELD_TYPE.Checkbox) {
				value = payload[this.customFieldModels[payload.index]].join(',');
			} else {
				value = payload[this.customFieldModels[payload.index]].toString();
			}

			const customFieldValue = {
				index: payload.index,
				userId: +this.userId,
				customFieldId: +payload.customFieldId,
				value
				// customFieldValueId: 0
			};

			if (findIndex === -1) {
				this.userRequestCustomFieldValueInputs.push(customFieldValue);
			} else {
				this.userRequestCustomFieldValueInputs[findIndex] = {
					...this.userRequestCustomFieldValueInputs[findIndex],
					...customFieldValue
				};
			}
			// console.log({ userRequestCustomFieldValueInputs: this.userRequestCustomFieldValueInputs });

			this.customFieldValueInputs = [...this.userRequestCustomFieldValueInputs, ...this.userCustomFieldValueInputs];

			this.customFields = this.customFields.map((customField, index) => {
				if (+customField.id === +payload.id) {
					customField = {
						...payload
					};
				}

				return customField;
			});
		},
		onUserCustomFieldValueChanged(payload) {
			// console.log({ userCustomFieldValues: payload });
			this.userCustomFieldValueInputs = payload;

			this.customFieldValueInputs = [...this.userRequestCustomFieldValueInputs, ...this.userCustomFieldValueInputs];
			if (this.customFieldValueInputs.length > 0) {
				this.setSaveContractButtonDisable = false;
			}
		},
		onCustomFieldValueInputsChanged(payload) {
			this.customFieldValueInputs = payload;
			if (this.customFieldValueInputs.length > 0) {
				this.setSaveContractButtonDisable = false;
			}
		}
	}
};
</script>
