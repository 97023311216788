<template>
	<b-tooltip id="dpaetooltip" custom-class="dpae-custom-tooltip custom-tooltip" :target="'tooltip-target-' + target" triggers="hover" placement="left">
		<b-row
			v-if="contractDpaeStatus === DPAE_STATUS.NOT_SENT && checkIfThereIsInvalidFields(user)"
			class="align-items-start text-start v-boottrap-custom-tooltip"
		>
			<b-col class="text-start py-1" sm="12">
				<div class="missing-info-title bottom-separator">{{ FormMSG(9, 'Missing info') }}</div>
				<div v-if="checkIfThereIsInvalidFieldsInUser(user)" class="missing-info-sub-title pt-1">{{ FormMSG(7, 'User :') }}</div>
				<div v-if="checkIfThereIsInvalidFieldsInUser(user)" v-html="getUserInvalidFields(user.dpaeInvalidFields.userInvalidFields)"></div>
				<div v-if="checkIfThereIsInvalidFieldsInLegalEntity(user)" class="missing-info-sub-title top-separator pt-1">
					{{ FormMSG(8, 'Legal Entity :') }}
				</div>
				<div v-if="checkIfThereIsInvalidFieldsInLegalEntity(user)" v-html="getUserInvalidFields(user.dpaeInvalidFields.legalEntityInvalidFields)"></div>
			</b-col>
		</b-row>
		<b-row v-if="contractDpaeStatus === DPAE_STATUS.PROCESSING" class="align-items-start text-start v-boottrap-custom-tooltip">
			<b-col class="text-start py-1 d-flex flex-row align-items-center" sm="12">
				<div class="missing-info-sub-title">{{ FormMSG(1, 'Sent to DPAE :') }}</div>
				<div class="pl-2">
					{{ formatDate(forUser ? data.contractDpaeSendDate : data.dpaeSendDate) }}
				</div>
			</b-col>
		</b-row>
		<b-row v-if="contractDpaeStatus === DPAE_STATUS.CONFIRMED" class="align-items-start text-start v-boottrap-custom-tooltip">
			<b-col class="text-start py-1 d-flex flex-row align-items-center" sm="12">
				<div class="missing-info-sub-title">{{ FormMSG(1, 'Sent to DPAE :') }}</div>
				<div class="pl-2">
					{{ formatDate(forUser ? data.contractDpaeSendDate : data.dpaeSendDate) }}
				</div>
			</b-col>
			<b-col class="text-start py-1 d-flex flex-row align-items-center" sm="12">
				<div class="missing-info-sub-title">{{ FormMSG(5, 'DPAE Publication :') }}</div>
				<div class="pl-2">
					{{ formatDate(forUser ? data.contractDpaePublicationDate : data.dpaePublicationDate) }}
				</div>
			</b-col>
			<b-col class="text-start py-1 d-flex flex-row align-items-center" sm="12">
				<div class="missing-info-sub-title">{{ FormMSG(2, 'Ref Dossier :') }}</div>
				<div class="pl-2">
					{{ forUser ? data.contractDpaeContractRef : data.dpaeContractRef }}
				</div>
			</b-col>
		</b-row>
		<b-row v-if="contractDpaeStatus === DPAE_STATUS.NOT_SENT_MISSING_INFO" class="align-items-start text-start v-boottrap-custom-tooltip">
			<b-col class="text-start py-1" sm="12"
				>{{ FormMSG(1, 'Sent to DPAE :') }} {{ formatDate(forUser ? data.contractDpaeSendDate : data.dpaeSendDate) }}
			</b-col>
			<b-col class="text-start py-1" sm="12"
				>{{ FormMSG(2, 'Ref Dossier :') }} {{ forUser ? data.contractDpaeContractRef : data.dpaeContractRef }}
			</b-col>
			<b-col class="text-start py-1" sm="12"
				>{{ FormMSG(3, 'Return Code :') }} {{ forUser ? data.contractDpaeReturnMessage : data.dpaeReturnMessage }}
			</b-col>
		</b-row>
		<b-row v-if="contractDpaeStatus === DPAE_STATUS.FAILED" class="align-items-start text-start v-boottrap-custom-tooltip">
			<b-col class="text-start py-1 d-flex flex-row align-items-center" sm="12">
				<div class="missing-info-sub-title">{{ FormMSG(1, 'Sent to DPAE :') }}</div>
				<div class="pl-2">
					{{ formatDate(forUser ? data.contractDpaeSendDate : data.dpaeSendDate) }}
				</div>
			</b-col>
			<b-col class="text-start py-1 d-flex flex-row align-items-center" sm="12">
				<div class="missing-info-sub-title">{{ FormMSG(2, 'Ref Dossier :') }}</div>
				<div class="pl-2">
					{{ forUser ? data.contractDpaeContractRef : data.dpaeContractRef }}
				</div>
			</b-col>
			<b-col class="text-start py-1 d-flex flex-row align-items-center" sm="12">
				<div class="missing-info-sub-title">{{ FormMSG(3, 'Return Code :') }}</div>
				<div class="pl-2">
					{{ forUser ? data.contractDpaeReturnMessage : data.dpaeReturnMessage }}
				</div>
			</b-col>
		</b-row>
	</b-tooltip>
</template>

<script>
import { DPAE_STATUS } from '@/shared/constants';
import languageMessages from '@/mixins/languageMessages';
import moment from 'moment';
import dpaeMixin from '@/mixins/dpae.mixin';

export default {
	name: 'DpaeTooltip',
	mixins: [languageMessages, dpaeMixin],
	props: {
		target: {
			type: String,
			default: ''
		},
		contractDpaeStatus: {
			type: Number,
			required: true
		},
		data: {
			type: Object,
			required: true
		},
		user: {
			type: Object,
			required: true
		},
		forUser: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			DPAE_STATUS: DPAE_STATUS,
			show: true
		};
	},
	methods: {
		formatDate(date) {
			return moment(date).format('DD/MM/YYYY HH:mm');
		}
	}
};
</script>

<style scoped>
.dpae-custom-tooltip.tooltip-inner {
	background-color: white !important;
	color: black !important;
}

.dpae-custom-tooltip.arrow {
	background-color: white !important;
}

.text-start {
	text-align: start !important;
}

#dpaetooltip {
	opacity: 1 !important;
}

#dpaetooltip .tooltip.b-tooltip {
	opacity: 1 !important;
}

.missing-info-title {
	font-weight: bold;
	font-size: 15px;
	padding-bottom: 5px;
}

.missing-info-sub-title {
	font-weight: 600;
}
</style>
<style>
.tooltip-inner:has(.v-boottrap-custom-tooltip) {
	max-width: 250px !important;
	width: 250px !important;
	background-color: rgb(255, 255, 255) !important;
	color: black;
	border: 1px solid rgb(187, 187, 187);
}

.custom-tooltip:has(.arrow::before) {
	bottom: 0 !important;
	border-width: 0 0.4rem 0.4rem !important;
	border-bottom-color: #8b9121 !important;
	background-color: #8b9121 !important;
}

.text-underline {
	text-decoration: underline;
}

.top-separator {
	border-top: 0.005rem solid rgba(226, 230, 235, 0.85);
}

.bottom-separator {
	border-bottom: 0.005rem solid rgba(226, 230, 235, 0.85);
}
.gap-2 {
	gap: 4px;
}
</style>
