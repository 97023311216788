var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pt-4", class: _vm.fillClassContainer },
    [
      _c(
        "b-form",
        { on: { submit: _vm.submitForm } },
        [
          _c("a", { ref: "scrollTop" }),
          _c("div", { staticClass: "card-collapsible px-0" }, [
            _c(
              "div",
              {
                staticClass:
                  "header-card-collapsible d-flex flex-row align-items-center cursor-pointer",
                on: {
                  click: function ($event) {
                    return _vm.handleCollapsibleHeader(
                      "work-general-information"
                    )
                  },
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "title w-90" },
                  [
                    _c("b-icon", {
                      staticStyle: { color: "rgba(6, 38, 62, 0.84)" },
                      attrs: { icon: "file-earmark-text" },
                    }),
                    _c("span", { staticClass: "ml-3" }, [
                      _vm._v(
                        _vm._s(_vm.FormMSG(25, "Work general information"))
                      ),
                    ]),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "cursor-pointer d-flex justify-content-end w-10",
                  },
                  [
                    !_vm.configCardAccordion["work-general-information"]
                      .isVisible
                      ? _c(_vm.getIconLucide("ChevronDown"), {
                          tag: "component",
                          attrs: { color: "#06263E", size: 22 },
                        })
                      : _vm._e(),
                    _vm.configCardAccordion["work-general-information"]
                      .isVisible
                      ? _c(_vm.getIconLucide("ChevronUp"), {
                          tag: "component",
                          attrs: { color: "#06263E", size: 22 },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            ),
          ]),
          _c(
            "b-collapse",
            {
              attrs: {
                visible:
                  _vm.configCardAccordion["work-general-information"].isVisible,
              },
            },
            [
              _c(
                "div",
                { staticClass: "content-card-collapsible" },
                [
                  _vm.fromJobRequest
                    ? _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { attrs: { sm: "8", md: "3", lg: "3", xl: "3" } },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: _vm.FormMSG(
                                      1897,
                                      "Original department"
                                    ),
                                  },
                                },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      placeholder: _vm.FormMSG(
                                        1579,
                                        "Enter a department"
                                      ),
                                    },
                                    model: {
                                      value: _vm.$v.originalDepartment.$model,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.$v.originalDepartment,
                                          "$model",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "$v.originalDepartment.$model",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            {
                              staticStyle: { "padding-top": "20px" },
                              attrs: { sm: "8", md: "3", lg: "3", xl: "3" },
                            },
                            [
                              _c(
                                "b-button",
                                {
                                  attrs: {
                                    block: "",
                                    variant: "outline-primary",
                                    disabled: _vm.disableBtnCreateDepartment,
                                  },
                                  on: { click: _vm.handleClickAddDepartment },
                                },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t" +
                                      _vm._s(
                                        _vm.FormMSG(1898, "Create department")
                                      ) +
                                      "\n\t\t\t\t\t\t"
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { sm: "8", md: "3", lg: "3", xl: "3" } },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: _vm.FormMSG(
                                      1899,
                                      "Original function"
                                    ),
                                  },
                                },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      placeholder: _vm.FormMSG(
                                        1784,
                                        "Enter a function"
                                      ),
                                    },
                                    model: {
                                      value: _vm.$v.originalFunction.$model,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.$v.originalFunction,
                                          "$model",
                                          $$v
                                        )
                                      },
                                      expression: "$v.originalFunction.$model",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            {
                              staticStyle: { "padding-top": "20px" },
                              attrs: { sm: "8", md: "3", lg: "3", xl: "3" },
                            },
                            [
                              _c(
                                "b-button",
                                {
                                  attrs: {
                                    block: "",
                                    variant: "outline-primary",
                                    disabled: _vm.disableBtnCreateFunction,
                                  },
                                  on: { click: _vm.handleClickCreateFunction },
                                },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t" +
                                      _vm._s(
                                        _vm.FormMSG(1900, "Create function")
                                      ) +
                                      "\n\t\t\t\t\t\t"
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("department-selection", {
                    ref: "depfun",
                    attrs: {
                      "edit-data": _vm.contract,
                      "disable-edit": _vm.isEditable() === false,
                      "burger-field": "",
                      "is-submitted": _vm.isHandledCcn2642,
                      "refresh-list": _vm.refreshDepartmentSelection,
                      "from-job-request": _vm.fromJobRequest,
                      useMemoDeal: _vm.useMemoDeal,
                    },
                    on: {
                      "department-function-selector:departmentSelected":
                        _vm.handleDepartmentChange,
                      "department-function-selector:functionSelected":
                        _vm.handleFunctionChange,
                      "department-function:selector:invalid":
                        _vm.isInvalidateFields,
                      "department-function-selection:init-finish":
                        _vm.handleDepartmentSelectionInitFinish,
                      "active-template:status": _vm.handleChangeStatusTemplate,
                    },
                  }),
                  !_vm.isFilmSingle && !_vm.isFilmSingleFree
                    ? _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { attrs: { sm: "12", md: "12", lg: "3", xl: "3" } },
                            [
                              _c(
                                "b-form-group",
                                {
                                  directives: [
                                    {
                                      name: "uni-for",
                                      rawName: "v-uni-for",
                                      value: "startTime",
                                      expression: "'startTime'",
                                    },
                                  ],
                                  attrs: {
                                    label: _vm.FormMSG(10, "Start date & time"),
                                  },
                                },
                                [
                                  _c("v-date-picker", {
                                    attrs: {
                                      mode: "dateTime",
                                      locale: _vm.navigatorLang,
                                      is24hr: !_vm.country12HList.includes(
                                        _vm.navigatorLang
                                      ),
                                      timezone: "UTC",
                                      masks: _vm.datetimeMask,
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function ({
                                            togglePopover,
                                            inputValue,
                                            inputEvents,
                                          }) {
                                            return [
                                              _c(
                                                "b-input-group",
                                                _vm._g(
                                                  {
                                                    staticClass: "w-100",
                                                    on: {
                                                      focus: function ($event) {
                                                        _vm.isEditable()
                                                          ? togglePopover
                                                          : void 0
                                                      },
                                                    },
                                                  },
                                                  inputEvents
                                                ),
                                                [
                                                  _c("b-form-input", {
                                                    directives: [
                                                      {
                                                        name: "mask",
                                                        rawName: "v-mask",
                                                        value:
                                                          _vm.datetimeVmask,
                                                        expression:
                                                          "datetimeVmask",
                                                      },
                                                    ],
                                                    staticClass: "form-control",
                                                    class: {
                                                      "is-invalid":
                                                        _vm.$v.contract
                                                          .startTime.$error,
                                                    },
                                                    staticStyle: {
                                                      "border-radius":
                                                        "10px 0 0 10px",
                                                    },
                                                    attrs: {
                                                      value: inputValue,
                                                      disabled:
                                                        _vm.isEditable() ===
                                                        false,
                                                      placeholder:
                                                        _vm.country12HList.includes(
                                                          _vm.navigatorLang
                                                        )
                                                          ? _vm.FormMSG(
                                                              85,
                                                              "MM/DD/YYYY hh:mm AM"
                                                            )
                                                          : _vm.FormMSG(
                                                              79,
                                                              "DD/MM/YYYY HH:mm"
                                                            ),
                                                    },
                                                  }),
                                                  _c(
                                                    "b-input-group-append",
                                                    {
                                                      staticStyle: {
                                                        cursor: "pointer",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "b-input-group-text",
                                                        {
                                                          staticClass:
                                                            "input-group-text bgc-grey-light",
                                                        },
                                                        [
                                                          _c(
                                                            _vm.getLucideIcon(
                                                              "Calendar"
                                                            ),
                                                            {
                                                              tag: "component",
                                                              attrs: {
                                                                size: 20,
                                                                "stroke-width": 2,
                                                              },
                                                            }
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm.$v.contract.startTime.$error
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "invalid-feedback",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t" +
                                                          _vm._s(
                                                            _vm.FormMSG(
                                                              78,
                                                              "Start date contract, a valid date is required"
                                                            )
                                                          ) +
                                                          "\n\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      3042668781
                                    ),
                                    model: {
                                      value: _vm.contract.startTime,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.contract, "startTime", $$v)
                                      },
                                      expression: "contract.startTime",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { sm: "12", md: "12", lg: "3", xl: "3" } },
                            [
                              _c(
                                "b-form-group",
                                {
                                  directives: [
                                    {
                                      name: "uni-for",
                                      rawName: "v-uni-for",
                                      value: "endDate",
                                      expression: "'endDate'",
                                    },
                                  ],
                                  attrs: {
                                    label: _vm.FormMSG(
                                      11,
                                      "End date (optional)"
                                    ),
                                  },
                                },
                                [
                                  _c("v-date-picker", {
                                    attrs: {
                                      "available-dates": {
                                        start: _vm
                                          .moment(_vm.contract.startTime)
                                          .format("YYYY-MM-DD"),
                                        end: null,
                                      },
                                      locale: _vm.navigatorLang,
                                      masks: _vm.country12HList.includes(
                                        _vm.navigatorLang
                                      )
                                        ? _vm.endDateEnMask
                                        : _vm.endDateFrMask,
                                      timezone: "UTC",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function ({
                                            togglePopover,
                                            inputValue,
                                            inputEvents,
                                          }) {
                                            return [
                                              _c(
                                                "b-input-group",
                                                _vm._g(
                                                  {
                                                    on: {
                                                      focus: function ($event) {
                                                        _vm.isEditable()
                                                          ? togglePopover
                                                          : void 0
                                                      },
                                                    },
                                                  },
                                                  inputEvents
                                                ),
                                                [
                                                  _c("b-form-input", {
                                                    directives: [
                                                      {
                                                        name: "mask",
                                                        rawName: "v-mask",
                                                        value: "##/##/####",
                                                        expression:
                                                          "'##/##/####'",
                                                      },
                                                    ],
                                                    staticClass: "form-control",
                                                    class: {
                                                      "is-invalid":
                                                        _vm.endDateValid,
                                                    },
                                                    staticStyle: {
                                                      "border-radius":
                                                        "10px 0 0 10px",
                                                    },
                                                    attrs: {
                                                      value: inputValue,
                                                      disabled:
                                                        _vm.isEditable() ===
                                                        false,
                                                      placeholder:
                                                        _vm.country12HList.includes(
                                                          _vm.navigatorLang
                                                        )
                                                          ? _vm.FormMSG(
                                                              86,
                                                              "MM/DD/YYYY"
                                                            )
                                                          : _vm.FormMSG(
                                                              80,
                                                              "DD/MM/YYYY"
                                                            ),
                                                    },
                                                  }),
                                                  _c(
                                                    "b-input-group-append",
                                                    {
                                                      staticStyle: {
                                                        cursor: "pointer",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "b-input-group-text",
                                                        {
                                                          staticClass:
                                                            "input-group-text bgc-grey-light",
                                                        },
                                                        [
                                                          _c(
                                                            _vm.getLucideIcon(
                                                              "Calendar"
                                                            ),
                                                            {
                                                              tag: "component",
                                                              attrs: {
                                                                size: 20,
                                                                "stroke-width": 2,
                                                              },
                                                            }
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm.endDateValid
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "invalid-feedback",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t" +
                                                          _vm._s(
                                                            _vm.FormMSG(
                                                              81,
                                                              "End date contract, a valid date is required"
                                                            )
                                                          ) +
                                                          "\n\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm.enableEndDateError
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "invalid-feedback",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t" +
                                                          _vm._s(
                                                            _vm.FormMSG(
                                                              4,
                                                              "End date should be greater than start date"
                                                            )
                                                          ) +
                                                          "\n\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      3003021491
                                    ),
                                    model: {
                                      value: _vm.contract.endDate,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.contract, "endDate", $$v)
                                      },
                                      expression: "contract.endDate",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { sm: "12", md: "12", lg: "3", xl: "3" } },
                            [
                              _c(
                                "b-form-group",
                                {
                                  directives: [
                                    {
                                      name: "uni-for",
                                      rawName: "v-uni-for",
                                      value: "daysforeseens",
                                      expression: "'daysforeseens'",
                                    },
                                  ],
                                  attrs: {
                                    label: _vm.FormMSG(
                                      12,
                                      "Number of workign days foreseen"
                                    ),
                                  },
                                },
                                [
                                  _c("b-form-input", {
                                    directives: [
                                      {
                                        name: "uni-id",
                                        rawName: "v-uni-id",
                                        value: "daysforeseens",
                                        expression: "'daysforeseens'",
                                      },
                                    ],
                                    attrs: {
                                      disabled: _vm.isEditable() === false,
                                      type: "number",
                                    },
                                    model: {
                                      value: _vm.contract.daysForeseen,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.contract,
                                          "daysForeseen",
                                          $$v
                                        )
                                      },
                                      expression: "contract.daysForeseen",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { sm: "12", md: "12", lg: "3", xl: "3" } },
                            [
                              _c(
                                "b-form-group",
                                {
                                  directives: [
                                    {
                                      name: "uni-for",
                                      rawName: "v-uni-for",
                                      value: "salType",
                                      expression: "'salType'",
                                    },
                                  ],
                                  attrs: {
                                    label: _vm.FormMSG(535, "Salary type"),
                                  },
                                },
                                [
                                  _c("b-form-select", {
                                    directives: [
                                      {
                                        name: "uni-id",
                                        rawName: "v-uni-id",
                                        value: "salType",
                                        expression: "'salType'",
                                      },
                                    ],
                                    attrs: {
                                      options: _vm.salaryTypeOptions,
                                      disabled: _vm.isEditable() === false,
                                    },
                                    model: {
                                      value: _vm.contract.salaryType,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.contract,
                                          "salaryType",
                                          $$v
                                        )
                                      },
                                      expression: "contract.salaryType",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.isFilmSingle && !_vm.isFilmSingleFree
                    ? _c(
                        "b-row",
                        { staticClass: "mb-2" },
                        [
                          _c(
                            "b-col",
                            [
                              _c(
                                "div",
                                { staticClass: "card-collapsible px-0" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "header-card-collapsible d-flex flex-row align-items-center cursor-pointer",
                                      on: {
                                        click: function ($event) {
                                          _vm.configCardAccordion[
                                            "pay-details"
                                          ].isVisible =
                                            !_vm.configCardAccordion[
                                              "pay-details"
                                            ].isVisible
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "title w-90" },
                                        [
                                          _c("b-icon", {
                                            staticStyle: {
                                              color: "rgba(6, 38, 62, 0.84)",
                                            },
                                            attrs: {
                                              icon: "file-earmark-text",
                                            },
                                          }),
                                          _c("span", { staticClass: "ml-3" }, [
                                            _vm._v(
                                              _vm._s(
                                                _vm.FormMSG(77, "Pay details")
                                              )
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "cursor-pointer d-flex justify-content-end w-10",
                                        },
                                        [
                                          !_vm.configCardAccordion[
                                            "pay-details"
                                          ].isVisible
                                            ? _c(
                                                _vm.getIconLucide(
                                                  "ChevronDown"
                                                ),
                                                {
                                                  tag: "component",
                                                  attrs: {
                                                    color: "#06263E",
                                                    size: 22,
                                                  },
                                                }
                                              )
                                            : _vm._e(),
                                          _vm.configCardAccordion["pay-details"]
                                            .isVisible
                                            ? _c(
                                                _vm.getIconLucide("ChevronUp"),
                                                {
                                                  tag: "component",
                                                  attrs: {
                                                    color: "#06263E",
                                                    size: 22,
                                                  },
                                                }
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "b-collapse",
                                {
                                  attrs: {
                                    visible:
                                      _vm.configCardAccordion["pay-details"]
                                        .isVisible,
                                  },
                                },
                                [
                                  _c(
                                    "b-container",
                                    {
                                      staticClass: "pt-3",
                                      attrs: { fluid: "" },
                                    },
                                    [
                                      _c(
                                        "b-row",
                                        { attrs: { "align-v": "center" } },
                                        [
                                          _c(
                                            "b-col",
                                            {
                                              attrs: {
                                                sm: "12",
                                                md: "12",
                                                lg: "3",
                                                xl: "3",
                                              },
                                            },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  directives: [
                                                    {
                                                      name: "uni-for",
                                                      rawName: "v-uni-for",
                                                      value: "employeeType",
                                                      expression:
                                                        "'employeeType'",
                                                    },
                                                  ],
                                                  attrs: {
                                                    label: _vm.FormMSG(
                                                      26,
                                                      "Employment type"
                                                    ),
                                                  },
                                                },
                                                [
                                                  _c("b-form-select", {
                                                    attrs: {
                                                      disabled:
                                                        _vm.isEditable() ===
                                                        false,
                                                      options:
                                                        _vm.employeeStatusOptions,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.contract
                                                          .employeeStatus,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.contract,
                                                          "employeeStatus",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "contract.employeeStatus",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm.useDpae
                                            ? _c(
                                                "b-col",
                                                {
                                                  attrs: {
                                                    sm: "12",
                                                    md: "12",
                                                    lg: "3",
                                                    xl: "3",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "b-form-checkbox",
                                                    {
                                                      attrs: {
                                                        id: "dpae-not-appliable",
                                                        name: "dpae-not-appliable",
                                                      },
                                                      on: {
                                                        change:
                                                          _vm.setContractDpaeStatus,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.currentDpaeStatus,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.currentDpaeStatus =
                                                            $$v
                                                        },
                                                        expression:
                                                          "currentDpaeStatus",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                          _vm._s(
                                                            _vm.FormMSG(
                                                              461,
                                                              "DPAE not appliable"
                                                            )
                                                          ) +
                                                          "\n\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        [
                                          [0, 1, 3, 4].includes(
                                            _vm.analyticPeriodType
                                          )
                                            ? _c(
                                                "b-col",
                                                {
                                                  attrs: {
                                                    sm: "12",
                                                    md: "12",
                                                    lg: "3",
                                                    xl: "3",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "b-form-group",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "uni-for",
                                                          rawName: "v-uni-for",
                                                          value:
                                                            "costCenterPrepsType",
                                                          expression:
                                                            "'costCenterPrepsType'",
                                                        },
                                                      ],
                                                      attrs: {
                                                        label:
                                                          _vm.labelCostCenterPreps,
                                                      },
                                                    },
                                                    [
                                                      _c("treeselect", {
                                                        class: {
                                                          "is-invalid":
                                                            _vm.isSubmitted &&
                                                            _vm.$v.contract
                                                              .costCenterPrepsType
                                                              .$error,
                                                        },
                                                        attrs: {
                                                          disabled:
                                                            _vm.isEditable() ===
                                                            false,
                                                          multiple: false,
                                                          clearable: false,
                                                          options:
                                                            _vm.costCenterPrepsOptions,
                                                          "disable-branch-nodes": true,
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "option-label",
                                                              fn: function ({
                                                                node,
                                                              }) {
                                                                return _c(
                                                                  "div",
                                                                  {},
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "treeselect-label",
                                                                        attrs: {
                                                                          title:
                                                                            node.label,
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            node.label
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                )
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          false,
                                                          2657349975
                                                        ),
                                                        model: {
                                                          value:
                                                            _vm.contract
                                                              .costCenterPrepsType,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.contract,
                                                              "costCenterPrepsType",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "contract.costCenterPrepsType",
                                                        },
                                                      }),
                                                      _vm.isSubmitted &&
                                                      _vm.$v.contract
                                                        .costCenterPrepsType
                                                        .$error
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "invalid-feedback",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                  _vm._s(
                                                                    _vm.FormMSG(
                                                                      61,
                                                                      _vm.msgPrep
                                                                    )
                                                                  ) +
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          [1, 2, 4].includes(
                                            _vm.analyticPeriodType
                                          )
                                            ? _c(
                                                "b-col",
                                                {
                                                  attrs: {
                                                    sm: "12",
                                                    md: "12",
                                                    lg: "3",
                                                    xl: "3",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "b-form-group",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "uni-for",
                                                          rawName: "v-uni-for",
                                                          value:
                                                            "costCenterShootingType",
                                                          expression:
                                                            "'costCenterShootingType'",
                                                        },
                                                      ],
                                                      attrs: {
                                                        label: _vm.FormMSG(
                                                          28,
                                                          "Cost center shoot"
                                                        ),
                                                      },
                                                    },
                                                    [
                                                      _c("treeselect", {
                                                        class: {
                                                          "is-invalid":
                                                            _vm.isSubmitted &&
                                                            _vm.$v.contract
                                                              .costCenterShootingType
                                                              .$error,
                                                        },
                                                        attrs: {
                                                          disabled:
                                                            _vm.isEditable() ===
                                                            false,
                                                          multiple: false,
                                                          clearable: false,
                                                          options:
                                                            _vm.costCenterShootingOptions,
                                                          "disable-branch-nodes": true,
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "option-label",
                                                              fn: function ({
                                                                node,
                                                              }) {
                                                                return _c(
                                                                  "div",
                                                                  {},
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "treeselect-label",
                                                                        attrs: {
                                                                          title:
                                                                            node.label,
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            node.label
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                )
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          false,
                                                          2657349975
                                                        ),
                                                        model: {
                                                          value:
                                                            _vm.contract
                                                              .costCenterShootingType,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.contract,
                                                              "costCenterShootingType",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "contract.costCenterShootingType",
                                                        },
                                                      }),
                                                      _vm.isSubmitted &&
                                                      _vm.$v.contract
                                                        .costCenterShootingType
                                                        .$error
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "invalid-feedback",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                  _vm._s(
                                                                    _vm.FormMSG(
                                                                      62,
                                                                      _vm.msgShooting
                                                                    )
                                                                  ) +
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          [2, 3, 4].includes(
                                            _vm.analyticPeriodType
                                          )
                                            ? _c(
                                                "b-col",
                                                {
                                                  attrs: {
                                                    sm: "12",
                                                    md: "12",
                                                    lg: "3",
                                                    xl: "3",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "b-form-group",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "uni-for",
                                                          rawName: "v-uni-for",
                                                          value:
                                                            "costCenterWrapType",
                                                          expression:
                                                            "'costCenterWrapType'",
                                                        },
                                                      ],
                                                      attrs: {
                                                        label: _vm.FormMSG(
                                                          29,
                                                          "Cost center wrap"
                                                        ),
                                                      },
                                                    },
                                                    [
                                                      _c("treeselect", {
                                                        class: {
                                                          "is-invalid":
                                                            _vm.isSubmitted &&
                                                            _vm.$v.contract
                                                              .costCenterWrapType
                                                              .$error,
                                                        },
                                                        attrs: {
                                                          disabled:
                                                            _vm.isEditable() ===
                                                            false,
                                                          multiple: false,
                                                          clearable: false,
                                                          options:
                                                            _vm.costCenterWrapOptions,
                                                          "disable-branch-nodes": true,
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "option-label",
                                                              fn: function ({
                                                                node,
                                                              }) {
                                                                return _c(
                                                                  "div",
                                                                  {},
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "treeselect-label",
                                                                        attrs: {
                                                                          title:
                                                                            node.label,
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            node.label
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                )
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          false,
                                                          2657349975
                                                        ),
                                                        model: {
                                                          value:
                                                            _vm.contract
                                                              .costCenterWrapType,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.contract,
                                                              "costCenterWrapType",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "contract.costCenterWrapType",
                                                        },
                                                      }),
                                                      _vm.isSubmitted &&
                                                      _vm.$v.contract
                                                        .costCenterWrapType
                                                        .$error
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "invalid-feedback",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                  _vm._s(
                                                                    _vm.FormMSG(
                                                                      63,
                                                                      _vm.msgWrap
                                                                    )
                                                                  ) +
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _c(
                                            "b-col",
                                            {
                                              attrs: {
                                                sm: "12",
                                                md: "12",
                                                lg: "3",
                                                xl: "3",
                                              },
                                            },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  directives: [
                                                    {
                                                      name: "uni-for",
                                                      rawName: "v-uni-for",
                                                      value: "bcfRate",
                                                      expression: "'bcfRate'",
                                                    },
                                                  ],
                                                  attrs: {
                                                    label: _vm.FormMSG(
                                                      35,
                                                      "Budget cost factor"
                                                    ),
                                                  },
                                                },
                                                [
                                                  _c("b-form-input", {
                                                    directives: [
                                                      {
                                                        name: "uni-id",
                                                        rawName: "v-uni-id",
                                                        value: "bcfRate",
                                                        expression: "'bcfRate'",
                                                      },
                                                    ],
                                                    attrs: {
                                                      type: "number",
                                                      step: "0.01",
                                                      min: "1",
                                                      placeholder: "1.0",
                                                      disabled:
                                                        _vm.isEditable() ===
                                                        false,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.contract
                                                          .companyCostFactor,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.contract,
                                                          "companyCostFactor",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "contract.companyCostFactor",
                                                    },
                                                  }),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "info-message",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        [
                                                          _c("info", {
                                                            attrs: { size: 16 },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass: "label",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.FormMSG(
                                                                36,
                                                                "budget cost = salary*factor"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.isFilmSingle && !_vm.isFilmSingleFree
                    ? _c(
                        "b-row",
                        [
                          _c("b-col", { attrs: { sm: "12" } }, [
                            _c(
                              "fieldset",
                              { staticClass: "card-inside" },
                              [
                                _c("legend", { staticClass: "card-inside" }, [
                                  _vm._v(
                                    _vm._s(_vm.FormMSG(37, "Document package"))
                                  ),
                                ]),
                                _c(
                                  "b-row",
                                  {
                                    staticClass:
                                      "inline-flex align-items-center",
                                  },
                                  [
                                    _c(
                                      "b-col",
                                      {
                                        attrs: {
                                          sm: "12",
                                          md: "12",
                                          lg: "6",
                                          xl: "6",
                                        },
                                      },
                                      [
                                        _c(
                                          "b-form-group",
                                          {
                                            directives: [
                                              {
                                                name: "uni-for",
                                                rawName: "v-uni-for",
                                                value: "documentPackageId",
                                                expression:
                                                  "'documentPackageId'",
                                              },
                                            ],
                                            attrs: {
                                              label: _vm.FormMSG(
                                                38,
                                                "Assign document package"
                                              ),
                                            },
                                          },
                                          [
                                            _c("v-select", {
                                              staticClass:
                                                "fix-clearable-disabled",
                                              class: {
                                                "is-invalid":
                                                  _vm.isHandleSendPackage &&
                                                  _vm.$v.contract
                                                    .documentPackageId.$error,
                                              },
                                              attrs: {
                                                disabled:
                                                  _vm.isEditable() === false,
                                                options:
                                                  _vm.documentPackageOptions,
                                                id: "documentPackageId",
                                                label: "name",
                                                reduce: (option) => option.id,
                                                clearable: false,
                                              },
                                              on: {
                                                "option:selected":
                                                  _vm.selectDocumentPackage,
                                              },
                                              model: {
                                                value:
                                                  _vm.contract
                                                    .documentPackageId,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.contract,
                                                    "documentPackageId",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "contract.documentPackageId",
                                              },
                                            }),
                                            _vm.isHandleSendPackage &&
                                            !_vm.$v.contract.documentPackageId
                                              .greaterThanZero
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "invalid-feedback",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                        _vm._s(
                                                          _vm.FormMSG(
                                                            69,
                                                            "Please, choose a document package"
                                                          )
                                                        ) +
                                                        "\n\t\t\t\t\t\t\t\t\t\t"
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      {
                                        attrs: {
                                          sm: "12",
                                          md: "12",
                                          lg: "6",
                                          xl: "6",
                                        },
                                      },
                                      [
                                        _vm.contract.documentPackageId > 0
                                          ? _c(
                                              "b-row",
                                              [
                                                _c(
                                                  "b-col",
                                                  { attrs: { sm: "12" } },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "btn-rounded",
                                                        on: {
                                                          click:
                                                            _vm.handleViewDocumentPackage,
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass: "icon",
                                                          },
                                                          [
                                                            _c("eye", {
                                                              attrs: {
                                                                color:
                                                                  "#5B6164D6",
                                                                size: 16,
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "label",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                _vm._s(
                                                                  _vm.FormMSG(
                                                                    39,
                                                                    "View document package"
                                                                  )
                                                                ) +
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.hideSendPackageBtn
                                          ? _c(
                                              "b-row",
                                              { staticClass: "pt-3" },
                                              [
                                                _c(
                                                  "b-col",
                                                  { attrs: { sm: "12" } },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "btn-rounded",
                                                        on: {
                                                          click:
                                                            _vm.handleResendDocumentPackage,
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass: "icon",
                                                          },
                                                          [
                                                            _c("files", {
                                                              attrs: {
                                                                color:
                                                                  "#5B6164D6",
                                                                size: 16,
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "label",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                _vm._s(
                                                                  _vm.FormMSG(
                                                                    42,
                                                                    "Send new document package"
                                                                  )
                                                                ) +
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
          _c("memo-deal", {
            ref: "memo",
            attrs: {
              "edit-data": _vm.getMemoDealInfoFromContract(),
              "disable-edit": !_vm.isEditable(),
              "start-date-update": _vm.contract.startDate
                ? _vm.contract.startDate.toString()
                : null,
              "end-date-update": _vm.contract.endDate
                ? _vm.contract.endDate.toString()
                : null,
              "days-foreseen-update": _vm.contract.daysForeseen,
              "employee-status-update": _vm.contract.employeeStatus,
              "cost-center-preps-type-update": _vm.contract.costCenterPrepsType,
              "cost-center-shooting-type-update":
                _vm.contract.costCenterShootingType,
              "cost-center-wrap-type-update": _vm.contract.costCenterWrapType,
              "company-cost-factor-update": _vm.contract.companyCostFactor,
              "document-package-id-update": _vm.contract.documentPackageId,
              "is-submitted": _vm.isSubmitted,
              "fill-class-container": "",
              "show-pay-code": _vm.showPayCode,
              "user-id": _vm.userId,
              "hide-actions": "",
            },
            on: {
              "memo-deal:save-template": _vm.saveAsDealMemoTemplate,
              "memo-deal:get-ref-salary": _vm.getRefSalary,
              change: _vm.handleMemoDealChange,
              "memo-deal:fields:invalid": _vm.isInvalidateFields,
              "memo-deal:fields:clear-error": _vm.clearFieldsError,
              "memo-deal:department-function:required": _vm.handleCcn2642,
              "memo-deal:user-custom-field-value":
                _vm.onContractCustomFieldValueChanged,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }